import { takeLatest, call, put } from 'redux-saga/effects';

import api, { POST } from 'utils/api';

import { updatePasswordSuccess, updatePasswordError } from './actions';
import { UPDATE_PASSWORD } from './constants';
import { notifyError, notifySuccess } from "../../../../utils/notification";

function* updatePasswordData({ data }) {
  const response = yield call(api, '/profile/password', {
    method: POST,
    body: data,
  });

  if (response.status === 200) {
    notifySuccess('Password was changed');
    yield put(updatePasswordSuccess());
  } else {
    notifyError('An error occurred during password update.');
    yield put(updatePasswordError());
  }

}

/**
 * Root saga manages watcher lifecycle
 */
export default function* getData() {
  yield takeLatest(UPDATE_PASSWORD, updatePasswordData);
}
