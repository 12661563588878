import React from "react";

import Modal from "components/Modal";
import { SendRoomEmailForm } from "component/SendRoomEmailForm";
import { notifyError, notifySuccess } from "utils/notification";
import api, { POST } from "utils/api";

import "./styles.scss";

export default class SendNotesModal extends React.Component {
  modal = React.createRef();
  form = React.createRef();

  openModal = () => {
    this.modal.current.openModal();
  };

  sendEmail = async (emails) => {
    const { surveyId, roomId } = this.props;
    const response = await api("/email/send/survey/observationNotes", {
      method: POST,
      body: {
        surveyId,
        roomId,
        emails,
      }
    });

    if (response.status !== 200) {
      notifyError(`Error on sending email${emails.length > 1 ? 's' : ''}, please try again!`);
    } else {
      notifySuccess(`${emails.length > 1 ? 'Emails were' : 'Email was'} sent with success!`);
    }
  };

  confirmHandler = async () => {
    const { updateSurvey, closeHandler } = this.props;

    const emails = this.form.current.getEmails();
    if (!emails || !emails.length) {
      notifyError("Please fill at least one email field.");
      return;
    }

    const response = await updateSurvey();
    if (response.status !== 200) {
      notifyError("Error on updating survey, please try again!");
      return;
    }

    this.sendEmail(emails);

    closeHandler();
    this.modal.current.closeModal();
  };

  cancelHandler = () => {
    const { closeHandler } = this.props;

    closeHandler();
    this.modal.current.closeModal();
  };

  render() {
    const { surveyId, roomId, closeHandler } = this.props;
    const location = {
      state: {
        surveyId,
        roomId
      }
    };

    return (
      <Modal ref={this.modal} title="EDIT SURVEY" onClose={closeHandler}>
        <hr className="send-notice-modal-line" />
        <div id="send-notice-modal">

          <SendRoomEmailForm ref={this.form} location={location} />

          <div className="template-button-left">
            <button
              type="submit"
              className="blue-button"
              onClick={this.cancelHandler}
            >
              Cancel
            </button>
          </div>
          <div className="template-button-right">
            <button
              type="submit"
              className="orange-button"
              onClick={this.confirmHandler}
            >
              Next
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
