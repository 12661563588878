import { LOAD_SURVEY_TEMPLATES_DATA,
         LOAD_SURVEY_TEMPLATES_DATA_SUCCESS,
         LOAD_CHOSEN_SURVEY_TEMPLATE,
         LOAD_CHOSEN_SURVEY_TEMPLATE_SUCCESS } from './constants';

export const loadSurveyTemplatesData = () => ({ type: LOAD_SURVEY_TEMPLATES_DATA });
export const loadSurveyTemplatesDataSuccess = (data) => ({ type: LOAD_SURVEY_TEMPLATES_DATA_SUCCESS, data });

export const loadChosenSurveyTemplate = (payload) => ({ type: LOAD_CHOSEN_SURVEY_TEMPLATE, payload });
export const loadChosenSurveyTemplateSuccess = (data) => ({ type: LOAD_CHOSEN_SURVEY_TEMPLATE_SUCCESS, data });
