import React, { Fragment } from 'react';

export default function AccessControl({ children, permissions, roles, resource, action, hasRole = '*', hasLevel = '*' }) {
  let hasAccess = true;
  const checkActions = (actions) => actions.includes('*') || actions.includes(action);
  const rolesToCheck = typeof hasRole === 'string' ? [hasRole] : hasRole;
  const levelsToCheck = typeof hasLevel === 'string' ? [hasLevel] : hasLevel;

  if (!roles || !roles.length || !permissions || !permissions.length) {
    return <></>;
  }

  if (!levelsToCheck.includes('*') && !rolesToCheck.includes('*')) {
    let arr = [];
    for (const role of rolesToCheck) {
      for (const level of levelsToCheck) {
        arr.push(`${role}/${level}`);
      }
    }
    hasAccess = arr.some(a => roles.some((role) => role === a));
  } else if (!levelsToCheck.includes('*')) {
    hasAccess = roles.map((role) => role.split('/')[1]).some((level) => levelsToCheck.includes(level));
  } else if (!rolesToCheck.includes('*')) {
    hasAccess = roles.map((role) => role.split('/')[0]).some((role) => rolesToCheck.includes(role));
  } else if (resource && action) {
    hasAccess = permissions.findIndex((permission) => permission.resource === resource && checkActions(permission.actions)) > -1;
  }

  return <Fragment>{hasAccess && <> {children}</>}</Fragment>;
}
