import { fromJS } from 'immutable';

import {
  LOAD_STATES,
  LOAD_STATES_SUCCESS,
  LOAD_STATES_ERROR,
  LOAD_ACCESS_LEVELS,
  LOAD_ACCESS_LEVELS_ERROR,
  LOAD_ACCESS_LEVELS_SUCCESS,
  LOAD_CAMPUSES,
  LOAD_CAMPUSES_ERROR,
  LOAD_CAMPUSES_SUCCESS,
  LOAD_DISTRICTS,
  LOAD_DISTRICTS_ERROR,
  LOAD_DISTRICTS_SUCCESS,
  LOAD_ROLES,
  LOAD_ROLES_ERROR,
  LOAD_ROLES_SUCCESS,
} from './constants';

const initialState = fromJS({
  states: [],
  districts: [],
  campuses: [],
  accessLevels: [],
  roles: [],
});

function userRoleReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_STATES:
    case LOAD_STATES_ERROR:
      return state.set('states', []);
    case LOAD_STATES_SUCCESS:
      return state.set('states', action.payload);

    case LOAD_DISTRICTS:
    case LOAD_DISTRICTS_ERROR:
      return state.set('districts', []);
    case LOAD_DISTRICTS_SUCCESS:
      return state.set('districts', action.payload);

    case LOAD_CAMPUSES:
    case LOAD_CAMPUSES_ERROR:
      return state.set('campuses', []);
    case LOAD_CAMPUSES_SUCCESS:
      return state.set('campuses', action.payload);

    case LOAD_ROLES:
    case LOAD_ROLES_ERROR:
      return state.set('roles', []);
    case LOAD_ROLES_SUCCESS:
      return state.set('roles', action.payload);

    case LOAD_ACCESS_LEVELS:
    case LOAD_ACCESS_LEVELS_ERROR:
      return state.set('accessLevels', []);
    case LOAD_ACCESS_LEVELS_SUCCESS:
      return state.set('accessLevels', action.payload);

    default:
      return state;
  }
}

export default userRoleReducer;
