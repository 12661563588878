import styled from 'styled-components';
import colors from 'styles/colors';

export const PageSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  color: ${colors.greyishBrown};
  height: 38px;
  font-family: 'Open Sans';
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
`;
export const OrangeTitle = styled.h3`
  color: ${colors.bloodOrange};
  font-size: 28px;
  font-weight: bold;
  margin-top: 26px;
  margin-bottom: 42px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 21px;
  }
`;

export const StyledInputText = styled.input.attrs(() => ({
  type: 'text',
}))`
  background-color: #efefef;
  font-family: 'Open Sans';
  border-radius: 0;
  border: none;
  height: 65px;
  font-size: 18px;
  line-height: 1.28;
  color: #373a49;

  :focus {
    outline: 0;
    box-shadow: none;
  }
`;

export const Line = styled.div`
  height: 1px;
  border: solid 1px ${colors.warmGrey};
  margin-top: 20px;
  margin-bottom: 20px;
`;
