/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';

import { CheckboxWrapper, CheckboxContainer, CheckboxMark, CheckboxTitle } from './styles';

export default function Checkbox({ onChange, title, disabled, style, defaultValue = false, update = true }) {
  const [checked, setChecked] = useState(defaultValue);

  useEffect(() => {
    if (defaultValue && onChange) {
      onChange(defaultValue);
      setChecked(defaultValue);
    }
  }, [defaultValue]);

  const onClick = () => {
    if (disabled) return;

    if (update) {
      setChecked((val) => !val);
    }
    if (onChange && typeof onChange === 'function') {
      onChange(!checked);
    }
  };

  return (
    <CheckboxWrapper onClick={onClick} style={style}>
      <CheckboxContainer>{checked && <CheckboxMark />}</CheckboxContainer>
      {title && (
        <CheckboxTitle content={title} disabled={disabled}>
          {title}
        </CheckboxTitle>
      )}
    </CheckboxWrapper>
  );
}
