import { UPDATE_USER_ID, UPDATE_AUTH_TOKEN, REFRESH_AUTH_TOKEN, REFRESH_AUTH_TOKEN_SUCCESS, UPDATE_PERMISSIONS, UPDATE_ROLES, UPDATE_TIMEZONE } from './constants';

export const updateAuthToken = (data) => ({ type: UPDATE_AUTH_TOKEN, data });

export const refreshAuthToken = (data) => ({ type: REFRESH_AUTH_TOKEN, data });
export const refreshAuthTokenSuccess = (data) => ({ type: REFRESH_AUTH_TOKEN_SUCCESS, data });

export const updatePermissions = (data) => ({ type: UPDATE_PERMISSIONS, data });

export const updateTimezone = (data) => ({ type: UPDATE_TIMEZONE, data });

export const updateRoles = (data) => ({ type: UPDATE_ROLES, data });

export const updateUserId = (data) => ({ type: UPDATE_USER_ID, data });
