/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';
import AccessControl from '../AccessControl';

export default ({ items, open, actions }) =>
  items && items.length ? (
    <ul className={open ? 'open-menu-item' : ''}>
      {items.map(({ to = '/', title = '', access, action, href = '' }) => (
        <AccessControl {...access} key={title}>
          <li>
            {href ? (
              <a href={href} className="router-link-inner" onClick={actions.closeMenu}>
                {title}
              </a>
            ) : action ? (
              <a
                className="router-link-inner"
                onClick={() => {
                  actions[action]();
                  // eslint-disable-next-line no-unused-expressions
                  actions.closeMenu?.();
                }}
                href="#/"
              >
                Logout
              </a>
            ) : (
              <Link className="router-link-inner" to={to} onClick={actions.closeMenu}>
                {title}
              </Link>
            )}
          </li>
        </AccessControl>
      ))}
    </ul>
  ) : null;
