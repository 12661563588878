import styled from 'styled-components';
import colors from 'styles/colors';

export const FormGroup = styled.div`
  margin-bottom: 32px;
`;

export const Label = styled.label`
  color: ${colors.charcoalGrey};
  font-family: BebasNeue;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: 2.29px;
  width: 100%;

  input {
    color: ${colors.charcoalGrey};
    background-color: ${colors.whiteGrey};
    font-family: OpenSans;
    border-radius: 0;
    border: none;
    height: 65px;
    font-size: 18px;
    font-weight: normal;
    padding: 20px 30px;
    line-height: 1.28;

    &:focus {
      outline: 0;
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }
`;
