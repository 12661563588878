import { createSelector } from 'reselect';

import { REDUCER_KEY } from '../constants';

const selectUpdatePassword = (state) => state.get(REDUCER_KEY);

export const makeUpdateLoading = () =>
  createSelector(
    selectUpdatePassword,
    (updatePasswordState) => updatePasswordState.get('updateLoading')
  );

export const makeUpdateSuccess = () =>
  createSelector(
    selectUpdatePassword,
    (updatePasswordState) => updatePasswordState.get('updateSuccess')
  );

export const makeHasErrorSelect = () =>
  createSelector(
    selectUpdatePassword,
    (updatePasswordState) => updatePasswordState.get('hasError')
  );

export { selectUpdatePassword };
