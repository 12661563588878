import React from 'react';
import { Label, FormGroup } from './styles';

export default function(props) {
  const {
    type = 'text',
    label,
    width,
    input: { value, onChange },
  } = props;

  return (
    <FormGroup className="form-group" width={width}>
      <Label>
        {label}
        <input type={type} className="form-control" value={value} onChange={onChange} />
      </Label>
    </FormGroup>
  );
}
