import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectRoles, makeSelectUserId } from '../App/selectors';

import NewSurveyConfig from './NewSurveyConfig';

const mapStateToProps = createStructuredSelector({
    roles: makeSelectRoles(),
    userId: makeSelectUserId(),
  });

  const withConnect = connect(
    mapStateToProps,
  );

  export default compose(
    withConnect
  )(NewSurveyConfig);
