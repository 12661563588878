/**
 * UserInfo selectors
 */

import { createSelector } from 'reselect';
import { REDUCER_KEY } from '../constants';

const selectUserInfo = (state) => state.get(REDUCER_KEY);

export const makeStatesLoading = () =>
  createSelector(
    selectUserInfo,
    (userInfoState) => userInfoState.get('statesLoading')
  );

export const makeStatesSelect = () =>
  createSelector(
    selectUserInfo,
    (userInfoState) => userInfoState.get('states')
  );

export { selectUserInfo };
