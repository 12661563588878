/**
 * User selectors
 */

import { createSelector } from 'reselect';
import { REDUCER_KEY } from '../constants';

const selectUser = (state) => state.get(REDUCER_KEY);

export const makeUserLoading = () =>
  createSelector(
    selectUser,
    (userState) => userState?.get?.('userLoading')
  );

export const makeSelectUser = () =>
  createSelector(
    selectUser,
    (userState) => userState?.get?.('user')
  );

export const makeSubmitLoading = () =>
  createSelector(
    selectUser,
    (userState) => userState?.get?.('submitLoading')
  );

export { selectUser };
