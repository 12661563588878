import { createSelector } from 'reselect';

import { REDUCER_KEY } from './constants';

const selectResetPassword = (state) => state.get(REDUCER_KEY);

export const makeSelectResetLoading = () =>
  createSelector(
    selectResetPassword,
    (resetPasswordState) => resetPasswordState.get('resetLoading')
  );

export const makeSelectError = () =>
  createSelector(
    selectResetPassword,
    (resetPasswordState) => resetPasswordState.get('hasError')
  );

