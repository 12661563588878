import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import './style.scss';
import colors from '../../styles/colors';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Line } from '../../components/Line';
import { PageWrapper, PageContent } from '../../components/PageWrapper';
import { PageHeader } from '../../components/PageHeader';
import dateIcon1 from '../../images/dateIcon_1.png';
import dateIcon2 from '../../images/dateIcon_2.png';
import listIcon1 from '../../images/listIcon_1.png';
import listIcon2 from '../../images/listIcon_2.png';
import AccessControl from 'components/AccessControl';
import api from 'utils/api';

const initTemplates = {
  templates: [
    {
      id: 1,
      title: 'Simple Report',
      description: 'One Time Window - One Data Set',
      provides: 'Provides Baseline Information',
      addTimeWindow: 1,
      addDataSet: 1,
      url: '/simple-report',
      uses: ['Quick Check', 'Establish a Baseline'],
      commonDataSet: ['District', 'Campus', 'Teacher Group', 'Classroom'],
    },
    {
      id: 2,
      title: 'Data Comparison Report',
      description: 'One Time Window - Multiple Data Sets',
      provides: 'Provides a Comparison at a Given Date',
      addTimeWindow: 1,
      addDataSet: 1,
      url: '/data-report',
      uses: ['Identify Trends', 'Identify Strengths', 'Identify Challenges'],
      commonDataSet: ['Campuses', 'Observers', 'Teacher Groups'],
    },
    {
      id: 3,
      title: 'Time Comparison Report',
      description: 'Multiple Time Windows - One Data Set',
      provides: 'Shows Movement Over Time',
      addTimeWindow: 2,
      addDataSet: 2,
      url: '/time-report',
      uses: ['Identify Trends', 'Support', 'Implementation', 'Motivation', 'Coaching'],
      commonDataSet: ['Identify Trends', 'Support', 'Implementation', 'Motivation', 'Coaching'],
    },
  ],
  templatesLoading: false,
};

const Container = styled.div`
  margin-top: 22px;
  display: flex;
  flex-direction: column;
`;

const Button = styled.div`
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 50px;
  height: 60px;
  width: 301px;
  background-color: ${colors.darkSlateBlue};
  font-family: BebasNeue;
  color: #fff;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 3.14px;
  font-weight: bold;
`;

export default class NewReportTemplatesPage extends React.Component {
  state = {
    campusDataHasPLC: null,
  };

  async componentDidMount() {
    const hasPLC = await this.fetchData();
    this.setState((prev) => {
      return { ...prev, campusDataHasPLC: hasPLC };
    });
  }

  async fetchData() {
    const userRequest = await api('/profile/me');
    const userInfo = await userRequest.json();
    const campusId = userInfo?.userRoles[0]?.campus?.id;

    if (campusId) {
      const campusRequest = await api(`/campus/${campusId}`);
      const campusData = await campusRequest.json();
      return campusData.hasPLC;
    }
  }

  render() {
    const { /* templates, */ isLoading } = this.props;
    const { campusDataHasPLC } = this.state;
    const tmpTemplates = initTemplates.templates;
    return (
      <PageWrapper className="with-blue">
        <Helmet>
          <title>New Report Templates Page</title>
          <meta name="description" content="New Report Templates" />
        </Helmet>
        <PageContent>
          <div className="pageHeader">
            <div className="headerLeftSide">
              <PageHeader>New Report</PageHeader>
              <Line />
            </div>
            <div className="headerRightSide">
              <AccessControl hasRole="campus">
                {campusDataHasPLC && (
                  <Link to="/plc-report">
                    <Button className="btnPlcReport">plc report</Button>
                  </Link>
                )}
              </AccessControl>
            </div>
          </div>
          <div className="pageTitle">Build a New Report</div>
          <div className="pageDescription">What Type of Report Do I Need?</div>
          {isLoading && <LoadingIndicator />}
          {!isLoading && (
            <Container>
              {tmpTemplates.map((block) => (
                <div className="reportContainer" key={block.id}>
                  <div className="leftSide">
                    <div className="reportTitle">
                      <div className="reportTitleText"> {block.title}</div>
                      <div className="reportTitleDescription">{block.description}</div>
                    </div>
                    <div className="iconsBlock">
                      <div className="iconTimeBlock">
                        <div className="iconTimeLabel">Add Time Window</div>
                        <div className="iconTimeImg">
                          {' '}
                          <img src={dateIcon1} alt="" style={{ width: '37px', height: '37px', marginRight: '11px' }} />
                        </div>
                        {block.id === 3 && (
                          <div className="iconTimeImg">
                            {' '}
                            <img src={dateIcon2} alt="" style={{ width: '37px', height: '37px' }} />
                          </div>
                        )}
                      </div>
                      <div className="iconDataBlock">
                        <div className="iconDataImg">
                          <img src={listIcon1} alt="" style={{ width: '37px', height: '37px', marginRight: '11px' }} />
                        </div>
                        {block.id === 2 && (
                          <div className="iconDataImg">
                            <img src={listIcon2} alt="" style={{ width: '37px', height: '37px' }} />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="buttonBlock">
                      <AccessControl hasLevel={['basic', 'trainer', 'advanced', 'managing']}>
                        <Link to={block.url}>
                          <Button className="button"> Start report</Button>
                        </Link>
                      </AccessControl>
                    </div>
                  </div>
                  <div className="rightSide">
                    <div className="provides">{block.provides}</div>
                    <div className="listsBlock">
                      <div className="usesBlock">
                        <div className="usesTitle">Uses</div>
                        {block.uses.map((el) => {
                          return <div className="usesItem">{el}</div>;
                        })}
                      </div>
                      <div className="dataSetsBlock">
                        <div className="dataSetsTitle">Common Data Sets</div>
                        {block.commonDataSet.map((el) => {
                          return <div className="dataItem">{el}</div>;
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="buttonBlock">
                <Link to="/home">
                  <Button className="btnBack">back</Button>
                </Link>
                <AccessControl hasLevel={['basic', 'trainer', 'advanced', 'managing']}>
                  <Link to="/quick-classroom-report" className="linkCustomComparison">
                    <Button className="btnQuickReport">quick classroom report</Button>
                  </Link>
                </AccessControl>
                <AccessControl hasLevel={['basic', 'trainer', 'advanced', 'managing']}>
                  <Link to="/custom-report" className="linkCustomComparison">
                    <Button className="headerButton btnCustomComparison">Custom comparison report</Button>
                  </Link>
                </AccessControl>
              </div>
            </Container>
          )}
        </PageContent>
      </PageWrapper>
    );
  }
}
