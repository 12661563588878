import styled from 'styled-components';

export const FormItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

export const FormLabel = styled.div`
  flex: 1;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: 600;
  line-height: 3.57;
  color: #000000;
`;

export const SelectWrapper = styled.div`
  flex: 2;
  margin-left: -15px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

export const CheckboxWrapper = styled.div`
  flex: 2;
  margin-left: -10px;
`;

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-top: 10px;
  > * {
    margin-right: 30px;
  }
`;
