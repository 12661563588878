import { fromJS } from 'immutable';

import {
  UPDATE_AUTH_TOKEN,
  REFRESH_AUTH_TOKEN,
  REFRESH_AUTH_TOKEN_SUCCESS,
  UPDATE_PERMISSIONS,
  UPDATE_ROLES,
  UPDATE_TIMEZONE,
  UPDATE_USER_ID,
} from './constants';

const initialState = fromJS({
  error: false,
  refreshTokenInProgress: false,
  authToken: window.localStorage.getItem('authToken'),
  timezone: window.localStorage.getItem('timezone'),
  permissions: JSON.parse(window.localStorage.getItem('permissions') || '[]'),
  roles: JSON.parse(window.localStorage.getItem('roles') || '[]'),
  userId: window.localStorage.getItem('userId'),
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_AUTH_TOKEN:
      window.localStorage.setItem('authToken', action.data);
      return state.set('authToken', action.data);

    case REFRESH_AUTH_TOKEN:
      return state.set('refreshTokenInProgress', true);
    case REFRESH_AUTH_TOKEN_SUCCESS:
      return state.set('refreshTokenInProgress', false);

    case UPDATE_TIMEZONE:
      if (!action.data) {
        window.localStorage.removeItem('timezone');
      } else {
        window.localStorage.setItem('timezone', action.data);
      }
      return state.set('timezone', action.data);
    case UPDATE_PERMISSIONS:
      window.localStorage.setItem('permissions', JSON.stringify(action.data));
      return state.set('permissions', fromJS(action.data || []));
    case UPDATE_ROLES:
      window.localStorage.setItem('roles', JSON.stringify(action.data));
      return state.set('roles', fromJS(action.data || []));
    case UPDATE_USER_ID: 
      if (action.data) {
        window.localStorage.setItem('userId', action.data);
      } else {
        window.localStorage.removeItem('userId');
      }
      return state.set('userId', action.data || null);
    default:
      return state;
  }
}

export default appReducer;
