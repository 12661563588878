import React, { useState } from 'react';
import './style.scss';

import Modal from '../NewModal/index';
import ContactForm from 'containers/auth/LoginPage/components/ContactForm';

const Footer = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <footer>
      <Modal show={showModal} title="Contact form" onClose={() => setShowModal(false)}>
        <ContactForm onRedirect={() => setShowModal(false)} />
      </Modal>
      <div
        className="contact-us"
        onClick={() => {
          setShowModal(true);
        }}
      >
        contact us
      </div>
    </footer>
  );
};

export default Footer;
