import React, { Component } from 'react';

import { FormInputText } from './styles';

export default class FormInput extends Component {
  render() {
    const { input } = this.props;

    return (
      <FormInputText>
        <input {...this.props} {...input} />
      </FormInputText>
    );
  }
}
