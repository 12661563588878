import { ReportTypes } from '../../constants/index';
import Loadable from 'react-loadable';
import React from 'react';

import LoadingIndicator from '../../components/LoadingIndicator';

const CustomReportPage = Loadable({
  loader: () => import('./index'),
  loading: LoadingIndicator,
});

export function DataReportPage(prop) {
  return (
    <CustomReportPage
      {...prop}
      reportType={ReportTypes.DataReportType}
      multipleDataSets={true}
      multipleDateSets={false}
      multipleReportSets={false}
    />
  );
}

export function TimeReportPage(prop) {
  return <CustomReportPage {...prop} reportType={ReportTypes.TimeReportType} multipleDateSets={true} multipleReportSets={false} />;
}

export function SimpleReportPage(prop) {
  return <CustomReportPage {...prop} reportType={ReportTypes.SimpleReportType} multipleDateSets={false} multipleReportSets={false} />;
}
export function QuickClassroomReportPage(prop) {
  return (
    <CustomReportPage
      {...prop}
      reportType={ReportTypes.QuickClassroomType}
      multipleDateSets={false}
      multipleReportSets={false}
      lockedType={''}
    />
  );
}

export default CustomReportPage;
