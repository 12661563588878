/* eslint-disable jsx-a11y/label-has-for,react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Checkbox from "../components/Checkbox3";
import FormInput from "../components/FormInput";

export function SavableEmail({ onSave, onChange, onSelect }) {
    const [email, setEmail] = useState('');
    const [saved, setSaved] = useState(false);
    const [shouldSend, setShouldSend] = useState(false);
    useEffect(() => {
        onChange(email);
    }, [email]);
    useEffect(() => {
        onSelect(shouldSend)
    }, [shouldSend]);

    return <label>
        <Checkbox title="Send email to" checked={shouldSend} onChange={() => setShouldSend(val => !val)} />
        <FormInput value={email} onChange={({ target }) => !saved && setEmail(target.value)} />
        <Checkbox
            title="Save email"
            checked={saved}
            onChange={() => {
                if (email.length === 0) {
                    return;
                }
                if (!saved && onSave) {
                    onSave(email);
                }
                setSaved(true);
            }} />
    </label>;
}
