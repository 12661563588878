import React from 'react';

import ActionButton from 'components/ActionButton';
import Modal from 'components/Modal';

import './styles.scss';

export default class ConfirmationModal extends React.Component {
  modal = React.createRef();

  openModal = () => {
    this.modal.current.openModal();
  };

  confirmHandler = () => {
    const { confirmHandler } = this.props;

    confirmHandler();
    this.modal.current.closeModal();
  };

  cancelHandler = () => {
    const { cancelHandler } = this.props;

    cancelHandler();
    this.modal.current.closeModal();
  };

  render() {
    return (
      <Modal ref={this.modal}>
        <div className="modalTitle">
            Do you want to re-lock this survey at this time?
        </div>
        <div className="modalButtons">
          <ActionButton title="Yes" type="alert" onClick={this.confirmHandler} big />
          <ActionButton title="No" onClick={this.cancelHandler} big />
        </div>
      </Modal>
    );
  }
}
