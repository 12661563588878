import Select from 'react-dropdown-select';
import styled from 'styled-components';

import colors from 'styles/colors';

export const Wrapper = styled.div`
  flex: 1;
  margin-bottom: 32px;
`;

export const StyledSelect = styled(Select)`
  height: 65px;
  border-radius: 0px !important;
  border: none !important;
  padding: 0 !important;
  color: ${colors.charcoalGrey};
  background-color: ${colors.whiteGrey};

  :focus {
    box-shadow: none;
  }

  .react-dropdown-select {
  }

  .react-dropdown-select-content {
    margin-left: 30px;

    ::before {
      content: ' ';
      display: block;
      height: 24px;
      width: 1px;
      background-color: ${colors.whiteGrey};
      position: absolute;
      right: 35px;
      top: 7px;
    }
  }

  .react-dropdown-select-dropdown {
    border-radius: 5px;
    border: 1px solid #ebebeb;
  }

  .react-dropdown-select-content,
  .react-dropdown-select-dropdown {
    color: ${colors.charcoalGrey};
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 38px;
    letter-spacing: normal;
  }

  .react-dropdown-select-dropdown-handle {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px 0 0;

    svg {
      width: 23px;
      height: 23px;
      margin-top: -3px;
    }
  }
`;

export const Label = styled.label`
  color: ${colors.charcoalGrey};
  font-family: BebasNeue;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: 2.29px;
  width: 100%;
`;

export const StyledSelectItem = styled.div`
  cursor: pointer;
  background-color: ${(props) => (props.selected ? colors.veryLightBlue : 'white')};
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 2.5;
  color: ${colors.greyishBrown};
  padding: 0 15px;

  :hover {
    background-color: ${colors.veryLightBlue};
  }
`;
