import { createSelector } from 'reselect';

const getNewSurvey = (state) => state.get('newSurvey');

export const getNewSurveyTemplates = () =>
  createSelector(
    getNewSurvey,
    (state) => state.get('templates')
  );

export const isLoading = () =>
  createSelector(
    getNewSurvey,
    (state) => state.get('loading')
  );

  export const getChosenSurveyTemplate = () =>
    createSelector(
      getNewSurvey,
      (state) => state.get('chosenTemplate')
    );