
export const tooltips = {
  'Common Info': 'This Module collects common information like Room, Grade, Subject, and Date Observed.',
  'Instructor Location': `Instructor Work Area: Teacher engaged in administrative duties at the teacher desk or computer/technology space.
  Lecture Position: Teacher teaching or monitoring with significant space between teacher and student(s), but not in work area.
  Power Zone: Teacher is teaching or monitoring in close proximity to one or more students in any area of the room.`,
  'Pupil Engagement': `Off Task: Actual number of students who are obviously off task, have no task to work on, or have finished the task.
  On Task: Actual number of students who have not been identified as Off Task.`,
  'Lesson Framing': `Today’s Objective Posted: Clear, student friendly statement of the intent of today’s lesson.
  Today’s Close Posted: Clear, student friendly description of how the student will demonstrate the understanding of the critical component(s) of today’s lesson.
  Class Activity Aligns With Lesson Frame: The observed activity in the classroom aligns with the posted lesson frame.`,
  'High Yield Instructional Practices': `Identifying Similarities & Differences (written): STUDENTS are comparing or classifying topics, items, and/or subject matter through purposeful writing.
  Summarization (written): STUDENTS are writing a clear and concise summary of a discussion, observation, and/or reading.
  Note Making: STUDENTS are making written connections, inferences, observations, highlights, and/or other notes based on presented information, discussion, and/or reading.
  Other Critical Writing Activity: STUDENTS engaged in a writing activity designed to increase connections to and cognition of the subject matter.
  Nonlinguistic Representation: STUDENTS are using visual or manipulative displays to organize, use, demonstrate, or connect to the subject matter.
  Student to Student Purposeful Talk: STUDENTS are engaged in purposeful talk about the subject matter, driven by TEACHER planning and lesson design.
  Student to Student Collaboration: STUDENTS collaborating on an assignment, academically supporting peers, and/or informally discussing academic content.
  Providing Recognition: TEACHER specifically recognizes a student’s academic success or growth.
  Reinforcing Effort: TEACHER specifically reinforces the work and effort by a student that will lead to academic success or growth.
  Practice: STUDENTS are engaged in an academic task AND the TEACHER is actively monitoring and/or providing feedback.`,
  'Instructional Rigor': `No Observable Instructional Activity or Task: While the observer is in the room, no instructional activity was in progress or started.
Remembering: The memory of facts and knowledge. Listening, watching, and copying are Remembering level activities
Understanding: Organizing information to develop meaning. Responding to simple questions, finding answers in provided materials, and short answers are often Understanding level activities.
Applying: Using knowledge to solve problems in a new situation. Multi-step process questions, building, manipulating, and hands on activities are often Applying level activities.
Analyzing: Examining and breaking information into parts by identifying motives and causes. Using evidence to support generalizations and inferences.
Evaluating: Using knowledge to defend opinions or judge the quality of work, based on criteria.
Creating: Using and combining components of knowledge to create a new or alternative solution.`,
  'Instructional Relevance': `No Observable Instructional Activity or Task: While the observer is in the room, no instructional activity was in progress or started.
Knowledge in the Content Area: Using content knowledge to solve content problems in the content area. Example: Completing a math worksheet in a math class.
Application in the Content Area: Content specific manipulating, building, or creating in the content area.
Knowledge Across Content Area: Use of other content information to address tasks in a content area. Example: Reading a historical account in an English or reading class.
Application Across Content Area: Use of other content knowledge while manipulating, building, or creating in a content area.
Real World Predictable Outcome: Using content knowledge to address real world problems and/or situations that have predictable outcomes or solutions.
Real World Novel Outcome: Using content knowledge to address real world problems and/or situations that have novel or infinite outcomes or solutions.`,
  'Classroom Climate and Management Practices': `Following Posted Instructional Schedule: If a classroom schedule is posted, the classroom activity and/or covered content aligns with the schedule.
Using Positive Language: The teacher uses positive language when interacting with students. Examples: Good job; Glad to see you; Great energy in the room today; etc.
Circulates Through Classroom: The teacher moves to different areas of the classroom during the observation.
Clutter Free Classroom: The classroom and teacher work area are free of clutter, excessive materials, and/or excessive decorations.
Organized Classroom: The materials and decor of the classroom and teacher work area are orderly and organized.
Clean Classroom: The classroom and teacher work area are clean and neat.
Using a Timer: The teacher is using a timer to keep track of how much time is left in an instructional activity. Ex: Warmups, Individual Practice; Lesson Closure, etc.
Copying / Dictation Activity: The student is either copying material from the board or a book, or writing down what the teacher is dictating.`,
}
