import React from 'react';
import createIconComponent from '../../utils/createIconComponent';

const AscIcon = createIconComponent({
  content: (
    <g fill="none" fillRule="evenodd" stroke="#4A4A4A">
      <path d="M1 7l4 4 4-4M5 10.5V.5" />
    </g>
  ),
  height: 11,
  width: 10,
});

AscIcon.displayName = 'AscIcon';

export default AscIcon;
