import styled from 'styled-components';
import colors from 'styles/colors';

export const StyledTip = styled.div`
position: relative;
    
  .tip {
    display: ${({isVisible}) => isVisible ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    color: black;
    width: 200px;
    height: 40px;
    background-color: ${({color}) => color || colors.tip_error};
    opacity: 0.7;
    position: absolute;
    top: -55px;
    left: 10px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    &:before {
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: -15px;
      left: 0;

      width: 0; 
      height: 0; 
      border-bottom: 15px solid transparent;
      
      border-left: 15px solid ${({color}) => color || colors.tip_error};
    }

  }
`