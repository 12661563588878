import styled from 'styled-components';

export const StyledContainer = styled.div`
  @media (max-width: 768px) {
    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;
      position: relative;

      > span:first-child {
        flex-basis: initial;
      }

      > span:last-child {
        flex-direction: row-reverse;
        position: absolute;
        top: 0;
        right: 0;

        > span:last-child {
          margin-right: 15px;
        }
      }
    }
  }
`;
