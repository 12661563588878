import qs from 'querystring';

import { history } from 'configureStore';
import { updateAuthToken, updatePermissions, updateTimezone, refreshAuthToken, updateUserId } from 'containers/App/actions';
import { makeSelectRefreshTokenInProgress } from 'containers/App/selectors';
import { store } from '../index';
import { parseJwt } from './jwt';

export const API_PREFIX = '/api/v3';

export const POST = 'POST';
export const GET = 'GET';
export const DELETE = 'DELETE';
export const PUT = 'PUT';

export default (url, { body, headers: addHeaders, query, method = GET, withToken = true } = { withToken: true }) => {
  const authToken = localStorage.getItem('authToken');
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${authToken}`,
    ...addHeaders,
  };

  const options = {
    headers,
    method,
  };
  const urlPrefix = `${API_PREFIX}${url.indexOf('/') === 0 ? '' : '/'}`;
  let urlSuffix = '';

  if (body instanceof FormData) {
    options.body = body;
    delete headers['Content-Type'];
  } else if (method !== GET && body) {
    if (typeof body === 'string') {
      options.body = body;
    } else {
      options.body = JSON.stringify(body);
    }
  }

  if (query) {
    urlSuffix = typeof query === 'string' ? `?${query.replace('?', '')}` : `?${qs.stringify(query)}`;
  }

  return fetch(urlPrefix + url + urlSuffix, options).then((response) => {
    switch (response.status) {
      case 401:
        store.dispatch(updateAuthToken());
        store.dispatch(updatePermissions([]));
        store.dispatch(updateTimezone());
        store.dispatch(updateUserId());
        break;
      case 423:
        store.dispatch(updateAuthToken());
        store.dispatch(updatePermissions([]));
        store.dispatch(updateTimezone());
        store.dispatch(updateUserId());
        break;
      case 403:
        history.push('/access/denied');
        break;
      case 200:
        if (withToken && (!url.includes('/auth') && authToken)) {
          const payload = parseJwt(authToken);
          const shouldRefresh = new Date().getTime() > (payload.exp - 14 * 60) * 1000; // Refresh every minute
          const refreshTokenInProgress = makeSelectRefreshTokenInProgress()(store.getState());

          if (shouldRefresh && !refreshTokenInProgress) {
            store.dispatch(refreshAuthToken());
          }
        }
        break;
      default:
        break;
    }

    return response;
  });
};
