import {
  LOAD_STATES,
  LOAD_STATES_SUCCESS,
  LOAD_STATES_ERROR,
  LOAD_ACCESS_LEVELS,
  LOAD_ACCESS_LEVELS_ERROR,
  LOAD_ACCESS_LEVELS_SUCCESS,
  LOAD_CAMPUSES,
  LOAD_CAMPUSES_ERROR,
  LOAD_CAMPUSES_SUCCESS,
  LOAD_DISTRICTS,
  LOAD_DISTRICTS_ERROR,
  LOAD_DISTRICTS_SUCCESS,
  LOAD_ROLES,
  LOAD_ROLES_ERROR,
  LOAD_ROLES_SUCCESS,
} from './constants';

export const loadStates = (payload) => ({ type: LOAD_STATES, payload });
export const loadStatesSuccess = (payload) => ({ type: LOAD_STATES_SUCCESS, payload });
export const loadStatesError = (payload) => ({ type: LOAD_STATES_ERROR, payload });

export const loadDistricts = (payload) => ({ type: LOAD_DISTRICTS, payload });
export const loadDistrictsSuccess = (payload) => ({ type: LOAD_DISTRICTS_SUCCESS, payload });
export const loadDistrictsError = (payload) => ({ type: LOAD_DISTRICTS_ERROR, payload });

export const loadCampuses = (payload) => ({ type: LOAD_CAMPUSES, payload });
export const loadCampusesSuccess = (payload) => ({ type: LOAD_CAMPUSES_SUCCESS, payload });
export const loadCampusesError = (payload) => ({ type: LOAD_CAMPUSES_ERROR, payload });

export const loadRoles = (payload) => ({ type: LOAD_ROLES, payload });
export const loadRolesSuccess = (payload) => ({ type: LOAD_ROLES_SUCCESS, payload });
export const loadRolesError = (payload) => ({ type: LOAD_ROLES_ERROR, payload });

export const loadAccessLevels = (payload) => ({ type: LOAD_ACCESS_LEVELS, payload });
export const loadAccessLevelsSuccess = (payload) => ({ type: LOAD_ACCESS_LEVELS_SUCCESS, payload });
export const loadAccessLevelsError = (payload) => ({ type: LOAD_ACCESS_LEVELS_ERROR, payload });
