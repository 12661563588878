import { takeLatest, call, put } from 'redux-saga/effects';

import api, { POST } from 'utils/api';

import { notify } from "utils/notification";
import { resetPasswordActionSuccess, resetPasswordActionError } from './actions';
import { RESET_PASSWORD } from './constants';

function* resetPassword({ data }) {
  const response = yield call(api, '/auth/send-reset-password', {
    method: POST,
    body: data,
  });

  if (response.status === 200) {
    notify('success', 'Reset password mail was sent!')
    yield put(resetPasswordActionSuccess());
  } else if (response.status === 423) {
    const json = yield call([ response, 'json' ]);
    notify('error', json?.message || 'The email was not found, please try again!')
    yield put(resetPasswordActionError());
  } else {
    notify('error', 'The email was not found, please try again!')
    yield put(resetPasswordActionError());
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* getData() {
  yield takeLatest(RESET_PASSWORD, resetPassword);
}
