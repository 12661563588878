import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';

import { REDUCER_KEY } from './constants';
import { updatePassword, resetValues } from './state/actions';
import { makeUpdateLoading, makeUpdateSuccess, makeHasErrorSelect } from './state/selectors';
import reducer from './state/reducer';
import saga from './state/saga';
import Page from './Page';

const mapDispatchToProps = (dispatch) => ({
  updatePassword: (data) => {
    dispatch(updatePassword(data));
  },
  resetRedux: () => {
    dispatch(resetValues());
  },
});

const mapStateToProps = createStructuredSelector({
  updateLoading: makeUpdateLoading(),
  hasError: makeHasErrorSelect(),
  updateSuccess: makeUpdateSuccess(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: REDUCER_KEY, reducer });
const withSaga = injectSaga({ key: REDUCER_KEY, saga });

export default compose(
  withReducer,
  withSaga,
  withConnect
)(Page);
export { mapDispatchToProps };
