/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import Checkbox from './Checkbox3';

const SubcategoryHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

export default function CheckboxGroup({ options = [], onChange = () => {}, disabled, showAll }) {
  const [selection, setSelection] = useState({});

  const isAllSelected = () => {
    const trueSelection = Object.keys(selection).filter((key) => selection[key] === true);

    return trueSelection.length === options.length;
  };

  const groupedOptions = _.groupBy(options, 'subcategory');
  useEffect(() => {
    const preSelect = options.reduce((acc, el) => {
      return { ...acc, [el.id]: !!(el.option && el.option[0]) };
    }, {});
    setSelection(preSelect);
  }, [options]);

  useEffect(() => {
    onChange(options.filter((el) => selection[el.id] === true));
  }, [selection]);

  const selectUnselectAll = () => {
    if (disabled) return;
    if (!isAllSelected()) {
      const obj = options
        .map((el) => el.id)
        .reduce((acc, el) => {
          return { ...acc, [el]: true };
        }, {});
      setSelection(obj);
    } else {
      setSelection({});
    }
  };

  const itemsToCheckDisablingCondition = ['PLC_LESSON_FRAMING_TODAYS_OBJECTIVE_POSTED', 'PLC_LESSON_FRAMING_TODAYS_CLOSE_POSTED'];
  const itemToConditionallyDisable = 'PLC_LESSON_FRAMING_CLASS_ACTIVITY_ALIGNS_WITH_LESSON_FRAME';

  return (
    <>
      {showAll && !!options.length && <Checkbox title="Select / Unselect All" onChange={selectUnselectAll} checked={isAllSelected()} />}
      {Object.keys(groupedOptions).map((categoryName) => {
        return [
          categoryName !== 'undefined' && categoryName !== 'null' && <SubcategoryHeader>{categoryName}</SubcategoryHeader>,
          groupedOptions[categoryName].map((opt, index) => {
            const newOptions = [...options];
            let conditionallyDisabled = disabled;
            if (!disabled && opt.descriptorId === itemToConditionallyDisable) {
              conditionallyDisabled = !(
                newOptions.filter((item) => itemsToCheckDisablingCondition.includes(item.descriptorId) && item.value === true).length > 0
              );
            }

            return (
              <Checkbox
                key={opt.id}
                title={opt.name}
                disabled={conditionallyDisabled}
                checked={selection[opt.id]}
                onChange={() => {
                  if (!disabled) {
                    newOptions[index].value = !opt.value;
                    const shouldOptionBeDisabled = !(
                      newOptions.filter((item) => itemsToCheckDisablingCondition.includes(item.descriptorId) && item.value === true)
                        .length > 0
                    );
                    if (shouldOptionBeDisabled) {
                      const enabledOption = newOptions.filter(
                        (item) => item.descriptorId === itemToConditionallyDisable && item.value === true
                      );
                      if (enabledOption && enabledOption.length > 0) {
                        enabledOption[0].value = false;
                        setSelection((state) => ({ ...state, [enabledOption[0].id]: !state[enabledOption[0].id] }));
                      }
                    }

                    setSelection((state) => ({ ...state, [opt.id]: !state[opt.id] }));
                  }
                }}
              />
            );
          }),
        ];
      })}
      <i />
      <i />
      <i />
    </>
  );
}
