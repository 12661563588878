import React, { Component } from 'react'
import RadioGroup from 'components/RadioGroup';
import { isEqual } from 'lodash'
import styled from 'styled-components';
import { Line } from 'components/Line';
import colors from 'styles/colors';
import CheckboxGroup from '../../../components/CheckboxGroup';
import InputGroup from './InputGroup';
import InfoIcon from '../../../components/Icons/InfoIcon';


const SurveyOptionHeader = styled.div`
font-size: 28px;
font-weight: bold;
`

const SurveyOptionSubHeader = styled.div`
font-size: 20px;
font-weight: bold;
`

const SurveyOptionBlock = styled.div`
  margin-top: 60px;

  span[content="Module not in use"] {
    color: ${colors.bloodOrange};
  }
`

const SurveyOptionBody = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  
  & > * {
    flex: 1 0 32%;
  }
`;

export default class SurveyOptions extends Component {

  shouldComponentUpdate(nextProps, nextState) {
    const { options } = this.props
    if (options !== nextProps.options) return true;
    if (!this.state) return true;
    return !isEqual(nextState, this.state);
  }

  componentDidUpdate() {
    const { onChange = () => { } } = this.props;

    onChange(this.state);
  }

  render() {
    const { options = [], disabled = false } = this.props

    return (
      <section>
        {
          options.map((opt) => {
            const titleParts = opt.title.split(':::');
            return (
              <SurveyOptionBlock key={opt.id}>
                {
                  titleParts[0] && <SurveyOptionHeader>
                    {titleParts[0]}

                    <InfoIcon float="right" cursor="pointer" onClick={(e) => this.props.onTooltipClick?.(e, titleParts[0])} />
                  </SurveyOptionHeader>
                }
                {
                  titleParts[1] && <SurveyOptionSubHeader>{titleParts[1]}</SurveyOptionSubHeader>
                }

                <Line margin="16px 0" />

                <SurveyOptionBody>
                  {opt.type === 'number' && <InputGroup
                    options={opt.options}
                    disabled={disabled}
                    onChange={data => {
                      this.setState({ [opt.id]: data });
                    }}
                  />}
                  {opt.type === 'multi' && <CheckboxGroup
                    options={opt.options}
                    disabled={disabled}
                    onChange={data => {
                      this.setState({ [opt.id]: data });
                    }}
                  />}
                  {opt.type === 'radio' && <RadioGroup
                    options={opt.options}
                    disabled={disabled}
                    defaultValue={(opt.options || []).find(o => o.option && o.option[0])}
                    titleExtractor={el => el.name}
                    onChange={data => {
                      this.setState({ [opt.id]: data });
                    }}
                  />}
                </SurveyOptionBody>
              </SurveyOptionBlock>)
          })
        }

      </section>
    )
  }
}
