import React, { useState } from 'react';
import styled from 'styled-components';
import { Line } from 'components/Line';
import Checkbox from 'components/CheckboxFixed';
import colors from 'styles/colors';

import InfoIcon from '../../../components/Icons/InfoIcon';

import RadioGroup from './RadioGroup';
import InputGroup from './InputGroup';
import CheckboxGroup from './CheckboxGroup';
import { ModalButtons, ModalContent } from 'containers/admin/OrganizationPage/components/ConfirmationModal/styles';
import Modal from 'components/Modal';
import ActionButton from 'components/ActionButton/component';

const SurveyOptionHeader = styled.div`
  display: flex;
  font-size: 28px;
  font-weight: bold;
`;

const SurveyOptionBlock = styled.div`
  margin-top: 60px;

  span[content='Module not in use'] {
    color: ${colors.bloodOrange};
  }

  span[content='Module not in use'][disabled] {
    color: ${colors.warmGrey};
  }
`;

const SurveyOptionBody = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;

  & > * {
    flex: 1 0 32%;
  }
`;

export default function SurveyOptions({
  modules = [],
  onTooltipClick = () => {},
  onModuleNotInUseClick = () => null,
  disabled = false,
  onChange = () => {},
  submitted = false,
  invalidModules = [],
  isNew = false,
}) {
  const [warning, setWarning] = useState(false);
  const [singleModule, setSingleModule] = useState(null);

  const onChangeHandler = (index) => (data) => {
    const newModules = [...modules];
    newModules[index].options = data;
    onChange(newModules);
  };

  const closeModal = () => {
    setWarning(false);
  };

  const onConfirm = () => {
    onModuleNotInUseClick(singleModule, warning);
    setWarning(false);
  };

  return (
    <section>
      {modules.map((opt, index) => {
        const showError = submitted && !!invalidModules.find(({ name }) => opt.name === name);

        if (!opt.inUse) {
          return null;
        }

        return (
          <SurveyOptionBlock key={opt.id}>
            <SurveyOptionHeader>
              <span className="block-title--text">
                {opt.name}
                {showError && <span className="error-message">Module is required</span>}
              </span>
              <InfoIcon float="right" cursor="pointer" onClick={(e) => onTooltipClick(e, opt.name)} />
            </SurveyOptionHeader>

            <Line margin="16px 0" />
            {warning && (
              <>
                <Modal title="Confirmation" showModal onClose={closeModal}>
                  <ModalContent>
                    You will not be able to switch this module on again for this survey. Are you sure you want to switch module off ?
                  </ModalContent>
                  <ModalButtons>
                    <ActionButton title="Confirm" type="alert" className="button" onClick={onConfirm} />
                    <ActionButton title="Cancel" className="button cancel-button" onClick={closeModal} />
                  </ModalButtons>
                </Modal>
              </>
            )}
            {
              <Checkbox
                style={{ marginBottom: '28px' }}
                title="Module not in use"
                disabled={disabled}
                update={false}
                onChange={(val) => {
                  setSingleModule(opt);
                  setWarning(val);
                }}
              />
            }

            <SurveyOptionBody>
              {opt.type === 'number' && <InputGroup options={opt.options} disabled={disabled} onChange={onChangeHandler(index)} />}
              {opt.type === 'multi' && (
                <CheckboxGroup
                  options={opt.options}
                  title={opt.name}
                  disabled={disabled}
                  modules={modules}
                  index={index}
                  onChange={onChange}
                />
              )}
              {opt.type === 'radio' && (
                <RadioGroup options={opt.options} disabled={disabled} titleExtractor={(el) => el.name} onChange={onChangeHandler(index)} />
              )}
            </SurveyOptionBody>
          </SurveyOptionBlock>
        );
      })}
    </section>
  );
}
