/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Checkbox from '../../components/Checkbox';

import './style.scss';

export default class NewReportPage extends React.Component {
  state = {
    checked: false,
  };

  onCheckboxChanged = (newValue) => {
    this.setState({ checked: newValue });
  };

  render() {
    const { checked } = this.state;
    const getCurrentDate = (separator = '') => {
      const newDate = new Date();
      const date = newDate.getDate();
      const month = newDate.getMonth() + 1;
      const year = newDate.getFullYear();

      return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;
    };

    return (
      <div className="New-report-page">
        <Helmet>
          <title>New report</title>
          <meta name="description" content="New report page" />
        </Helmet>
        <div className="ns-page">
          <div id="new-survey-wrapper">
            <div id="page-title">NEW REPORT</div>
            <div id="steps-title">Simple report</div>
            <div className="container">
              <ul className="progressbar">
                <li className="active">HOME</li>
                <li className="active">CREATE REPORT</li>
                <li className="active">SIMPLE REPORT</li>
                <li>FINISH</li>
              </ul>
            </div>
            <div id="block-wrapper">
              <div id="block-title">Name your report:</div>
              <div className="select-numbers-1">Fall Semester Summary</div>
            </div>
            <div id="block-wrapper" className="date-range-block">
              <div id="block-title">Date Range</div>
              <hr id="block-line" />
              <div id="inner-block-line">
                <div id="inner-block">
                  <div className="inner-item">Start date: {getCurrentDate('-')}</div>
                </div>
              </div>
              <div id="inner-block-line">
                <div id="inner-block">
                  <div className="inner-item">End date: {getCurrentDate('-')}</div>
                </div>
              </div>
            </div>
            <div id="block-wrapper">
              <div id="block-title">Data Set</div>
              <hr id="block-line" />
              <div id="inner-block-line">
                <div className="inner-item">Select type of filter:</div>
                <div className="custom-select">
                  <select>
                    <option value="0">Natioanl Summarye</option>
                    <option value="1">Local Summary</option>
                  </select>
                </div>
              </div>
              <div id="inner-block-line">
                <div className="inner-item">Grade:</div>
                <div className="custom-select">
                  <select>
                    <option value="0">All</option>
                    <option value="1">High school</option>
                  </select>
                </div>
                <div style={{ padding: 20, margin: 20 }} />
                <div className="inner-item">Subject:</div>
                <div className="custom-select">
                  <select>
                    <option value="0">All</option>
                    <option value="1">Physics</option>
                  </select>
                </div>
              </div>
            </div>
            <div id="block-wrapper">
              <div id="block-title">Report content</div>
              <hr id="block-line" />
              <div className="checkbox">
                <form className="blockWrapper">
                  <div className="checkboxWrapper">
                    <Checkbox onChange={this.onCheckboxChanged} checked={checked} />
                  </div>
                  <div className="labelModule">Select / Unselect All</div>
                </form>
              </div>
            </div>
            <div id="block-wrapper-buttons">
              <div id="back-button">
                <Link to="/home">
                  <button type="submit" className="btn btn-primary">
                    BACK
                  </button>
                </Link>
              </div>
              <div id="cancel-button">
                <Link to="/home">
                  <button type="submit" className="btn btn-primary">
                    CANCEL
                  </button>
                </Link>
              </div>
              <div id="finish-button">
                <Link to="/home">
                  <button type="submit" className="btn btn-primary">
                    SAVE
                  </button>
                </Link>
              </div>
            </div>
            <div id="block-wrapper">
              <hr id="block-line" />
            </div>
            <div className="block-wrapper-buttons-2">
              <div className="save-print-button">
                <Link to="/home">
                  <button type="submit" className="btn btn-primary">
                    save & print
                  </button>
                </Link>
              </div>
              <div className="save-run-button">
                <Link to="/home">
                  <button type="submit" className="btn btn-primary">
                    save & run
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="new-survey-tooltip-block">
            <div className="tooltip-title">Info</div>
            <div className="tooltip-text">
              <p>
                <span className="tooltip-text-title">Instructor Work Area:</span> Teacher engaged in administrative duties at the teacher
                desk or computer/technology space.
              </p>
              <p>
                <span className="tooltip-text-title">Lecture Position:</span> Teacher teaching or monitoring with significant space between
                teacher and student(s), but not in work area.
              </p>
              <p>
                <span className="tooltip-text-title">Power Zone:</span> Teacher is teaching or monitoring in close proximity to one or more
                students in any area of the room.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
