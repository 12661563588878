import { call, put, takeLatest } from 'redux-saga/effects';

import api from 'utils/api';

import { loadTemplatesSuccess } from './actions';
import { LOAD_TEMPLATES } from './constants';

function* loadTemplatesData() {
  // eslint-disable-next-line no-undef
  const response = yield call(api, '/report/type/list');
  const respJson = yield call([response, 'json']);

  yield put(loadTemplatesSuccess(respJson.data));
}

export default function* getData() {
  yield takeLatest(LOAD_TEMPLATES, loadTemplatesData);
}
