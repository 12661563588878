import styled from 'styled-components';
import colors from '../styles/colors';

const Sidebar = styled.div`
  background-color: ${colors.nightBlue};
  flex: ${({ width = '400px' }) => `0 0 ${width}`};
  padding: ${({ padding = '0' }) => `${padding}`};

  @media (max-width: 768px) {
    flex: 1;
  }
`;

export default Sidebar;