import { takeLatest, call, put } from 'redux-saga/effects';

import api, { POST } from 'utils/api';

import { updateAuthToken, updatePermissions, updateRoles, updateTimezone, refreshAuthTokenSuccess, updateUserId } from './actions';
import { REFRESH_AUTH_TOKEN } from './constants';

function* refreshTokenData() {
  const response = yield call(api, '/auth/token/refresh', { method: POST });
  const respJson = yield call([response, 'json']);

  if (response.status === 200) {
    yield put(updateTimezone(respJson.timezone));
    yield put(updatePermissions(respJson.permissions));
    yield put(updateRoles(respJson.roles));
    yield put(updateUserId(respJson.id));
    yield put(updateAuthToken(respJson.token));
  }

  yield put(refreshAuthTokenSuccess());
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* getData() {
  yield takeLatest(REFRESH_AUTH_TOKEN, refreshTokenData);
}
