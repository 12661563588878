import styled from 'styled-components';
// import colors from 'styles/colors';

export const Wrapper = styled.div`
  position: fixed;
  left: 0;
  height: 100%;
  top: 0;
  text-align: left;
  width: 100%;
  z-index: 2;
`;

export const Background = styled.div`
  background: rgba(9, 33, 67, 0.5);
  left: 0;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
`;

export const StyledModal = styled.div`
  background: white;
  display: inline-block;
  left: 50%;
  min-width: 500px;
  min-height: 400px;
  overflow-y: auto;
  padding: 20px;
  position: relative;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: 4;

  @media (max-width: 768px) {
    max-width: 95%;
    min-width: initial;
    max-height: 90%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 25px;
`;

export const Title = styled.div`
  flex: 1;
  color: #000000;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  line-height: 36px;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
`;

export const Content = styled.div`
overflow-y: auto;
`;
