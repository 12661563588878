import { fromJS } from 'immutable';
import { LOAD_SURVEY_TEMPLATES_DATA,
         LOAD_SURVEY_TEMPLATES_DATA_SUCCESS,
         LOAD_CHOSEN_SURVEY_TEMPLATE,
         LOAD_CHOSEN_SURVEY_TEMPLATE_SUCCESS } from './constants';

const initialState = fromJS({
  loading: false,
  templates: [],
  username: '',
  chosenTemplate:{},
});

function newSurveyReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_SURVEY_TEMPLATES_DATA: {
      return state.set('loading', true);
    }

    case LOAD_SURVEY_TEMPLATES_DATA_SUCCESS: {
      return state.set('templates', action.data).set('loading', false);
    }

    case LOAD_CHOSEN_SURVEY_TEMPLATE: {
      return state.set('loading', true);
    }

    case LOAD_CHOSEN_SURVEY_TEMPLATE_SUCCESS: {
      return state.set('chosenTemplate', action.data).set('loading', false);
    }

    default:
      return state;
  }
}

export default newSurveyReducer;
