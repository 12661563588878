import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faUser } from '@fortawesome/free-solid-svg-icons';

import { notifyError } from 'utils/notification';

import './style.scss';

export default class ChangePwdPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      passwordRepeat: '',
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSend = this.handleSend.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  async handleSend(e) {
    e.preventDefault();
    const {
      match: {
        params: { token },
      },
      changePassword,
    } = this.props;
    const { password, passwordRepeat } = this.state;

    if (password === passwordRepeat) {
      changePassword({ token, password });
    } else {
      notifyError('Passwords don\'t match, please try again!')
    }
  }

  render() {
    const { password, passwordRepeat, submitted } = this.state;
    const { changeLoading } = this.props;

    return (
      <div className="Change-pwd-page">
        <Helmet>
          <title>Change password</title>
          <meta name="description" content="Change password page" />
        </Helmet>
        <div id="wrapper">
          <div id="form-container">
            <div className="row">
              <button type="button" className="toggle">
                <Link to="/">
                  Login
                  <span className="icon-wrapper">
                    <FontAwesomeIcon icon={faSignInAlt} />
                  </span>
                </Link>
              </button>
              <button type="button" className="toggle">
                <Link to="/">
                  Subscription Request
                  <span className="icon-wrapper">
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                </Link>
              </button>
            </div>
            <div className="row">
              <div id="change-pwd-wrapper">
                <div className="title">change password</div>
                <form name="form" onSubmit={this.handleSend}>
                  <div className={`form-group${submitted && !password ? ' has-error' : ''}`}>
                    <label htmlFor="password">
                      {submitted && !password ? <div className="help-block">New password is required</div> : 'New password'}
                      <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                    </label>
                  </div>
                  <div className={`form-group${submitted && !passwordRepeat ? ' has-error' : ''}`}>
                    <label htmlFor="password">
                      {passwordRepeat !== password ? (
                        <div className="help-block">Repeat password is different than password</div>
                      ) : (
                          'Repeat password'
                        )}
                      <input
                        type="password"
                        className="form-control"
                        name="passwordRepeat"
                        value={passwordRepeat}
                        onChange={this.handleChange}
                      />
                    </label>
                  </div>
                  <div className="form-group position-absolute align-bottom" id="change-pwd-button">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={this.handleSend}
                      disabled={!passwordRepeat || !password || passwordRepeat !== password || changeLoading}
                    >
                      Change password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div id="card-container" />
        </div>
      </div>
    );
  }
}
