export const LOAD_STATES = 'powerwalks/UserRole/LOAD_STATES';
export const LOAD_STATES_SUCCESS = 'powerwalks/UserRole/LOAD_STATES_SUCCESS';
export const LOAD_STATES_ERROR = 'powerwalks/UserRole/LOAD_STATES_ERROR';

export const LOAD_ROLES = 'powerwalks/UserRole/LOAD_ROLES';
export const LOAD_ROLES_SUCCESS = 'powerwalks/UserRole/LOAD_ROLES_SUCCESS';
export const LOAD_ROLES_ERROR = 'powerwalks/UserRole/LOAD_ROLES_ERROR';

export const LOAD_ACCESS_LEVELS = 'powerwalks/UserRole/LOAD_ACCESS_LEVELS';
export const LOAD_ACCESS_LEVELS_SUCCESS = 'powerwalks/UserRole/LOAD_ACCESS_LEVELS_SUCCESS';
export const LOAD_ACCESS_LEVELS_ERROR = 'powerwalks/UserRole/LOAD_ACCESS_LEVELS_ERROR';

export const LOAD_DISTRICTS = 'powerwalks/UserRole/LOAD_DISTRICTS';
export const LOAD_DISTRICTS_SUCCESS = 'powerwalks/UserRole/LOAD_DISTRICTS_SUCCESS';
export const LOAD_DISTRICTS_ERROR = 'powerwalks/UserRole/LOAD_DISTRICTS_ERROR';

export const LOAD_CAMPUSES = 'powerwalks/UserRole/LOAD_CAMPUSES';
export const LOAD_CAMPUSES_SUCCESS = 'powerwalks/UserRole/LOAD_CAMPUSES_SUCCESS';
export const LOAD_CAMPUSES_ERROR = 'powerwalks/UserRole/LOAD_CAMPUSES_ERROR';
