import { fromJS } from 'immutable';

import { UPDATE_PASSWORD, UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_ERROR, RESET_VALUES } from './constants';

const initialState = fromJS({
  updateLoading: false,
  hasError: false,
  updateSuccess: null,
});

function updatePasswordReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PASSWORD:
      return state.set('updateLoading', true);
    case UPDATE_PASSWORD_SUCCESS:
      return state.set('updateLoading', false).set('updateSuccess', true);
    case UPDATE_PASSWORD_ERROR:
      return state.set('updateLoading', false).set('hasError', true);

    case RESET_VALUES:
      return initialState;

    default:
      return state;
  }
}

export default updatePasswordReducer;
