import { createSelector } from 'reselect';
import jwtDecode from 'jwt-decode';

const selectGlobal = (state) => state.get('global');
const selectRoute = (state) => state.get('router');

export const makeSelectLoading = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.get('loading')
  );

export const makeSelectPermissions = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.get('permissions').toJS()
  );

export const makeSelectRoles = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.get('roles').toJS()
  );

export const makeSelectCurrentRole = () => createSelector(
    makeSelectRoles(),
    (roles) => roles?.[0] ?? ''
);

export const makeSelectAuthToken = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.get('authToken')
  );

export const makeSelectUserId = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.get('userId')
  );

export const makeSelectAuthData = () =>
  createSelector(
    selectGlobal,
    (globalState) => jwtDecode(globalState.get('authToken'))
  );

export const makeSelectAuthenticated = () =>
  createSelector(
    selectGlobal,
    (globalState) => {
      const token = globalState.get('authToken');
      const today = new Date();

      if (!token || token === 'undefined') {
        return false;
      }

      const decoded = jwtDecode(token);

      return decoded.exp > today.getTime() / 1000;
    }
  );

export const makeSelectLocation = () =>
  createSelector(
    selectRoute,
    (routeState) => routeState.get('location').toJS()
  );

export const makeSelectTimezone = () =>
  createSelector(
    selectGlobal,
    state => state.get('timezone')
  );

export const makeSelectRefreshTokenInProgress = () =>
  createSelector(
    selectGlobal,
    state => state.get('refreshTokenInProgress')
  );
