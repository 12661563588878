import { compose } from 'redux';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form/immutable';
import { createStructuredSelector } from 'reselect';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';

import { loadUser, saveUser, updateUser } from './state/actions';
import { makeSelectUser, makeUserLoading, makeSubmitLoading } from './state/selectors';
import reducer from './state/reducer';
import saga from './state/saga';
import Page from './Page';
import { FORM_ID, REDUCER_KEY } from './constants';

const mapDispatchToProps = (dispatch) => ({
  loadUser: (id) => {
    dispatch(loadUser(id));
  },
  saveUser: (payload) => {
    dispatch(saveUser(payload));
  },
  updateUser: (payload) => {
    dispatch(updateUser(payload));
  },
});

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  isUserLoading: makeUserLoading(),
  isSubmitLoading: makeSubmitLoading(),
  editUser: getFormValues(FORM_ID),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: REDUCER_KEY, reducer });
const withSaga = injectSaga({ key: REDUCER_KEY, saga });

export default compose(
  withReducer,
  withSaga,
  withConnect
)(Page);

export { mapDispatchToProps };
