import styled from 'styled-components';
import colors from '../../styles/colors';

export const CheckboxWrapper = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const CheckboxContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: ${colors.lightgrey};
  width: 24px;
  height: 24px;
`;

export const CheckboxMark = styled.span`
  position: relative;
  left: 0px;
  top: -2px;
  width: 9px;
  height: 15px;
  border: solid ${colors.bloodOrange};
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
`;

export const CheckboxTitle = styled.span`
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 600;
  line-height: 2.86;
  margin-left: 18px;
`;
