import { fromJS } from 'immutable';

import { LOAD_USER, LOAD_USER_SUCCESS, LOAD_USER_ERROR, SAVE_USER, SAVE_USER_SUCCESS, SAVE_USER_ERROR, UPDATE_USER } from './constants';

// The initial state of the Users
const initialState = fromJS({
  user: null,
  userLoading: false,
  submitLoading: false,
});

function userReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_USER:
      return state.set('userLoading', true);
    case LOAD_USER_SUCCESS:
      return state.set('userLoading', false).set('user', action.payload);
    case LOAD_USER_ERROR:
      return state.set('userLoading', false);
    case UPDATE_USER:
      return state.mergeDeep({ user: action.payload });
    case SAVE_USER:
      return state.set('submitLoading', true);
    case SAVE_USER_SUCCESS:
      return state.set('submitLoading', false);
    case SAVE_USER_ERROR:
      return state.set('submitLoading', false);
    default:
      return state;
  }
}

export default userReducer;
