/*
 * Edit User Page
 */
import React from 'react';
import { Helmet } from 'react-helmet';

import ActionButton from 'components/ActionButton';

import Form from './components/Form';
import './styles.scss';
import { Wrapper, Row, Sidebar, Title, ActionButtonWrapper } from './styled-components';

export default class MyProfilePage extends React.PureComponent {
  componentDidMount() {
    const { loadUser } = this.props;
    loadUser();
  }

  updateUser = (event) => {
    const { editUser, saveUser } = this.props;
    const { user } = editUser.toJS ? editUser.toJS() : { user: {} };
    event.preventDefault();

    user.userInfo.salutation = user.userInfo.salutation.label;
    user.userInfo.state = user.userInfo.state.label;

    saveUser(user);
  };

  render() {
    const { user, isSubmitLoading } = this.props;

    return (
      <article>
        <Helmet>
          <title>My Profile Page</title>
          <meta name="description" content="Power Walks" />
        </Helmet>
        <div className="edit-user-page">
          <Wrapper>
            <Row>
              <Title>My Profile</Title>
            </Row>
            <Form initialValues={{ user }} />
          </Wrapper>
          <Sidebar>
            <ActionButtonWrapper>
              <ActionButton big title="SAVE" onClick={this.updateUser} disabled={isSubmitLoading} />
            </ActionButtonWrapper>
            <ActionButtonWrapper>
              <ActionButton big title="CANCEL" type="alert" />
            </ActionButtonWrapper>
          </Sidebar>
        </div>
      </article>
    );
  }
}
