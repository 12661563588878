import { createSelector } from 'reselect';

import { REDUCER_KEY } from './constants';

const selectChangePassword = (state) => state.get(REDUCER_KEY);

export const makeSelectChangeLoading = () =>
  createSelector(
    selectChangePassword,
    (changePasswordState) => changePasswordState.get('resetLoading')
  );

export const makeSelectError = () =>
  createSelector(
    selectChangePassword,
    (changePasswordState) => changePasswordState.get('hasError')
  );
