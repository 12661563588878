import styled from 'styled-components';
import colors from '../../styles/colors';

export const RadioWrapper = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? '0.5' : '1'};
  user-select: ${props => props.disabled ? 'none' : 'auto'};
`;

export const RadioContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: ${colors.lightgrey};
  width: 24px;
  min-width: 24px;
  height: 24px;
`;

export const RadioMark = styled.span`
  position: relative;
  border-radius: 100%;
  border: 5px solid ${colors.bloodOrange};
`;

export const RadioTitle = styled.span`
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;
  margin-left: 18px;
`;
