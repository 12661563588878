import { takeLatest, call, put } from 'redux-saga/effects';

import { updateAuthToken, updatePermissions, updateRoles, updateTimezone, updateUserId } from 'containers/App/actions';
import api, { POST } from 'utils/api';
import { notify } from 'utils/notification';

import { changePasswordActionError, changePasswordActionSuccess } from './actions';
import { CHANGE_PASSWORD } from './constants';

function* changePassword({ data }) {
  const response = yield call(api, '/auth/reset-password', {
    method: POST,
    body: data,
  });
  const respJson = yield call([response, 'json']);

  if (response.status === 200) {
    notify('success', 'The password was updated!');
    yield put(updateTimezone(respJson.timezone));
    yield put(updatePermissions(respJson.permissions));
    yield put(updateRoles(respJson.roles));
    yield put(updateUserId(respJson.id));
    yield put(updateAuthToken(respJson.token));
    yield put(changePasswordActionSuccess(respJson));
  } else {
    notify('error', 'The password was not updated, please try again!');
    yield put(changePasswordActionError());
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* getData() {
  yield takeLatest(CHANGE_PASSWORD, changePassword);
}
