import { takeLatest, call, put } from 'redux-saga/effects';

import api from 'utils/api';

import { loadStatesSuccess, loadDistrictsSuccess, loadCampusesSuccess, loadRolesSuccess, loadAccessLevelsSuccess } from './actions';
import { LOAD_STATES, LOAD_DISTRICTS, LOAD_CAMPUSES, LOAD_ROLES, LOAD_ACCESS_LEVELS } from './constants';

function* loadStatesData() {
  const response = yield call(api, `/data/states/all`);
  const respJson = yield call([response, 'json']) || { data: [] };
  const states = respJson.data.map((s) => ({ label: s.name, value: s.id }));

  yield put(loadStatesSuccess(states));
}

function* loadDistrictsData({ payload }) {
  const response = yield call(api, `/data/districts`, { query: { stateId: payload } });
  const respJson = yield call([response, 'json']) || { data: [] };
  const districts = respJson.data.map((s) => ({ label: s.name, value: s.id }));

  yield put(loadDistrictsSuccess(districts));
}

function* loadCampusesData({ payload }) {
  const response = yield call(api, `/data/campuses`, { query: { districtId: payload } });
  const respJson = yield call([response, 'json']) || { data: [] };
  const campuses = respJson.data.map((s) => ({ label: s.name, value: s.id }));

  yield put(loadCampusesSuccess(campuses));
}

function* loadRolesData() {
  const response = yield call(api, `/data/roles`);
  const respJson = yield call([response, 'json']) || { data: [] };
  const roles = respJson.data.map((s) => ({ label: s.title, value: s.id }));

  yield put(loadRolesSuccess(roles));
}

function* loadAccessLevelsData({ payload }) {
  const response = yield call(api, `/data/access-levels`, { query: { roleId: payload } });
  const respJson = yield call([response, 'json']) || { data: [] };
  const accessLevels = respJson.data.map((s) => ({ label: s.title, value: s.id }));

  yield put(loadAccessLevelsSuccess(accessLevels));
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* getData() {
  yield takeLatest(LOAD_STATES, loadStatesData);
  yield takeLatest(LOAD_DISTRICTS, loadDistrictsData);
  yield takeLatest(LOAD_CAMPUSES, loadCampusesData);
  yield takeLatest(LOAD_ROLES, loadRolesData);
  yield takeLatest(LOAD_ACCESS_LEVELS, loadAccessLevelsData);
}
