/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import styled from 'styled-components';

import RadioGroup from 'components/RadioGroup';
import colors from 'styles/colors';

const ListStates = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > * {
    flex: 0 0 25%;
  }

  @media (max-width: 768px) {
    & > * {
      flex: 0 0 50%;
    }
  }
`;

const Title = styled.div`
  text-align: center;
  color: ${colors.bloodOrange};
  /* text-transform: uppercase; */
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 25px;
`;

export default function SelectState({ onSelect = () => { }, defaultValue, options = [] }) {
  return (
    <div>
      <Title>Select Your State</Title>

      <ListStates>
        <RadioGroup options={options} titleExtractor={(val) => val.name} onChange={onSelect} defaultValue={defaultValue}></RadioGroup>
      </ListStates>
    </div>
  );
}
