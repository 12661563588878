/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

import HomePage from '../HomePage/Loadable';
import SurveysPage from '../SurveysPage/Loadable';
import NewSurveyConfig from '../NewSurveyConfig';
import LoginPage from '../auth/LoginPage/Loadable';
import NotFoundPage from '../NotFoundPage/Loadable';
import AccessDeniedPage from '../AccessDeniedPage/Loadable';
import CountsPage from '../CountsPage/Loadable';
import ReportsPage from '../ReportsPage/Loadable';
import ResourcesPage from '../ResourcesPage/Loadable';
import BubbleMessage from '../BubbleMessage/Loadable';
import UserBehavior from '../UserBehavior/Loadable';
import MatrixBuilder from '../MatrixBuilder/Loadable';
import PPNoticePage from '../PPNoticePage/Loadable';
import PPNoticeViewPage from '../PPNoticeViewPage/Loadable';

import SnapshotBrowsePage from '../reports/snapshot/BrowsePage/Loadable';
import CreateSnapshotReportPage from '../reports/snapshot/CreatePage/Loadable';
import SnapshotChartPage from '../reports/snapshot/ChartPage/Loadable';

import PPBadgePage from '../PPBadgePage/Loadable';
import PPBadgeViewPage from '../PPBadgeViewPage/Loadable';
import RewardsPage from '../RewardsPage/Loadable';
// import NumericalReportsPage from '../NumericalReportsPage/Loadable';
import SurveyTemplatesPage from '../SurveyTemplatesPage/Loadable';
import SupportMaterialsPage from '../SupportMaterialsPage/Loadable';
import ResearchPage from '../ResearchPage/Loadable';
import MobileApplicationPage from '../MobileApplicationPage/Loadable';
import CustomSurveyPage from '../CustomSurveyPage/Loadable';
import ChallengeListPage from '../ChallengeListPage/Loadable';
import ChallengeDetailsPage from '../ChallengeDetailsPage/Loadable';
import ChallengeAcceptPage from '../ChallengeAcceptPage/Loadable';
import CustomReportPage, { DataReportPage, QuickClassroomReportPage, SimpleReportPage, TimeReportPage } from '../CustomReportPage/Loadable';
import RosterAndSchedulePage from '../RosterAndSchedulePage/Loadable';
import RosterPage from '../RosterPage/Loadable';
import PrintTemplatePage from '../PrintTemplatePage/Loadable';
import WeekReminderPage from '../WeekReminderPage/Loadable';
import PinsPage from '../PinsPage/Loadable';
import ObserverNotesPage from '../ObserverNotesPage/Loadable';
import MergeRoomPage from '../admin/rooms/MergePage/Loadable';
import MergeCampusesPage from '../admin/campuses/MergePage/Loadable';

import SurveyCongratulationPage from '../SurveyCongratulationPage/Loadable';
import NewReportCongratulationPage from '../NewReportCongratulationPage/Loadable';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './style.scss';
import ForgotPasswordPage from '../auth/ForgotPasswordPage';
import ChangePasswordPage from '../auth/ChangePasswordPage';
import NewSurveyPage from '../NewSurvey';
import NewPLCSurveyPage from '../NewPLCSurvey';
import MyProfilePage from '../profile/MyProfilePage';
import NewReportPage from '../NewReport/NewReportPage';
import NewReportTemplatesPage from '../NewReportTemplatesPage';
import UsersPage from '../admin/users/UsersPage/Loadable';
import EditUserPage from '../admin/users/EditUserPage/Loadable';
import UsersImportPage from '../admin/users/UsersImportPage/Loadable';
import RoomsImportPage from '../admin/rooms/ImportPage/Loadable';
import OrganizationPage from '../admin/OrganizationPage/Loadable';
import MigrationPage from '../admin/MigrationPage/Loadable';
import LogsPage from '../admin/LogsPage/Loadable';
import SubscriptionsPage from '../admin/SubscriptionsPage/Loadable';
import SendObservationNotes from '../SendObservationNotes/Loadable';
import UpdatePasswordPage from '../profile/UpdatePasswordPage';
import ReportChartPage from '../ReportChartPage/Loadable';
import SurveyNoticePage from '../SurveyNoticePage/Loadable';
import SendNotices from '../SendNotices/Loadable';

import BubbleMessageLatest from '../../component/BubbleMessage';
import PlcReportPage from '../PLCReportPage/Loadable';
import ReportDataPage from '../ReportDataPage/Loadable';

import { ReportTypes } from '../../constants';

const BackgroundElement = styled.div`
  background-color: white;
`;

export default function App({ authenticated, logout }) {
  return (
    <div className="app-wrapper">
      <ToastContainer className="pr-10" />
      <Helmet titleTemplate="Power Walks" defaultTitle="Power Walks">
        <meta name="description" content="Power Walks" />
      </Helmet>{' '}
      <Header displayNav={authenticated} logout={logout} /> {authenticated && <BubbleMessageLatest />}
      <BackgroundElement>
        {' '}
        {authenticated ? (
          // * Authenticated Routes *
          <Switch>
            <Route path="/report/data-page" exact component={ReportDataPage} />

            <Route exact path="/home" render={() => <HomePage />} />
            <Route path="/surveys" component={SurveysPage} />
            <Route path="/send-observation-notes" component={SendObservationNotes} />
            <Route path="/standard-counts" render={(prop) => <CountsPage {...prop} componentType="standard" />} />
            <Route path="/averages-counts" render={(prop) => <CountsPage {...prop} componentType="average" />} />
            <Route path="/top-performers-counts" render={(prop) => <CountsPage {...prop} componentType="top" />} />
            <Route path="/bubble-message" component={BubbleMessage} />
            <Route path="/user-behavior" component={UserBehavior} />
            <Route path="/matrix-builder" component={MatrixBuilder} />

            <Route path="/reports/snapshot/run/:id" component={SnapshotChartPage} />
            <Route path="/reports/snapshot/edit/:id" component={CreateSnapshotReportPage} />
            <Route path="/reports/snapshot/create" component={CreateSnapshotReportPage} />
            <Route path="/reports/snapshot" component={SnapshotBrowsePage} />

            <Route path="/reports" component={ReportsPage} />
            <Route path="/run-report/:id" component={ReportChartPage} />
            <Route path="/resources" component={ResourcesPage} />
            <Route path="/newsurvey" render={(prop) => <NewSurveyPage {...prop} isNew />} />
            <Route path="/newplcsurvey" component={NewPLCSurveyPage} />
            <Route path="/viewsurveydata/:id" render={(prop) => <NewSurveyPage {...prop} isView />} />
            <Route path="/editsurveydata/:id" render={(prop) => <NewSurveyPage {...prop} isEdit />} />
            <Route path="/newreporttempl" component={NewReportTemplatesPage} />
            <Route path="/surveycongratulation" component={SurveyCongratulationPage} />
            <Route path="/survey-notice" component={SurveyNoticePage} />
            <Route path="/send-survey-notice" component={SendNotices} />
            <Route path="/pp-notice" component={PPNoticePage} />
            <Route path="/pp-notice-view/:id" component={PPNoticeViewPage} />
            <Route path="/pp-badge" component={PPBadgePage} />
            <Route path="/pp-badge-view/:id" component={PPBadgeViewPage} />
            <Route path="/newreportcongratulation" component={NewReportCongratulationPage} />
            {/* <Route path="/numerical-report" component={NumericalReportsPage} /> */}
            <Route path="/survey-templates" component={SurveyTemplatesPage} />
            <Route path="/support-materials" component={SupportMaterialsPage} />
            <Route path="/week-reminder-page" component={WeekReminderPage} />
            <Route path="/pins" component={PinsPage} />
            <Route path="/observer-notes" component={ObserverNotesPage} />
            <Route path="/research" component={ResearchPage} />
            <Route path="/mobile-applications" component={MobileApplicationPage} />
            <Route exact path="/custom-survey" render={(props) => <CustomSurveyPage {...props} />} />
            <Route path="/custom-survey/edit" render={(props) => <CustomSurveyPage {...props} edit />} />
            <Route path="/rewards" component={RewardsPage} />
            <Route path="/newreport" component={NewReportPage} />
            <Route path="/challenge-list" component={ChallengeListPage} />
            <Route path="/challenge-details/:id" component={ChallengeDetailsPage} />
            <Route path="/challenge-accept/:id" component={ChallengeAcceptPage} />
            <Route path="/roster-and-schedule" component={RosterAndSchedulePage} />
            <Route path="/roster/:id?" component={RosterPage} />
            <Route path="/print-template" component={PrintTemplatePage} />
            <Route path="/simple-report" render={(prop) => <SimpleReportPage {...prop} state="new" />} />
            <Route path="/simple-report-view" render={(prop) => <SimpleReportPage {...prop} state="update" />} />
            <Route path="/quick-classroom-report" render={(prop) => <QuickClassroomReportPage {...prop} state="new" />} />
            <Route path="/quick-classroom-report-view" render={(prop) => <QuickClassroomReportPage {...prop} state="update" />} />
            <Route path="/data-report" render={(prop) => <DataReportPage {...prop} state="new" />} />
            <Route path="/data-report-view" render={(prop) => <DataReportPage {...prop} state="update" />} />
            <Route path="/time-report" render={(prop) => <TimeReportPage {...prop} state="new" />} />
            <Route path="/time-report-view" render={(prop) => <TimeReportPage {...prop} state="update" />} />
            <Route path="/custom-report" render={(prop) => <CustomReportPage {...prop} multipleDataSets state="new" />} />
            <Route
              path="/custom-report-view"
              render={(prop) => <CustomReportPage reportType={ReportTypes.CustomReportType} {...prop} multipleDataSets state="update" />}
            />
            <Route path="/plc-report" render={(prop) => <PlcReportPage {...prop} state="new" />} />
            <Route path="/plc-report-view" render={(prop) => <PlcReportPage {...prop} state="update" />} />
            <Route path="/admin/campuses/merge" component={MergeCampusesPage} />
            <Route path="/admin/rooms/merge" component={MergeRoomPage} />
            <Route path="/new-survey-config" component={NewSurveyConfig} />
            <Route path="/admin/rooms/import" component={RoomsImportPage} />
            <Route path="/admin/users/import" component={UsersImportPage} />
            <Route path="/admin/users/edit/:id" component={EditUserPage} />
            <Route path="/admin/users/create" component={EditUserPage} />
            <Route path="/admin/users" component={UsersPage} />
            <Route path="/admin/migration" component={MigrationPage} />
            <Route path="/admin/logs" component={LogsPage} />
            <Route path="/admin/organization" component={OrganizationPage} />
            <Route path="/admin/subscriptions" component={SubscriptionsPage} />
            <Route path="/my-profile/password" component={UpdatePasswordPage} />
            <Route path="/my-profile" component={MyProfilePage} />
            <Route path="/auth" render={() => <Redirect to="/home" />} />
            <Route path="/" exact render={() => <Redirect to="/home" />} />
            <Route path="/access/denied" component={AccessDeniedPage} />
            <Route path="" component={NotFoundPage} />
          </Switch>
        ) : (
          // * Anonymous Routes *
          <Switch>
            <Route path="/auth/login" render={() => <LoginPage />} />
            <Route path="/auth/forgot-password" component={ForgotPasswordPage} />
            <Route path="/auth/change-password/:token" component={ChangePasswordPage} />
            <Route path="" render={() => <Redirect to="/auth/login" />} />{' '}
          </Switch>
        )}{' '}
      </BackgroundElement>{' '}
      <Footer />
    </div>
  );
}
