import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AccessControl from '../AccessControl';

import MenuChildren from './MenuChildren';

export default ({ item, actions }) => {
  const [open, setopen] = useState(false);
  const toggleOpen = () => setopen(!open);

  return (
    <AccessControl {...item.resource}>
      <div className="router-link">
        <div className="router-main-item" onClick={toggleOpen}>
          <span className="icon-wrapper">
            {
              item.customIcon || <FontAwesomeIcon icon={item.icon} />
            }
          </span>
          {item.title}
        </div>

        <MenuChildren open={open} items={item.children} actions={actions} />
      </div>
    </AccessControl >
  )
}