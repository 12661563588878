import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import * as yup from 'yup';

import UAParser from 'ua-parser-js';

import './styles.scss';
import api from 'utils/api';
import { notifyError, notifySuccess } from 'utils/notification';

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  phone: yup.string(),
  type: yup.string(),
  description: yup.string().required(),
  'tech-type': yup.string().when('type', {
    is: (val) => val === 'tech',
    then: yup.string().required(),
  }),
  'device-type': yup.string().when('tech-type', {
    is: (val) => ['PW CLC mobile application (iOS)', 'PW CLC mobile application (Android)'].includes(val),
    then: yup.string().required(),
  }),
  'os-type': yup.string().when('tech-type', {
    is: (val) => ['PW CLC mobile application (iOS)', 'PW CLC mobile application (Android)'].includes(val),
    then: yup.string().required(),
  }),
});

const mapping = [
  {
    name: 'Contact name',
    value: 'name',
    isRequired: true,
  },
  {
    name: 'Email address',
    value: 'email',
    isRequired: true,
  },
  {
    name: 'Phone number',
    value: 'phone',
  },
  {
    name: 'Description of problem',
    value: 'description',
    isRequired: true,
  },
  {
    type: 'radio',
    value: 'type',
    options: [{ name: 'Proposal or question', value: 'proposal' }, { name: 'Experiencing technical difficulties', value: 'tech' }],
  },
  {
    name: 'With',
    type: 'select',
    value: 'tech-type',
    isRequired: true,
    isVisible: (values) => values.type === 'tech',
    options: [
      { name: '', value: '' },
      {
        name: 'PW Website',
        value: 'PW Website',
      },
      {
        name: 'PW CLC mobile application (iOS)',
        value: 'PW CLC mobile application (iOS)',
      },
      {
        name: 'PW CLC mobile application (Android)',
        value: 'PW CLC mobile application (Android)',
      },
    ],
  },
  {
    name: 'Device and model used to enter data',
    value: 'device-type',
    isRequired: true,
    isVisible: (values) => ['PW CLC mobile application (iOS)', 'PW CLC mobile application (Android)'].includes(values['tech-type']),
  },
  {
    name: 'Device operating system and version',
    value: 'os-type',
    isRequired: true,
    isVisible: (values) => ['PW CLC mobile application (iOS)', 'PW CLC mobile application (Android)'].includes(values['tech-type']),
  },
];

const getInfo = () => {
  const parser = new UAParser();

  return parser.getBrowser();
};

export default function ContactForm({ onRedirect }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      type: 'proposal',
    },
  });

  const onSubmit = async (data) => {
    try {
      if (data['tech-type'] === 'PW Website') {
        const { name, version } = getInfo();
        data['browser-type'] = name;
        data['browser-version'] = version;
      }

      const resp = await api('/email/request', {
        body: data,
        method: 'POST',
        withToken: false,
      });

      if (resp.ok && resp.status === 200) {
        notifySuccess('Success. The message is sent');
      } else {
        notifyError('Something wrong, try again!');
      }
    } catch (error) {
      notifyError('Something wrong, try again!');
    } finally {
      onRedirect();
    }
  };

  const allFields = watch();

  return (
    <div id="contact-form-wrapper">
      <form name="form" onSubmit={handleSubmit(onSubmit)}>
        {mapping.map(({ name, value, type, options, isVisible, isRequired }) => {
          const isReq = isRequired === true;

          if (typeof isVisible === 'function' && !isVisible(allFields)) {
            if (allFields[value]) {
              setValue(value, undefined);
            }
            return <React.Fragment key={value}></React.Fragment>;
          }

          if (type === 'radio') {
            return (
              <div key={value} className={cn(`form-group`, { 'has-error': errors[value], required: isReq })}>
                {options.map((opt) => (
                  <label className="radio-cmp" key={opt.value}>
                    <span>{opt.name}</span>
                    <input type="radio" {...register(value)} value={opt.value} />
                  </label>
                ))}
              </div>
            );
          }

          if (type === 'select') {
            return (
              <div key={value} className={cn(`form-group select`, { 'has-error': errors[value], required: isReq })}>
                <label>
                  {!errors[value] ? <div className="help-block">{name}</div> : <div className="help-block">{errors[value].message}</div>}

                  <select {...register(value)}>
                    {options.map((opt) => (
                      <option key={`${opt.value}_${opt.name}`} value={opt.value}>
                        {opt.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
            );
          }

          return (
            <div key={value} className={cn(`form-group`, { 'has-error': errors[value], required: isReq })}>
              <label>
                {!errors[value] ? <span className="name">{name}</span> : <div className="help-block">{errors[value].message}</div>}
                <input {...register(value)} type="text" className="form-control" />
              </label>
            </div>
          );
        })}

        <div className="form-group align-bottom" id="login-button">
          <button type="submit" className="btn btn-primary">
            Send
          </button>
        </div>

        <div className="form-group mb-5">* - mandatory fields (please fill)</div>

        <div className="form-group">
          <span>
            <u>Before submitting the form, please:</u>
          </span>
          <ul>
            <li>Verify that your popup blocker is turned OFF when running reports.</li>
            <li>
              If you are using the web browser on mobile device, clear the cookies/history/cache from time to time. Please note that you
              should do at least one observation survey with an internet connection prior to going offline. It allows the application to
              load from the server all data required for next surveys.
            </li>
          </ul>
          <span>
            <u>After submitting the form</u>, you will receive an e-mail that confirms that you have submitted the form successfully.
          </span>
        </div>
      </form>
    </div>
  );
}
