import { createSelector } from 'reselect';

const selectNewReportTemplates = (state) => state.get('newReportTemplates');

export const getNewReportTemplates = () =>
  createSelector(
    selectNewReportTemplates,
    (state) => state.get('newReportTemplates')
  );

export const isLoading = () =>
  createSelector(
    selectNewReportTemplates,
    (state) => state.get('loading')
  );
