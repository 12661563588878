import styled from 'styled-components';

export const ActionWrapper = styled.div`
  width: 240px;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FieldsWrapper = styled.div`
  width: 525px;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
