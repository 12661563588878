import React from 'react';

import { CheckboxWrapper, CheckboxContainer, CheckboxMark, CheckboxMarkDisabled, CheckboxTitle, CheckboxTitleDisabled } from './styles';

export default function Checkbox(props) {
  const { title, checked, onChange, disabled } = props;


    if (disabled)
      return (
        <CheckboxWrapper>
          <CheckboxContainer>{checked && <CheckboxMarkDisabled />}</CheckboxContainer>
            {title && <CheckboxTitleDisabled dangerouslySetInnerHTML={{ __html: title }} />}
          </CheckboxWrapper>)

    return (
      <CheckboxWrapper onClick={onChange}>
        <CheckboxContainer>{checked && <CheckboxMark />}</CheckboxContainer>
        {title && <CheckboxTitle dangerouslySetInnerHTML={{ __html: title }} />}
      </CheckboxWrapper>);
}
