import styled from 'styled-components';
// import colors from 'styles/colors';

export const Wrapper = styled.div`
  padding: 50px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 15px;
    background: white;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const Title = styled.div`
  color: #000000;
  flex: 1;
  object-fit: contain;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 36px;
`;
