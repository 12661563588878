import React from 'react';
import { Field } from 'redux-form/immutable';

import ActionButton from 'components/ActionButton';
import Tip from 'components/Tip';
import { BigFormInput } from 'containers/admin/OrganizationPage/components';

import { ActionWrapper, FieldsWrapper } from './styles';

export default function (props) {
  const { submit, updateLoading, hasError } = props;

  return (
    <>
      <FieldsWrapper>
        <Tip val="Wrong Password" isValid={!hasError}>
          <Field name="password" component={BigFormInput} type="password" label="Password" />
        </Tip>
        <Field name="newPassword" component={BigFormInput} type="password" label="New Password" />
      </FieldsWrapper>
      <ActionWrapper>
        <ActionButton title="Update" big type="alert" onClick={submit} disabled={updateLoading} />
      </ActionWrapper>
    </>
  );
}
