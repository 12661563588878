import styled from 'styled-components';

export const FieldsWrapper = styled.div`
  width: 525px;
  margin: 0 auto;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
  width: 490px;
`;

export const Label = styled.div`
  text-align: right;
  margin-right: 25px;
  width: 150px;
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ActionWrapperCenter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;
