import React from 'react';
import createIconComponent from '../../utils/createIconComponent';

const DateInfoIcon = createIconComponent({
  content: (
    <g fill="none" fillRule="evenodd">
      <circle cx="13" cy="13" r="13" fill="#D3D3D3" />
      <g stroke="#FFF" transform="translate(7.73 7.027)">
        <rect width="8.541" height="7.838" x="1" y="2.405" strokeWidth="2" rx="2" />
        <path strokeWidth="2" d="M2.81 0v2.46M7.73 0v2.46" />
        <path d="M.703 4.568h9.135" />
      </g>
    </g>
  ),
  height: 26,
  width: 26,
});

DateInfoIcon.displayName = 'DateInfoIcon';

export default DateInfoIcon;
