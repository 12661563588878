import React from 'react';
import styled from 'styled-components';

import Select, { ControlledSelect } from './component';

export default Select;

export {
  ControlledSelect,
}

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;  
  font-weight: bold;
  width: 50%;
  min-width: 300px;
  margin-bottom: 30px;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const Label = styled.div`
  width: ${({ width }) => width || 'initial'};
  text-align: ${({ align }) => align || 'right'};
  
  @media (max-width: 768px) {
    font-size: 21px;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
  }
`;

export function SelectNamed({ label, labelWidth, labelAlign, showError = false, ...props }) {
  return (
    <SelectWrapper className="named-select-wrapper">
      <Label width={labelWidth} align={labelAlign}>{label}</Label>
      <Select {...props} valid={!showError} className="select" />
    </SelectWrapper >
  )
}
