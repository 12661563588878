import { fromJS } from 'immutable';
import { LOAD_TEMPLATES, LOAD_TEMPLATES_SUCCESS, LOAD_TEMPLATES_ERROR } from './constants';

const initialState = fromJS({
  templates: [
    {
      id: 1,
      title: 'Simple Report',
      description: 'One Time Window - One Data Set',
      provides: 'Provides Baseline Information',
      addTimeWindow: 1,
      addDataSet: 1,
      uses: ['Quick Check', 'Establish a Baseline'],
      commonDataSet: ['District', 'Campus', 'Teacher Group', 'Classroom'],
    },
    {
      id: 2,
      title: 'Data Comparation Report',
      description: 'One Time Window - Multiple Data Sets',
      provides: 'Provides a Comparison at a Given Date',
      addTimeWindow: 1,
      addDataSet: 2,
      uses: ['Identify Trends', 'Identify Strengths', 'Identify Challenges'],
      commonDataSet: ['Campuses', 'Observers', 'Teacher Groups'],
    },
    {
      id: 3,
      title: 'Time Comparation Report',
      description: 'Multiple Time Windows - One Data Set',
      provides: 'Shows Movement Over Time',
      addTimeWindow: 2,
      addDataSet: 1,
      uses: ['Identify Trends', 'Support', 'Implementation', 'Motivation', 'Coaching'],
      commonDataSet: ['Identify Trends', 'Support', 'Implementation', 'Motivation', 'Coaching'],
    },
  ],
  templatesLoading: false,
});

const newReportTemplatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TEMPLATES:
      return state.set('templatesLoading', true);
    case LOAD_TEMPLATES_SUCCESS:
      return state.set('templatesLoading', false).set('templates', action.data);
    case LOAD_TEMPLATES_ERROR:
      return state.set('templatesLoading', false);

    default:
      return state;
  }
};

export const initStateForNewReports = initialState;
export default newReportTemplatesReducer;
