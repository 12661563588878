import React from 'react';
import { CloseIcon } from '../Icons';
import { Background, Content, Header, IconWrapper, StyledModal, Title, Wrapper } from './styles';

export default function Modal({ children, title, onClose: closeModal, show }) {
  return show ? (
    <Wrapper>
      <Background onClick={closeModal} />
      <StyledModal>
        <Header>
          <Title>{title}</Title>
          <IconWrapper onClick={closeModal}>
            <CloseIcon height="25px" width="25px" />
          </IconWrapper>
        </Header>
        <Content>{children}</Content>
      </StyledModal>
    </Wrapper>
  ) : null;
}
