/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import _ from "lodash";
import styled from "styled-components";
import { RadioWrapper, RadioContainer, RadioMark, RadioTitle } from './styles';

const SubcategoryHeader = styled.div`
font-size: 20px;
font-weight: bold;
`

export function Radio(props) {
  const { title, checked, onSelect, disabled } = props;

  return (
    <RadioWrapper disabled={disabled} onClick={!disabled ? onSelect : () => { }}>
      <RadioContainer disabled={disabled}>{checked && <RadioMark />}</RadioContainer>
      {title && <RadioTitle>{title}</RadioTitle>}
    </RadioWrapper>
  );
}

export default function RadioGroup({ options = [], onChange = () => { }, titleExtractor = val => val, defaultValue, disabled }) {
  const [selection, setSelection] = useState(defaultValue);

  useEffect(() => {
    if (!selection && defaultValue) {
      setSelection(defaultValue);
    }

    options.forEach(({ option: [selected] = [] }, key) => {
      if (selected && selected.value && selected.value === 'true') {
        setSelection(options[key]);
      }
    });
  }, [options]);

  useEffect(() => {
    if (!selection && defaultValue) {
      setSelection(defaultValue);
    }
  }, [defaultValue])

  const onSelectHandler = (val) => () => {
    if (disabled !== true) {
      setSelection(val);
      onChange(val);
    }
  }

  const groupedOptions = _.groupBy(options, 'subcategory');

  return (
    Object.keys(groupedOptions).map(categoryName => {
      return [
        categoryName !== "undefined" && categoryName !== "null" && <SubcategoryHeader>{categoryName}</SubcategoryHeader>,
        groupedOptions[categoryName].map(val =>
            <Radio
                key={val.id || val}
                disabled={val.disabled || disabled}
                title={titleExtractor(val)}
                checked={(selection && val && selection.id && selection.id === val.id) || selection === val}
                onSelect={onSelectHandler(val)}
            />
        )
      ]
    })
  )
}
