export const FORM_ID = 'myProfile';

export const REDUCER_KEY = 'my_user';

export const SALUTATION_OPTS = [
  {
    label: 'Mr.',
    value: 'Mr.',
  },
  {
    label: 'Mrs.',
    value: 'Mrs.',
  },
  {
    label: 'Ms.',
    value: 'Ms.',
  },
  {
    label: 'Dr.',
    value: 'Dr.',
  },
];
