import { fromJS } from 'immutable';

import { LOAD_STATES, LOAD_STATES_SUCCESS, LOAD_STATES_ERROR } from './constants';

// The initial state of the UserInfo
const initialState = fromJS({
  states: [],
  statesLoading: false,
});

function userInfoReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_STATES:
      return state.set('statesLoading', true);
    case LOAD_STATES_SUCCESS:
      return state.set('statesLoading', false).set('states', action.payload);
    case LOAD_STATES_ERROR:
      return state.set('statesLoading', false);
    default:
      return state;
  }
}

export default userInfoReducer;
