import React from "react";
import Checkbox from "components/Checkbox3";

export default function CheckboxGroup({ options = [], onChange = () => { }, disabled, title, modules = [], index = 0 }) {
    const isNoObservable = ({ name }) => name.toLowerCase().includes('no observable instructional');
    const itemsToCheckDisablingCondition = ['LESSON_FRAMING_TODAYS_OBJECTIVE_POSTED', 'LESSON_FRAMING_TODAYS_CLOSE_POSTED'];
    const itemToConditionallyDisable = 'LESSON_FRAMING_CLASS_ACTIVITY_ALIGNS_WITH_LESSON_FRAME';

    return (
        <>
            {
                options.map((opt, i) => {
                    let conditionallyDisabled = disabled;
                    const newModules = [...modules];

                    // Because according to the business logic we need to disable item if any of other two items not selected
                    if (!disabled && opt.descriptorId === itemToConditionallyDisable) {
                      const currentModule = newModules.filter(item => item.name === title);
                      if (currentModule && currentModule.length > 0)
                      {
                          conditionallyDisabled = !(currentModule[0].options.filter(item => itemsToCheckDisablingCondition.includes(item.descriptorId) && item.value === true).length > 0);
                      }
                    }

                    return <Checkbox
                        key={opt.id}
                        title={opt.name}
                        disabled={conditionallyDisabled}
                        checked={opt.value}
                        onChange={() => {
                            if (!disabled) {
                              const selected = !opt.value;

                                const instructionalTitles = ['Instructional Rigor', 'Instructional Relevance'];

                                if (instructionalTitles.includes(title)) {
                                    if (isNoObservable(opt)) {
                                        const findModules = newModules.filter(item => instructionalTitles.includes(item.name));

                                        for (let x = 0; x < findModules.length; x += 1) {
                                            const moduleId = findModules[x].id;
                                            const moduleIndex = newModules.findIndex(({ id }) => id === moduleId);

                                            newModules[moduleIndex].options = newModules[moduleIndex].options.map(option => ({
                                                ...option,
                                                value: isNoObservable(option) ? selected : false,
                                            }));
                                        }
                                    } else {
                                        const noObservableOptionIndex = options.findIndex(isNoObservable);
                                        if (!newModules[index].options[noObservableOptionIndex].value) {
                                            newModules[index].options[i].value = selected;
                                        }
                                    }
                                } else {
                                    newModules[index].options[i].value = selected;

                                    // To unset flag set for conditionally relate checkbox if all dependent checkbox were unselected
                                    const shouldOptionBeDisabled = !(newModules[index].options.filter(item => itemsToCheckDisablingCondition.includes(item.descriptorId) && item.value === true ).length > 0);
                                    if (shouldOptionBeDisabled && newModules[index]) {
                                        const enabledOption = newModules[index].options.filter(item => (item.descriptorId === itemToConditionallyDisable) && item.value === true)
                                      if (enabledOption && enabledOption.length > 0) {
                                        enabledOption[0].value = false;
                                      }
                                    }
                                }

                                onChange(newModules);
                            }
                        }}
                    />
                })
            }
            <i />
            <i />
            <i />
        </>
    )
}
