import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { REDUCER_KEY } from './state/constants';
import { resetPasswordAction } from './state/actions';
import { makeSelectResetLoading, makeSelectError } from './state/selectors';
import reducer from './state/reducer';
import saga from './state/saga';
import Page from './Page';

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (username) => {
    dispatch(resetPasswordAction({ username }));
  },
});

const mapStateToProps = createStructuredSelector({
  resetLoading: makeSelectResetLoading(),
  hasError: makeSelectError(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: REDUCER_KEY, reducer });
const withSaga = injectSaga({ key: REDUCER_KEY, saga });

export default compose(
  withReducer,
  withSaga,
  withConnect
)(Page);
export { mapDispatchToProps };
