import { toast } from "react-toastify";

const getFunctionByType = (type) => {
    switch (type) {
        case 'info':
            return toast.info.bind(toast);
        case 'success':
            return toast.success.bind(toast);
        case 'warn':
            return toast.warn.bind(toast);
        case 'error':
            return toast.error.bind(toast);
        default:
            return toast.info.bind(toast);
    }
};

const NOTIFY_OPTIONS = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
};

export const notify = (type, message) => {
    const toastFn = getFunctionByType(type);
    toastFn(message, NOTIFY_OPTIONS)
};

export const notifySuccess = message => toast.success( message, NOTIFY_OPTIONS);
export const notifyError = message => toast.error(message, NOTIFY_OPTIONS);
