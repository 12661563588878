import { createSelector } from 'reselect';
import { REDUCER_KEY } from '../constants';

const selectUserRole = (state) => state.get(REDUCER_KEY);

export const makeStatesSelect = () =>
  createSelector(
    selectUserRole,
    (userRoleState) => userRoleState.get('states')
  );

export const makeDistrictsSelect = () =>
  createSelector(
    selectUserRole,
    (userRoleState) => userRoleState.get('districts')
  );

export const makeCampusesSelect = () =>
  createSelector(
    selectUserRole,
    (userRoleState) => userRoleState.get('campuses')
  );

export const makeRolesSelect = () =>
  createSelector(
    selectUserRole,
    (userRoleState) => userRoleState.get('roles')
  );

export const makeAccessLevelsSelect = () =>
  createSelector(
    selectUserRole,
    (userRoleState) => userRoleState.get('accessLevels')
  );

export { selectUserRole };
