import styled from 'styled-components';
import colors from 'styles/colors';

export const FormInputText = styled.span`
  display: flex;
  flex: 2;

  input {
    flex: 1;
    font-family: 'Open Sans';
    background-color: ${colors.lightgrey};
    border: none;
    border-radius: 5px;
    font-size: 16px;
    line-height: 1.28;
    height: 38px;
    padding: 7px 15px;

    :focus {
      outline: 0;
      box-shadow: none;
    }
  }
`;
