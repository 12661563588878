import { reduxForm, formValueSelector } from 'redux-form/immutable';
import { connect } from 'react-redux';

import Form from './component';

const FORM_ID = 'NewRoomForm';

const mapStateToProps = (state) => ({
  formValues: formValueSelector(FORM_ID)(state, 'name', 'emailList'),
});

const FormComponent = reduxForm({
  form: FORM_ID,
  enableReinitialize: true,
})(Form);


export default connect(
  mapStateToProps
)(FormComponent);
