import { createElement } from 'react';

const styles = {
  display: 'inline-block',
  fill: 'currentcolor',
  height: '1.25em',
  maxWidth: '100%',
  position: 'relative',
  userSelect: 'none',
  textAlignVertical: 'text-bottom',
};

const createIconComponent = ({ content, height, width, parentProps = {} }) => ({ onClick, ...stylesFromProps }) => {
  const props = {
    ...parentProps,
    style: {
      ...styles,
      stroke: stylesFromProps.color,
      ...stylesFromProps,
    },
    viewBox: `0 0 ${width} ${height}`,
    onClick,
  };

  return createElement('svg', props, content);
};

export default createIconComponent;
