import React from 'react';

import { Field } from 'redux-form/immutable';

import PageSection from 'components/PageSection';
import Select from 'components/Select';
import UserRole from 'components/UserRole';
// import Checkbox from 'components/Checkbox';
import UserInfo from 'components/forms/UserInfo';
// import FormInput from 'components/FormInput';

import {
  FormItem,
  FormLabel,
  SelectWrapper,
  // CheckboxWrapper
} from '../../styled-components';

export default function (props) {
  const { handleSubmit, initialValues } = props;

  return (
    <form onSubmit={handleSubmit}>
      <PageSection title="Common Information" orange>
        <UserInfo initialValues={initialValues} isMyProfile />
      </PageSection>
      {initialValues.user && initialValues.user.id && (
        <PageSection title="Change owner">
          <FormItem>
            <FormLabel>Select user that will be new owner:</FormLabel>

            <SelectWrapper>
              <Field name="newOwner" component={Select} type="text" />
            </SelectWrapper>
          </FormItem>
        </PageSection>
      )}
      <PageSection title="Role">
        <UserRole initialValues={initialValues} disabled />
      </PageSection>
    </form>
  );
}
