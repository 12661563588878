import React, { useState, useEffect } from 'react';

import api, { POST } from 'utils/api';
import { getCookie, setCookie, deleteCookie } from 'utils/cookies';

import { PageWrapper } from 'components/PageWrapper';
import styled from 'styled-components';
import { Line } from 'components/Line';
import ActionButton from 'components/ActionButton';
import { PageHeader } from 'components/PageHeader';
import AccessControl from 'components/AccessControl';
import Sidebar from 'components/Sidebar';
import Checkbox from 'components/Checkbox';

import SelectState from './SelectState';
import NewRoomModal from './NewRoomModal';

import Select, { SelectNamed } from '../../components/Select';
import { orderBy } from 'lodash';

const PageFooter = styled.div`
  display: flex;
  justify-content: space-evenly;
  
  & > * {
    margin: 0 50px;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 0 25px;

    & > * {
      margin: 10px 0;
    }
  }
`;

const Page = styled.div`
  display: flex;

  @media (max-width: 768px) {
    background: #092143;
    flex-direction: column-reverse;
    padding: 0 15px;
  }
`;

const SelectWrapperBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    width: 50%;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 300px;
  margin-bottom: 30px;

  & > * {
    margin-right: 5px;
  }
`;

const NoStatesText = styled.div`
  align-items: center;
  font-style: italic;
  font-size: 17px;
  margin: 100px;
`;

function getRandomElement(array) {
  const max = array.length - 2;

  return array[Math.round(Math.random() * max)];
}

export default function ConfigPage({ history, location, roles, userId }) {
  const {
    state = null,
    district,
    campus,
    isRandom,
  } = location.state || {};
  const [surveyTypes, setSurveyTypes] = useState([]);
  const [selectedSurveyType, setSelectedSurveyType] = useState(null);

  const [selectedState, setSelectedState] = useState(state);
  const [districtsList, setDistrictsList] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState(district);
  const [campusList, setCampusList] = useState([]);
  const [selectedCampus, setSelectedCampus] = useState(campus);
  const [roomList, setRoomList] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState();
  const [showNewRoom, setShowNewRoom] = useState(false);
  const [stateList, setStateList] = useState();

  const [isRememberChecked, setIsRememberChecked] = useState(false);
  const [isRememberVisible, setIsRememberVisible] = useState(false);
  const [selectedHours, setSelectedHours] = useState(1);

  const setValue = (cb) => (val) => {
    if (val && val[0] && val[0].value) {
      cb(val[0].value);
    }
  };

  const selectRoomHandler = (val) => {
    if (val && val[0] && val[0].value) {
      const { value } = val[0];
      if (value === 'new') {
        setShowNewRoom(true);
      }

      setSelectedRoom(value);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const response = await api('/data/states');
      const json = await response.json();
      setStateList(json.data);
    }

    fetchData();
    const dataStr = getCookie(userId);

    if (dataStr) {
      const data = JSON.parse(dataStr);

      if (!selectedState) {
        setSelectedState(data.state);
      }
      if (!selectedDistrict) {
        setSelectedDistrict(data.district);
      }
      if (!selectedCampus) {
        setSelectedCampus(data.campus);
      }
    } else {
      const [roleLevel] = roles[0].split('/');
      if (['global', 'district', 'regional'].includes(roleLevel) && !selectedState) {
        setIsRememberVisible(true);
      }
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (isRememberChecked && selectedCampus) {
      setCookie(userId, JSON.stringify({ campus: selectedCampus, state: selectedState, district: selectedDistrict }), selectedHours);
    } else if (isRememberVisible && !isRememberChecked) {
      deleteCookie(userId);
    }
  }, [isRememberChecked, selectedHours, selectedCampus]); // eslint-disable-line

  useEffect(() => {
    if (stateList && stateList.length === 1 && !selectedState) {
      setSelectedState(stateList[0]);
    }
  }, [stateList]); // eslint-disable-line

  useEffect(() => {
    async function fetchData() {
      const response = await api(`/data/districts?stateId=${selectedState.id}`);
      const json = await response.json();
      setDistrictsList(json.data);
    }

    if (selectedState) {
      fetchData();
    }
  }, [selectedState]);

  useEffect(() => {
    if (districtsList.length === 1 && !selectedDistrict) {
      setSelectedDistrict(districtsList[0].id);
    }
  }, [districtsList]); // eslint-disable-line

  useEffect(() => {
    async function fetchData() {
      const response = await api(`/data/campuses?districtId=${selectedDistrict}`);
      const json = await response.json();
      setCampusList(json.data);
    }

    if (selectedDistrict) {
      fetchData();
    }
  }, [selectedDistrict]);

  useEffect(() => {
    if (campusList.length === 1 && !selectedCampus) {
      setSelectedCampus(campusList[0].id);
    }
  }, [campusList]); // eslint-disable-line

  useEffect(() => {
    async function fetchData() {
      const response = await api(`/data/rooms?campusId=${selectedCampus}`);
      const json = await response.json();
      let rooms = json?.data ?? [];

      rooms.push({
        id: 'new',
        name: 'New Room',
      });

      rooms = orderBy(rooms, (room) => {
        if (room.id === 'new') {
          return 0;
        }
        if (room.isPracticeRoom) {
          return 1;
        }

        return 3;
      });

      setRoomList(rooms);
    }

    async function fetchTypeData() {
      const response = await api(`/survey/type/list?campusId=${selectedCampus}&userId=${userId}`);
      const json = await response.json();
      setSurveyTypes(json.data);
    }

    if (selectedCampus) {
      fetchData();
      fetchTypeData()
    } else {
      setRoomList([]);
    }
  }, [selectedCampus, userId]);

  useEffect(() => {
    if (roomList.length && isRandom) {
      // need timeout to prevent react-select-dropdown change state issue
      setTimeout(() => setSelectedRoom(getRandomElement(roomList).id), 500);
    }
  }, [roomList]); // eslint-disable-line

  const onSubmitHandler = async (formValues, f, form) => {
    const values = formValues && formValues.toJS ? formValues.toJS() : {};

    const response = await api(`/rooms/create`, {
      method: POST,
      body: {
        ...values,
        campusId: selectedCampus,
      }
    });

    const json = await response.json();
    roomList.splice(roomList.length - 2, 0, {
      id: json.id,
      name: json.name
    })
    setRoomList(roomList);
    setSelectedRoom(json.id);
    form.reset();
    setShowNewRoom(false);
  }

  const onCloseHandler = () => {
    setSelectedRoom(null);
    setShowNewRoom(false);
  }

  return (
    <Page>
      <PageWrapper style={{ width: '100%' }}>
        {
          showNewRoom && <NewRoomModal onSubmit={onSubmitHandler} onClose={onCloseHandler} />
        }
        <PageHeader>New Survey</PageHeader>
        <Line margin="25px 0" />
        {
          !stateList || stateList.length ? (
            <>
              <SelectState onSelect={setSelectedState} defaultValue={state || selectedState} options={stateList} />

              <Line margin="45px 0" />

              <SelectWrapperBottom>
                <SelectNamed
                  label="District"
                  labelWidth="90px"
                  selectedValue={selectedDistrict}
                  options={districtsList.map((obj) => ({ label: obj.name, value: obj.id }))}
                  onChange={setValue(setSelectedDistrict)}
                />
                <SelectNamed
                  label="Campus"
                  labelWidth="90px"
                  selectedValue={selectedCampus}
                  options={campusList.map((obj) => ({ label: obj.name, value: obj.id }))}
                  onChange={setValue(setSelectedCampus)}
                />
                {isRememberVisible && selectedCampus && <CheckboxWrapper>
                  <Checkbox title="" checked={isRememberChecked} onChange={() => setIsRememberChecked(!isRememberChecked)} />
                  Remember my choice for next
                  <Select
                    selectedValue={1}
                    options={[1, 2, 8].map((value) => ({ label: value, value }))}
                    onChange={setValue(setSelectedHours)}
                  />
                  hours
                </CheckboxWrapper>}
                <SelectNamed
                  label="Room"
                  labelWidth="90px"
                  selectedValue={selectedRoom}
                  options={roomList.map((obj) => ({ label: obj.name, value: obj.id }))}
                  onChange={selectRoomHandler}
                />
                <SelectNamed
                  label="Survey Type"
                  labelWidth="90px"
                  options={surveyTypes.map((obj) => ({ label: obj.name, value: obj.id }))}
                  onChange={setValue(setSelectedSurveyType)}
                />
              </SelectWrapperBottom>
              <PageFooter>
                <ActionButton big title="Cancel" onClick={() => history.push('/home')} />
                <ActionButton
                  big
                  title="Next"
                  type="alert"
                  disabled={!(selectedRoom && selectedSurveyType)}
                  onClick={() => {
                    const campus = campusList.find((e) => e.id === selectedCampus);
                    const data = {
                      stateName: selectedState.name,
                      districtName: districtsList.find((e) => e.id === selectedDistrict).name,
                      campusName: campus.name,
                      campusId: campus.id,
                      roomName: roomList.find((e) => e.id === selectedRoom).name,
                      surveyTypeName: surveyTypes.find((e) => e.id === selectedSurveyType).name,
                    };

                    if (data.surveyTypeName.includes('PLC')) {
                      history.push('/newplcsurvey', { surveyType: selectedSurveyType, room: selectedRoom, ...data });
                    } else {
                      history.push('/newsurvey', { surveyType: selectedSurveyType, room: selectedRoom, ...data });
                    }
                  }}
                />
              </PageFooter>
            </>
          ) : (
              <NoStatesText>
                Sorry, you have no access to any active campus(es).<br />
                Please contact your campus administrator to manage the subscription(s).
              </NoStatesText>
            )
        }

      </PageWrapper>
      <Sidebar padding="50px">
        <AccessControl hasLevel={['basic', 'trainer', 'advanced', 'managing']}>
          <ActionButton
            big
            title="Build Custom Survey"
            style={{ marginBottom: '50px' }}
            onClick={() => {
              history.push('/custom-survey');
            }}
          />
          <ActionButton
            big
            title="Edit Custom Survey"
            onClick={() => {
              history.push('/custom-survey/edit');
            }}
          />
        </AccessControl>
      </Sidebar>
    </Page>
  );
}
