import React from 'react';
import { faPoll, faCalculator, faClipboard, faBox, faSmile, faKey } from '@fortawesome/free-solid-svg-icons';
import { PWCoachIcon } from 'components/Icons';

export const MENU_ITEMS = [
  {
    title: 'Surveys',
    icon: faClipboard,
    children: [
      {
        to: '/surveys',
        title: 'Browse Surveys',
        access: {
          resource: 'survey',
          action: 'view',
        },
      },
      {
        to: '/new-survey-config',
        title: 'New Survey',
        access: {
          resource: 'survey',
          action: 'create',
        },
      },
    ],
  },
  {
    title: 'Counts',
    icon: faCalculator,
    access: {
      hasLevel: ['basic', 'trainer', 'advanced', 'managing'],
    },
    children: [
      {
        to: '/standard-counts',
        title: 'Standard Counts',
      },
      {
        to: '/averages-counts',
        title: 'Averages',
      },
      {
        to: '/top-performers-counts',
        title: 'Top Performers',
      },
    ],
  },
  {
    title: 'Reports',
    icon: faPoll,
    children: [
      {
        to: '/reports',
        title: 'Browse / Build Reports',
      },
      {
        to: '/reports/snapshot',
        title: 'Formative Snapshots',
        access: {
          resource: 'snapshotReport',
          action: 'view',
        },
      },
      {
        to: '/pp-notice',
        title: 'Powerful Practice Notice',
        access: {
          hasLevel: ['basic', 'trainer', 'advanced', 'managing'],
        },
      },
      {
        to: '/pp-badge',
        title: 'Powerful Performer Badges',
        access: {
          hasLevel: ['basic', 'trainer', 'advanced', 'managing'],
        },
      },
      {
        to: '/pins',
        title: 'PINS',
        access: {
          hasLevel: ['managing', 'advanced'],
        },
      },
      {
        to: '/observer-notes',
        title: 'OBSERVER NOTES',
        access: {
          resource: 'notes',
          action: 'view',
        },
      },
    ],
  },
  {
    title: 'Resources',
    icon: faBox,
    children: [
      {
        to: '/survey-templates',
        title: 'Survey Templates',
      },
      {
        to: '/support-materials',
        title: 'Support Materials',
      },
      {
        to: '/research',
        title: 'Research',
      },
      {
        to: '/mobile-applications',
        title: 'Mobile Application',
      },
      {
        to: '/matrix-builder',
        title: 'Matrix Builder',
        access: {
          hasLevel: ['basic', 'trainer', 'advanced', 'managing'],
        },
      },
      {
        to: '/challenge-list',
        title: 'Challenges',
      },
      {
        href: 'https://pw.voliasoftware.com/',
        title: 'PREVIOUS (RED PW) VERSION',
      }
    ]
  },
  {
    title: 'PW Coach',
    customIcon: <PWCoachIcon />,
    resource: {
      hasLevel: ['managing', 'advanced'],
      hasRole: ['campus', 'district', 'global'],
    },
    children: [
      {
        to: '/roster',
        title: 'Build, Edit, Delete Roster',
        access: {
          hasLevel: ['managing'],
          hasRole: ['campus', 'global'],
        },
      },
      {
        to: '/roster-and-schedule',
        title: 'View Roster and Schedule',
      },
    ],
  },
  {
    title: 'My profile',
    icon: faSmile,
    children: [
      {
        to: '/my-profile',
        title: 'My profile',
      },
      {
        to: '/my-profile/password',
        title: 'Change Password',
      },
      {
        action: 'logout',
        title: 'Logout',
      },
    ],
  },
  {
    title: 'Admin',
    icon: faKey,
    children: [
      {
        to: '/admin/migration',
        title: 'Manage Migration',
        access: {
          resource: 'migrate',
          action: 'view',
        },
      },
      {
        to: '/admin/logs',
        title: 'View Logs',
        access: {
          resource: 'stats',
          action: 'logs',
        },
      },
      {
        to: '/admin/organization',
        title: 'Manage Organization',
      },
      {
        to: '/admin/subscriptions',
        title: 'Manage Subscriptions',
        access: {
          resource: 'subscription',
          action: 'view',
        },
      },
      {
        to: '/admin/rooms/import',
        title: 'Import Rooms',
        access: {
          resource: 'room',
          action: 'import',
        },
      },
      {
        to: '/admin/users',
        title: 'Browse Users',
        access: {
          resource: 'user',
          action: 'view',
        },
      },
      {
        to: '/admin/users/import',
        title: 'Import Users',
        access: {
          resource: 'user',
          action: 'import',
        },
      },
      {
        to: '/admin/users/create',
        title: 'Create User',
        access: {
          resource: 'user',
          action: 'create',
        },
      },
      {
        to: '/user-behavior',
        title: 'User Behavior Report',
        access: {
          hasLevel: ['managing'],
        },
      },
      {
        to: '/bubble-message',
        title: 'Manage Bubble Messages',
        access: {
          resource: 'message',
          action: 'create',
        },
      },
      {
        to: '/admin/rooms/merge',
        title: 'Merge Rooms',
        access: {
          resource: 'room',
          action: 'merge',
        },
      },
      {
        to: '/admin/campuses/merge',
        title: 'Merge Campuses',
        access: {
          resource: 'campus',
          action: 'merge',
        },
      },
      {
        to: '/rewards',
        title: 'Rewards',
        access: {
          resource: 'reward',
          action: 'edit',
        },
      },
    ],
  },
];
