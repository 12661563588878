import React from 'react';
import createIconComponent from '../../utils/createIconComponent';

const ArrowUpIcon = createIconComponent({
  content: <path fill="none" fillRule="evenodd" strokeWidth="2" d="M1 1l6 6 6-6" />,
  height: 9,
  width: 14,
  parentProps: {
    stroke: '#4A4A4A',
  },
});

ArrowUpIcon.displayName = 'ArrowUpIcon';

export default ArrowUpIcon;
