/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import DateTime from 'react-datetime';
import { Helmet } from 'react-helmet';
import moment from 'moment-timezone';
import html2canvas from 'html2canvas';
import JSPDF from 'jspdf';

import 'react-datetime/css/react-datetime.css';

import api, { POST } from 'utils/api';
import { notifyError } from 'utils/notification';
import { SelectNamed } from 'components/Select';
import { Line } from 'components/Line';
import ActionButton from 'components/ActionButton';
import Sidebar from 'components/Sidebar';
import InfoIcon from '../../components/Icons/InfoIcon';
import SurveyOptions from './components/SurveyOptions';
import PrintSurvey from './components/PrintSurvey';
import ConfirmationModal from './components/ConfirmationModal';
import SendNotesModal from './components/SendNotesModal';

import { REQUIRED_MODULES } from './constants';
import { tooltips } from './config/tooltips';
import './style.scss';
import TooltipModal from './components/TooltipModal';

const timezonesMapping = {
  'Hawaiian Standard Time': 'Pacific/Honolulu',
  'Alaskan Standard Time': 'America/Anchorage',
  'Pacific Standard Time': 'America/Los_Angeles',
  'Mountain Standard Time': 'America/Phoenix',
  'Central Standard Time': 'America/Chicago',
  'Eastern Standard Time': 'America/New_York',
  'Atlantic Standard Time': 'Atlantic/Bermuda',
};

export default function NewSurveyPage({ location, history, match, isNew, isView, isEdit, timezone }) {
  const title = isView ? 'View' : isEdit ? 'Edit' : 'New';
  const modal = useRef(null);
  const sendNotesModal = useRef(null);

  const [createDate, setCreateDate] = useState(() => new Date());
  const [isCreateDateValid, setIsCreateDateValid] = useState(true);
  const [infoSurvey, setInfoSurvey] = useState({});

  const [showModal, setShowModal] = useState(false);

  const [tooltipPos, setTooltipPos] = useState(0);
  const [tooltipText, setTooltipText] = useState({ title: '', body: '' });

  const [invalidModules, setInvalidModules] = useState(false);

  const [modules, setModules] = useState([]);

  const [subjects, setSubjects] = useState([]);
  const [grades, setGrades] = useState([]);

  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);

  const [originNotesData, setOriginNotesData] = useState('');
  const [notesData, setNotesData] = useState('');
  const [roomList, setRoomList] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [surveyType, setSurveyType] = useState(null);

  const [campusId, setCampusId] = useState(null);

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showError, setShowError] = useState(false);

  const [hasPositiveComments, setHasPositiveComments] = useState(true);

  const getOptionValue = (option) => {
    if (!option.option || !option.option[0]) {
      return option.type === 'number' ? '' : null;
    }
    const { value } = option.option[0] || {};

    switch (option.type) {
      case 'boolean':
        return value === 'true';
      default:
        return value;
    }
  };
  const setModulesFromOriginal = (newModules) => {
    setModules(
      newModules.map((module) => ({
        ...module,
        options: module.options.map((option) => ({
          ...option,
          value: getOptionValue(option),
        })),
        inUse: module.hasOwnProperty('inUse') ? module.inUse : true,
      }))
    );
  };

  useEffect(() => {
    async function fetchData(id) {
      setLoading(true);
      const response = await api(`/campus/${id}`);
      const respJson = await response.json();
      setLoading(false);
      setHasPositiveComments(respJson.hasPositiveComments);
    }
    if (campusId) {
      fetchData(campusId);
    }
  }, [campusId]);

  useEffect(() => {
    const newInvalidModules = modules.filter(({ type, options, name }) => {
      if (!REQUIRED_MODULES.includes(name)) {
        return false;
      }

      switch (type) {
        case 'number':
          return !options.every(({ value }) => value);
        case 'multi':
        case 'radio':
          return !options.find(({ value }) => value);
        default:
          return true;
      }
    });

    setInvalidModules(newInvalidModules);
  }, [modules]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response = await api(`/survey/${match.params.id}`);
      const respJson = await response.json();
      setLoading(false);

      if (response.status === 200) {
        const { room, type, version } = respJson;
        setInfoSurvey({
          surveyTypeName: type.name,
          stateName: room.campus.district.state.name,
          districtName: room.campus.district.name,
          campusName: room.campus.name,
          roomName: room.name,
          version,
        });

        setCreateDate(moment.utc(respJson.dateCreation).toDate());
        setCampusId(room.campus.id);

        setModulesFromOriginal(type.modules);
        setOriginNotesData(respJson.notes);
        setNotesData(respJson.notes);
        setSelectedSubject(respJson.subject.id);
        setSelectedGrade(respJson.grade.id);
        setSurveyType(type.id);
        setSelectedRoom(room.id);
      } else {
        notifyError(respJson.message || 'System error, please try again!');
      }
    }
    if (match && match.params && match.params.id) {
      fetchData();
    }
  }, [match && match.params && match.params.id]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response = await api(`/survey/custom/${location.state.surveyType}/for/campus/${location.state.campusId}`);
      const json = await response.json();
      setLoading(false);
      setModulesFromOriginal(json.data.modules);
    }

    if (location?.state?.surveyType) {
      fetchData();
      setInfoSurvey(location.state);
      setSelectedRoom(location.state.room);
      setSurveyType(location.state.surveyType);
    }
  }, [location?.state?.surveyType]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response = await api(`/data/subjects`);
      const json = await response.json();
      setLoading(false);
      setSubjects((json.data || []).map((e) => ({ value: e.id, label: e.name })));
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response = await api(`/data/grades?campusId=${campusId}`);
      const json = await response.json();
      setLoading(false);
      setGrades((json.data || []).map((e) => ({ value: e.id, label: e.name })));
    }

    if (campusId) {
      fetchData();
    }
  }, [campusId]);

  useEffect(() => {
    if (location?.state?.campusId) {
      setCampusId(location.state.campusId);
    }
  }, [location?.state?.campusId]);

  useEffect(() => {
    async function fetchData() {
      const response = await api(`/data/rooms?campusId=${campusId}`);
      const json = await response.json();

      setRoomList(json.data);
    }

    if (campusId) {
      fetchData();
    }
  }, [campusId]);

  if (isNew && !location?.state?.surveyType) {
    history.push('/home');
    return null;
  }

  const printSurvey = () => {
    const printArea = document.getElementById('printWrapper');
    html2canvas(printArea, {
      y: 190,
      width: printArea.clientWidth,
    }).then((canvas) => {
      const printWindow = window.open();
      printWindow.document.write(`<img width="100%" src="${canvas.toDataURL()}" />`);
      setTimeout(() => {
        printWindow.print();
      }, 1000);

      printWindow.onafterprint = () => printWindow.close();
    });
  };

  const downloadSurvey = () => {
    const printArea = document.getElementById('printWrapper');

    html2canvas(printArea, {
      y: 190,
    }).then((canvas) => {
      const dataURL = canvas.toDataURL();
      const pdf = new JSPDF('p', 'mm', 'a4');

      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (printArea.clientHeight * imgWidth) / printArea.clientWidth;
      let heightLeft = imgHeight;
      let position = 7;

      pdf.addImage(dataURL, 'JPEG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position += heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(dataURL, 'JPEG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
        heightLeft -= pageHeight;
      }

      const date = moment(createDate).format('MM/DD/YYYY hh:mm:ss A');
      pdf.save(`${surveyType} ${infoSurvey.surveyTypeName} ${date}.pdf`);
    });
  };

  const getSurvey = () => {
    const options = modules
      .map((module) => module.options.filter(({ value }) => value).map((option) => ({ surveyOptionId: option.id, value: option.value })))
      .flat(2);

    const modulesInUse = modules.map((module) => ({
      moduleId: module.id,
      value: module.inUse,
    }));

    return {
      name: '',
      notes: notesData,
      roomId: selectedRoom,
      gradeId: selectedGrade,
      subjectId: selectedSubject,
      surveyTypeId: surveyType,
      options,
      modules: modulesInUse,
      dateCreation: createDate,
    };
  };

  const modalConfirmAction = () => {
    setLoading(true);
    api(`/survey/${match.params.id}`, {
      method: POST,
      body: {
        ...getSurvey(),
        locked: true,
      },
    }).then(() => {
      setLoading(false);

      history.push('/surveys');
    });
  };

  const modalCancelationAction = () => {
    setLoading(true);
    api(`/survey/${match.params.id}`, {
      method: POST,
      body: getSurvey(),
    }).then(() => {
      setLoading(false);

      history.push('/surveys');
    });
  };

  const updateSurvey = async () => {
    const response = await api(`/survey/${match.params.id}`, {
      method: POST,
      body: getSurvey(),
    });

    return response;
  };

  const setTooltip = (name) => {
    if (tooltipText?.title === name) {
      setTooltipText({ title: '', body: '' });
    } else {
      setTooltipText({ title: name, body: tooltips[name] });
    }
  };

  const isFinishBtnDisabled = submitted && !(!invalidModules.length && selectedGrade && selectedSubject && !loading);
  const finishBtnHandler = () => {
    if (loading || submitted) {
      return;
    }

    setShowError(false);
    setSubmitted(true);

    if (invalidModules.length || !selectedSubject || !selectedGrade || !isCreateDateValid) {
      notifyError('Please fill all required fields!');
      setSubmitted(false);
      setShowError(true);
      return;
    }

    if (isNew) {
      setLoading(true);
      api('/survey', {
        method: POST,
        body: getSurvey(),
      }).then(async (response) => {
        setLoading(false);
        const data = await response.json().then((resp) => resp.data);
        const { isFifteenth } = data;
        if (notesData.length > 0 || isFifteenth) {
          history.push('/send-observation-notes', {
            surveyId: data.id,
            roomId: selectedRoom,
            roomName: roomList.find((r) => r.id === selectedRoom)?.name ?? '',
            notices: data.notices,
            hasNotices: data.hasNotices,
            badges: data.badges,
            hasBadges: data.hasBadges,
            isFifteenth: isFifteenth,
            lastFifteenthList: data.lastFifteenthList,
          });
        } else if (data.hasNotices || data.hasBadges) {
          history.push('/survey-notice', {
            notices: data.notices,
            badges: data.badges,
            roomId: selectedRoom,
            surveyId: data.id,
          });
        } else {
          history.push('/surveycongratulation');
        }
      });
    } else if (isEdit) {
      if (notesData.length > 0 && notesData !== originNotesData) {
        sendNotesModal.current.openModal();
      } else {
        modal.current.openModal();
      }
    }
  };

  const isViewedModules = modules.filter((module) => module.inUse);

  return (
    <div className="New-survey-page">
      <Helmet>
        <title>{title} survey</title>
        <meta name="description" content="New survey page" />
      </Helmet>
      <TooltipModal show={showModal} title={tooltipText.title} body={tooltipText.body} onClose={setShowModal} />
      <div className="ns-page">
        <div className="new-survey-wrapper">
          <div id="page-title">{title.toUpperCase()} SURVEY</div>
          <div id="steps-title">{infoSurvey.surveyTypeName}</div>
          <div className="container">
            <ul className="progressbar">
              <li className="actived">HOME</li>
              <li className="actived">SURVEY SETTINGS</li>
              <li className="active">{infoSurvey.surveyTypeName}</li>
              <li className={isView ? 'active' : ''}>FINISH</li>
            </ul>
          </div>
          <div id="block-wrapper">
            <div id="block-title">
              <span className="block-title--text">Common Info</span>
              <span>
                <InfoIcon
                  onClick={(e) => {
                    if (window.innerWidth < 768) {
                      setShowModal(true);
                    } else {
                      const { top } = e.currentTarget.getBoundingClientRect();
                      setTooltipPos(window.scrollY + top);
                    }

                    setTooltip('Common Info');
                  }}
                  width={33}
                  height={33}
                  float="right"
                />
              </span>
            </div>
            <Line margin="25px 0" />
            <div id="inner-block-line">
              <div id="inner-block">
                <div className="inner-item">Survey Definition:</div>
                <div className="inner-item">Version:</div>
                <div className="inner-item">State:</div>
                <div className="inner-item">District:</div>
                <div className="inner-item">Campus:</div>
                <div className="inner-item">Room:</div>
              </div>
              <div id="inner-block">
                <div className="inner-item">{infoSurvey.surveyTypeName}</div>
                <div className="inner-item">{infoSurvey.version || 1}</div>
                <div className="inner-item">{infoSurvey.stateName}</div>
                <div className="inner-item">{infoSurvey.districtName}</div>
                <div className="inner-item">{infoSurvey.campusName}</div>
                {!isNew && <div className="inner-item">{infoSurvey.roomName}</div>}
                {isNew && (
                  <div className="inner-item room-select">
                    <SelectNamed
                      label=""
                      selectedValue={selectedRoom}
                      options={roomList.map((obj) => ({ label: obj.name, value: obj.id }))}
                      onChange={(val) => {
                        if (val && val[0] && val[0].value) {
                          setSelectedRoom(val[0].value);
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <Line margin="25px 0" />
            <div>
              <SelectNamed
                label="Subject"
                options={subjects}
                disabled={isView}
                values={subjects.length > 0 && selectedSubject ? [subjects.find((e) => e.value === selectedSubject)] : undefined}
                onChange={(e) => {
                  if (e && e[0] && e[0].value) {
                    setSelectedSubject(e[0].value);
                  }
                }}
                showError={showError && !selectedSubject}
              />
              <SelectNamed
                label="Grade"
                options={grades}
                disabled={isView}
                values={grades.length > 0 && selectedGrade ? [grades.find((e) => e.value === selectedGrade)] : undefined}
                onChange={(e) => {
                  if (e && e[0] && e[0].value) {
                    setSelectedGrade(e[0].value);
                  }
                }}
                showError={showError && !selectedGrade}
              />
              <label>
                Date Observed:
                <DateTime
                  className={isCreateDateValid ? '' : 'date-time--error'}
                  value={createDate}
                  inputProps={{
                    disabled: isView,
                  }}
                  displayTimeZone={timezonesMapping[timezone]}
                  onChange={(date) => {
                    if (moment.isMoment(date)) {
                      setCreateDate(date.toDate());
                      setIsCreateDateValid(true);
                    } else {
                      setIsCreateDateValid(false);
                    }
                  }}
                />
              </label>
            </div>
          </div>

          <div id="block-wrapper">
            <SurveyOptions
              isNew={isNew}
              disabled={isView}
              modules={isView ? isViewedModules : modules}
              submitted={submitted}
              invalidModules={invalidModules}
              onTooltipClick={(e, name) => {
                if (window.innerWidth < 768) {
                  setShowModal(true);
                } else {
                  const { top } = e.currentTarget.getBoundingClientRect();
                  setTooltipPos(window.scrollY + top);
                }

                setTooltip(name);
              }}
              onModuleNotInUseClick={({ id }, val) => {
                setModules((modules) => modules.map((module) => (module.id === id ? { ...module, inUse: !val } : module)));
              }}
              onChange={setModules}
            />
          </div>

          {hasPositiveComments && (
            <div id="block-wrapper">
              <div id="block-title">
                <span className="block-title--text">Positive Comments</span>
                <span>
                  <InfoIcon
                    onClick={(e) => {
                      if (window.innerWidth < 768) {
                        setShowModal(true);
                      } else {
                        const { top } = e.currentTarget.getBoundingClientRect();
                        setTooltipPos(window.scrollY + top);
                      }
                      setTooltip('Positive Comments');
                    }}
                    width={33}
                    height={33}
                    float="right"
                  />
                </span>
              </div>
              <Line margin="15px 0" />
              <textarea
                id="bottom-textarea"
                disabled={isView}
                onChange={({ target }) => setNotesData(target.value)}
                value={notesData}
              ></textarea>
            </div>
          )}
          <div id="block-wrapper-buttons">
            {isView ? (
              <>
                <ActionButton
                  big
                  title="Close"
                  onClick={() => {
                    try {
                      window.close();
                    } catch (e) {
                      window.history.back();
                    }
                  }}
                />
                <ActionButton type="alert" big title="Print" onClick={printSurvey} />
                <ActionButton type="alert" big title="Save to PDF" onClick={downloadSurvey} />
              </>
            ) : (
              <>
                <ActionButton
                  big
                  title="Close"
                  onClick={() => {
                    try {
                      window.close();
                    } catch (e) {
                      window.history.back();
                    }
                  }}
                />
                <ActionButton big title="Cancel" onClick={() => history.push('/home')} />
                <ActionButton big type="alert" title="Finish" disabled={isFinishBtnDisabled} onClick={finishBtnHandler} />
              </>
            )}
            {isEdit && <ConfirmationModal confirmHandler={modalConfirmAction} cancelHandler={modalCancelationAction} ref={modal} />}
            {isEdit && (
              <SendNotesModal
                ref={sendNotesModal}
                surveyId={match.params.id}
                roomId={selectedRoom}
                updateSurvey={updateSurvey}
                closeHandler={() => modal.current.openModal()}
              />
            )}
          </div>
        </div>
        <PrintSurvey
          survey={infoSurvey}
          subjects={subjects}
          selectedSubject={selectedSubject}
          grades={grades}
          selectedGrade={selectedGrade}
          createDate={createDate}
          modules={modules}
          notesData={notesData}
          timezone={timezone}
          hasPositiveComments={hasPositiveComments}
        />
        <Sidebar className="new-survey-tooltip-block">
          {tooltipPos > 0 && (
            <div style={{ position: 'absolute', top: tooltipPos - 190 }}>
              <div className="tooltip-title" dangerouslySetInnerHTML={{ __html: tooltipText.title }} />
              <div className="tooltip-text" dangerouslySetInnerHTML={{ __html: tooltipText.body }} />
            </div>
          )}
        </Sidebar>
      </div>
    </div>
  );
}
