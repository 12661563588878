import React, { Fragment } from 'react';

import { PageSectionWrapper, Title, OrangeTitle, Line } from './styles';

const PageSection = (props) => {
  const { title, children, orange } = props;

  return (
    <PageSectionWrapper>
      {orange ? (
        <Fragment>
          <Line />
          <OrangeTitle>{title}</OrangeTitle>
        </Fragment>
      ) : (
        <Fragment>
          <Title>{title}</Title>
          <Line />
        </Fragment>
      )}

      {children}
    </PageSectionWrapper>
  );
};

export default PageSection;
