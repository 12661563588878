import React from 'react';
import DateTime from 'react-datetime';

import { SelectNamed } from 'components/Select';
import { Line } from 'components/Line';
import InfoIcon from 'components/Icons/InfoIcon';
import SurveyOptions from './SurveyOptions';

const timezonesMapping = {
  'Hawaiian Standard Time': 'Pacific/Honolulu',
  'Alaskan Standard Time': 'America/Anchorage',
  'Pacific Standard Time': 'America/Los_Angeles',
  'Mountain Standard Time': 'America/Phoenix',
  'Central Standard Time': 'America/Chicago',
  'Eastern Standard Time': 'America/New_York',
  'Atlantic Standard Time': 'Atlantic/Bermuda',
};

export default function PrintSurvey({
  survey,
  subjects,
  selectedSubject,
  grades,
  selectedGrade,
  createDate,
  modules,
  timezone,
  notesData,
  hasPositiveComments,
}) {
  return (
    <div className="new-survey-wrapper" id="printWrapper">
      <div id="page-title">VIEW SURVEY</div>
      <div id="steps-title">{survey.surveyTypeName}</div>
      <div className="container">
        <ul className="progressbar">
          <li className="actived">HOME</li>
          <li className="actived">SURVEY SETTINGS</li>
          <li className="active">{survey.surveyTypeName}</li>
          <li className="active">FINISH</li>
        </ul>
      </div>
      <div id="block-wrapper">
        <div id="block-title">
          Common Info
          <span>
            <InfoIcon width={33} height={33} float="right" />
          </span>
        </div>
        <Line margin="25px 0" />
        <div id="inner-block-line">
          <div id="inner-block">
            <div className="inner-item">Survey Definition:</div>
            <div className="inner-item">Version:</div>
            <div className="inner-item">State:</div>
            <div className="inner-item">District:</div>
            <div className="inner-item">Campus:</div>
            <div className="inner-item">Room:</div>
          </div>
          <div id="inner-block">
            <div className="inner-item">{survey.surveyTypeName}</div>
            <div className="inner-item">{survey.version || 1}</div>
            <div className="inner-item">{survey.stateName}</div>
            <div className="inner-item">{survey.districtName}</div>
            <div className="inner-item">{survey.campusName}</div>
            <div className="inner-item">{survey.roomName}</div>
          </div>
        </div>
        <Line margin="25px 0" />
        <div>
          <SelectNamed
            label="Subject"
            options={subjects}
            disabled
            values={
              subjects.length > 0 && selectedSubject
                ? [subjects.find((e) => e.value === selectedSubject)]
                : undefined
            }
          />
          <SelectNamed
            label="Grade"
            options={grades}
            disabled
            values={
              grades.length > 0 && selectedGrade
                ? [grades.find((e) => e.value === selectedGrade)]
                : undefined
            }
          />
          <label>
            Date Observed:
            <DateTime
              value={createDate}
              inputProps={{
                disabled: true,
              }}
              displayTimeZone={timezonesMapping[timezone]}
            />
          </label>
        </div>
      </div>

      <div id="block-wrapper">
        <SurveyOptions disabled modules={modules} />
      </div>

      {hasPositiveComments && (
        <div id="block-wrapper">
          <div id="block-title">
            Positive Comments
            <span>
              <InfoIcon width={33} height={33} float="right" />
            </span>
          </div>
          <Line margin="15px 0" />
          <textarea id="bottom-textarea" disabled value={notesData}></textarea>
        </div>
      )}
    </div>
  );
}
