import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';

import reducer from './reducer';
import saga from './saga';
import { loadSurveyTemplatesData,
         loadChosenSurveyTemplate } from './actions';
import { getNewSurveyTemplates,
         isLoading,
         getChosenSurveyTemplate } from './selectors';

import NewSurveyPage from './NewSurveyPage';
import { makeSelectTimezone } from "../App/selectors";

const mapDispatchToProps = (dispatch) => ({
  onSubmitForm: () => {},

  loadSurveyTemplatesData: () => {
    dispatch(loadSurveyTemplatesData());
  },

  loadChosenSurveyTemplate: (id) => {
    dispatch(loadChosenSurveyTemplate(id));
  }
});

const mapStateToProps = createStructuredSelector({
  isLoading: isLoading(),
  templates: getNewSurveyTemplates(),
  chosenTemplate: getChosenSurveyTemplate(),
  timezone: makeSelectTimezone(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'newSurvey', reducer });
const withSaga = injectSaga({ key: 'newSurvey', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect
)(NewSurveyPage);
