import { call, put, takeLatest } from 'redux-saga/effects';

import api from 'utils/api';

import { loadSurveyTemplatesDataSuccess, loadChosenSurveyTemplateSuccess } from './actions';
import { LOAD_SURVEY_TEMPLATES_DATA, LOAD_CHOSEN_SURVEY_TEMPLATE } from './constants';

function* loadTemplatesData() {
  const response = yield call(api, `/survey/type/list`);
  const respJson = yield call([response, 'json']);

  if (response.status === 200) {
    yield put(loadSurveyTemplatesDataSuccess(respJson.data));
  }
}

function* loadChosenTemplate({ payload }) {
  const response = yield call(api, `/survey/custom/${payload}`);
  const respJson = yield call([response, 'json']);

  if (response.status === 200) {
    yield put(loadChosenSurveyTemplateSuccess(respJson.data));
  }
}

export default function* getData() {
  yield takeLatest(LOAD_SURVEY_TEMPLATES_DATA, loadTemplatesData);
  yield takeLatest(LOAD_CHOSEN_SURVEY_TEMPLATE, loadChosenTemplate);
}
