/*
 * User Edit Actions
 */
import { LOAD_USER, LOAD_USER_ERROR, LOAD_USER_SUCCESS, UPDATE_USER, SAVE_USER, SAVE_USER_SUCCESS, SAVE_USER_ERROR } from './constants';

export const loadUser = (payload) => ({ type: LOAD_USER, payload });
export const loadUserSuccess = (payload) => ({ type: LOAD_USER_SUCCESS, payload });
export const loadUserError = (payload) => ({ type: LOAD_USER_ERROR, payload });

export const updateUser = (payload) => ({ type: UPDATE_USER, payload });

export const saveUser = (payload) => ({ type: SAVE_USER, payload });
export const saveUserSuccess = (payload) => ({ type: SAVE_USER_SUCCESS, payload });
export const saveUserError = (payload) => ({ type: SAVE_USER_ERROR, payload });
