import styled from 'styled-components';

export const ModalContent = styled.div`
  font-size: 18px;
  max-width: 700px;
  padding: 0;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;
`;

export const SelectLabel = styled.div`
  margin-bottom: 10px;
  font-size: 12px;
  margin-left: 24px;
`;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 115px 10px 0;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '115px')};

  .button {
    height: 50px;
    width: 160px;
  }

  .cancel-button {
    margin-left: 30px;
  }
`;
