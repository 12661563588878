import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './style.scss';
import '../../styles/colors';

import Menu from './Menu';

class Header extends React.Component {
  state = {
    showMobileMenu: false,
  }

  toggleMobileMenu = () => {
    const { showMobileMenu } = this.state;
    this.setState({ showMobileMenu: !showMobileMenu })
  }

  render() {
    const { displayNav, logout } = this.props;
    const { showMobileMenu } = this.state;

    return (
      <div className="header">
        <div id="logo-block">
          <Link to="/home">
            <div id="logo" />
          </Link>

          {displayNav && (
            <Menu logout={logout} />
          )}

          <div id="stamp" />

          {
            displayNav && (
              <div className={`mobile-menu ${showMobileMenu ? 'open' : ''}`}>
                <span className="menu-icon-wrapper" onClick={this.toggleMobileMenu}>
                  <FontAwesomeIcon icon={faBars} />
                </span>
                {showMobileMenu && (
                  <Menu logout={logout} closeMenu={this.toggleMobileMenu} isMobile />
                )}
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

export default Header;
