import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { loadTemplates } from './actions';
import { getNewReportTemplates, isLoading } from './selectors';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import NewReportTemplatesPage from './NewReportTemplatesPage';

const mapDispatchToProps = (dispatch) => {
  return {
    loadTemplates: () => {
      dispatch(loadTemplates());
    },
  };
};

const mapStateToProps = createStructuredSelector({
  templates: getNewReportTemplates(),
  isLoading: isLoading(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: 'newReportTemplates', reducer });
const withSaga = injectSaga({ key: 'newReportTemplates', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect
)(NewReportTemplatesPage);
