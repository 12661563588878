import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';

// import { history } from 'configureStore';
import Form from './components/Form';
import { Wrapper, Content, Row, Title, Line } from './styles';

export default class UpdatePasswordPage extends PureComponent {

  componentWillUnmount() {
    const { resetRedux } = this.props;
    resetRedux();
  }

  updatePassword = (values) => {
    const data = values.toJS();
    const { updatePassword } = this.props;

    updatePassword(data);
  };

  render() {
    const { updateLoading, updateSuccess, hasError } = this.props;

    return updateSuccess ? <Redirect to="/my-profile" /> : (
      <article>
        <Helmet>
          <title>Update Password</title>
          <meta name="description" content="Power Walks" />
        </Helmet>
        <Wrapper>
          <Content>
            <Row>
              <Title>Update Password</Title>
            </Row>
            <Line />
            <Form onSubmit={this.updatePassword} updateLoading={updateLoading} hasError={hasError} />
          </Content>
        </Wrapper>
      </article>
    );
  }
}
