import React from 'react';

import createIconComponent from '../../utils/createIconComponent';

const PWCoachIcon = createIconComponent({
  content: (
    <g id="Layer_2">
      <g id="Layer_1-2">
        <path d="M23.64,10.78,22,5.63a.9.9,0,0,0-1.14-.58L12.25,7.82a.69.69,0,0,0-.46.74A1.95,1.95,0,0,1,8.17,9.73a.68.68,0,0,0-.8-.33l-.94.3a9.19,9.19,0,0,0-6,11.58,9,9,0,0,0,17.31-5.09l5.57-4.42A.91.91,0,0,0,23.64,10.78ZM10.14,21.84a3.66,3.66,0,1,1,2.36-4.61A3.66,3.66,0,0,1,10.14,21.84Z" />
        <rect x="6.65" width="2.04" height="5.95" rx="0.65" transform="translate(-0.54 2.53) rotate(-18.06)" />
        <rect x="1.94" y="3.55" width="2.04" height="5.13" rx="0.65" transform="translate(-3.39 3.69) rotate(-43.23)" />
        <rect x="10.77" y="1.72" width="5.13" height="2.04" rx="0.65" transform="translate(8.96 15.63) rotate(-82.89)" />
      </g>
    </g>
  ),
  height: 26,
  width: 26,
});

PWCoachIcon.displayName = 'PWCoachIcon';

export default PWCoachIcon;
