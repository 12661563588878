import React from 'react';

import { CheckboxWrapper, CheckboxContainer, CheckboxMark, CheckboxTitle } from './styles';

export default function Checkbox(props) {
  const { title, onChange, input } = props;
  let { checked } = props;
  const onClick = () => {
    const newValue = !props.checked;
    if (onChange && typeof onChange === 'function') {
      onChange(newValue, props.module, props.flag);
    }

    if (input) {
      input.onChange(!input.value);
    }
  };

  if (input) {
    checked = input.value;
  }

  return (
    <CheckboxWrapper onClick={onClick}>
      <CheckboxContainer>{checked && <CheckboxMark />}</CheckboxContainer>
      {title && <CheckboxTitle dangerouslySetInnerHTML={{ __html: title }} />}
    </CheckboxWrapper>
  );
}
