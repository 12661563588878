import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';

import { loadStates } from './state/actions';
import { makeStatesSelect } from './state/selectors';
import reducer from './state/reducer';
import saga from './state/saga';
import { REDUCER_KEY } from './constants';
import UserInfo from './component';

const mapDispatchToProps = (dispatch) => ({
  loadStates: () => {
    dispatch(loadStates());
  },
});

const mapStateToProps = createStructuredSelector({
  states: makeStatesSelect(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: REDUCER_KEY, reducer });
const withSaga = injectSaga({ key: REDUCER_KEY, saga });

export default compose(
  withReducer,
  withSaga,
  withConnect
)(UserInfo);

export { mapDispatchToProps };
