export default {
  bloodOrange: '#fe5200',
  nightBlue: '#061832',
  greyblue: '#8192bf',
  lightgrey: '#ebebeb',
  warmGrey: '#979797',
  darkSlateBlue: '#1d3d69',
  greyishBrown: '#4a4a4a',
  headerBlack: '#092143',
  whiteGrey: '#dedede',
  veryLightBlue: '#eaf1ff',
  pinkishGrey: '#c0c0c0',
  emeraldGreen: '#04822b',
  perrywinkle: '#7ca3da',
  charcoalGrey: '#373a49',
  tip_success: '#95bd8c',
  tip_notify: '#adcee6',
  tip_error: '#ff8181',
  tip_error_bg: '#ffeeee',
  // whiteGrey: '#efefef',
};
