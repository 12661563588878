import React from 'react';
import { Field } from 'redux-form/immutable';

import FormInput from 'components/FormInput';
import ActionButton from 'components/ActionButton';
import Modal from 'components/Modal';

import { ActionWrapper, FieldsWrapper, InputWrapper, Label } from './styles';

function InputWithLabel(props) {
  const { label } = props;
  return (
    <InputWrapper>
      <Label>{label}</Label>
      <FormInput {...props}></FormInput>
    </InputWrapper >
  );
}

export default function NewRoomModal(props) {
  const { handleSubmit, submit, onClose, formValues } = props;
  const submitDisabled = !formValues.name;
  const modalRef = React.createRef();

  const onCloseHandler = () => {
    modalRef.current.closeModal();
  }

  return (
    <Modal title="New Room" showModal onClose={onClose} ref={modalRef}>
      <form onSubmit={handleSubmit}>
        <FieldsWrapper>
          <Field name="name" component={InputWithLabel} label="Name" />
          <Field name="emailList[0]" component={InputWithLabel} label="Email Address #1" />
          <Field name="emailList[1]" component={InputWithLabel} label="Email Address #2" />
          <Field name="emailList[2]" component={InputWithLabel} label="Email Address #3" />
          <Field name="emailList[3]" component={InputWithLabel} label="Email Address #4" />
          <Field name="emailList[4]" component={InputWithLabel} label="Email Address #5" />
        </FieldsWrapper>

        <ActionWrapper>
          <ActionButton title="Cancel" big width="240px" onClick={onCloseHandler} />
          <ActionButton title="Save" big type="alert" width="240px" onClick={submit} disabled={submitDisabled} />
        </ActionWrapper>
      </form>
    </Modal>
  );
}