import Select from 'react-dropdown-select';
import styled from 'styled-components';
import colors from '../../styles/colors';

export const Wrapper = styled.div`
  flex: 1;
  margin-left: 10px;

  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
`;

export const StyledSelect = styled(Select)`
  min-height: 38px;
  border-radius: 5px !important;
  border: ${(props) => (props.valid === false ? `3px solid ${colors.bloodOrange} !important` : 'none !important')};
  padding: 0 !important;
  background-color: ${colors.lightgrey};

  :focus {
    box-shadow: none;
  }

  div[disabled] {
    opacity: 0.7;
  }

  .react-dropdown-select-content {
    margin-left: 16px;

    ::before {
      content: ' ';
      display: block;
      height: 24px;
      width: 1px;
      background-color: ${colors.whiteGrey};
      position: absolute;
      right: 35px;
      top: 7px;
    }

    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 10px);
    }
  }

  .react-dropdown-select-dropdown {
    border-radius: 5px;
    border: 1px solid #ebebeb;
  }

  .react-dropdown-select-type-multi {
    margin-left: 10px;

    .react-dropdown-select-option {
      background-color: #c5d4f1;
      color: #4a4a4a;
      font-size: 15px;
      line-height: 27px;
      margin: 7px 5px;
      border-radius: 3px;
    }
  }

  .react-dropdown-select-content,
  .react-dropdown-select-dropdown {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 38px;
    letter-spacing: normal;
    color: ${colors.greyishBrown};
  }

  .react-dropdown-select-dropdown-handle {
    height: 100%;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    svg {
      width: 23px;
      height: 23px;
      margin-top: -3px;
    }
  }
`;
export const StyledSelectItem = styled.div`
  cursor: pointer;
  background-color: ${(props) => (props.selected ? colors.veryLightBlue : 'white')};
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 2.5;
  color: ${colors.greyishBrown};
  padding: 0 15px;

  :hover {
    background-color: ${colors.veryLightBlue};
  }
`;
