import { takeLatest, call, put } from 'redux-saga/effects';

import api from 'utils/api';
import { loadStatesSuccess } from './actions';
import { LOAD_STATES } from './constants';

function* loadStatesData() {
  const response = yield call(api, `/data/states/all`);
  const respJson = yield call([response, 'json']) || { data: [] };
  const states = respJson.data.map((s) => ({ label: s.name, value: s.id }));

  yield put(loadStatesSuccess(states));
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* getData() {
  yield takeLatest(LOAD_STATES, loadStatesData);
}
