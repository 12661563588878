import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import injectSaga from 'utils/injectSaga';

import { updateAuthToken, updatePermissions, updateTimezone, updateUserId } from './actions';
import { makeSelectAuthenticated } from './selectors';
import { REDUCER_KEY } from './constants';
import saga from './saga';
import App from './App';

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(updateAuthToken());
    dispatch(updatePermissions([]));
    dispatch(updateTimezone());
    dispatch(updateUserId());
  },
});

const mapStateToProps = createStructuredSelector({
  authenticated: makeSelectAuthenticated(),
  loggedIn: makeSelectAuthenticated(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withSaga = injectSaga({ key: REDUCER_KEY, saga });

export default compose(
  withConnect,
  withSaga
)(App);

export { mapDispatchToProps };
