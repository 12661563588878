import styled from 'styled-components'

export const PageWrapper = styled.section`
  margin: 52px 58px;
  max-width: calc(100% - 400px);

  @media (max-width: 768px) {
    background-color: white;
    padding: 15px;
    margin: 0;
    max-width: 100%;

    &.with-blue {
      background: #092143;
    }
  }
`;

export const PageContent = styled.div`
  @media (max-width: 768px) {
    background-color: white;
    padding: 15px;
  }
`;