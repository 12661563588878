import { range } from 'lodash';
import React, { Component } from 'react';

import Checkbox from '../components/Checkbox3';
import FormInput from '../components/FormInput';
import api, { POST } from '../utils/api';
import { SavableEmail } from './SavableEmail';
import { StyledContainer } from './styles';

export class SendRoomEmailForm extends Component {
  state = { emailsList: [], extraEmails: '', emailsSendMap: {}, sendToExtraMails: false, emptyEmails: {} };

  async loadEmailsForRoom() {
    const {
      location: {
        state: { roomId },
      },
    } = this.props;
    const response = await api(`/email/get/room/${roomId}`);
    const json = await response.json();
    const emails = json.data || [];
    this.setState({ emailsList: emails });
    const emailMap = emails.reduce((res, email) => ({ ...res, [email]: true }), {});
    this.setState({ emailsSendMap: emailMap });
  }

  async saveEmailForRoom(email) {
    const {
      location: {
        state: { roomId },
      },
    } = this.props;
    await api(`/email/add/room/${roomId}`, { body: { email }, method: POST });
  }

  async sendEmail() {
    const {
      location: {
        state: { roomId, surveyId },
      },
    } = this.props;
    try {
      await api(`/email/send/survey/observationNotes`, {
        method: POST,
        body: {
          surveyId,
          roomId,
          emails: this.getEmails(),
        },
      });
    } catch (error) {}
  }

  getEmails() {
    return [...this.getRoomMails(), ...this.getExtraEmails(), ...this.getUserInputEmails()];
  }

  getUserInputEmails() {
    return Object.values(this.state.emptyEmails)
      .filter((item) => item.isSelected)
      .map((item) => item.email);
  }

  getRoomMails() {
    return Object.entries(this.state.emailsSendMap)
      .filter(([, isUsed]) => isUsed)
      .map(([email]) => email);
  }

  getExtraEmails() {
    const { extraEmails, sendToExtraMails } = this.state;
    if (!sendToExtraMails) {
      return [];
    }

    return extraEmails
      .split(',')
      .map((mail) => mail.trim())
      .filter((mail) => Boolean(mail));
  }

  componentDidMount() {
    this.loadEmailsForRoom();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.state.roomId !== prevProps.location.state.roomId) {
      this.loadEmailsForRoom();
      this.setState({ emptyEmails: {} });
    }
  }

  renderExtraMailField() {
    const { extraEmails, sendToExtraMails } = this.state;
    return (
      <label>
        <Checkbox
          title="Send email to"
          checked={sendToExtraMails}
          onChange={() => this.setState((state) => ({ ...state, sendToExtraMails: !state.sendToExtraMails }))}
        />
        <FormInput
          value={extraEmails}
          onChange={({ target }) => this.setState({ extraEmails: target.value })}
          placeholder="Type additional emails separated"
        />
        <div style={{ width: '250px' }}>Field has {extraEmails.length > 0 ? this.getExtraEmails().length : 0} recipient(s)</div>
      </label>
    );
  }

  render() {
    const { emailsList, emailsSendMap } = this.state;
    return (
      <React.Fragment>
        <h2>Send the note to the following recipient(s)!</h2>

        <StyledContainer>
          <div>
            {emailsList.map((email) => {
              return (
                <label key={email}>
                  <Checkbox
                    title="Send email to"
                    checked={emailsSendMap[email]}
                    onChange={() => {
                      this.setState((state) => ({
                        ...state,
                        emailsSendMap: {
                          ...state.emailsSendMap,
                          [email]: !state.emailsSendMap[email],
                        },
                      }));
                    }}
                  />
                  <FormInput value={email} />
                  <Checkbox title="Save email" checked />
                </label>
              );
            })}
            {range(5 - emailsList.length).map((id) => {
              return (
                <SavableEmail
                  key={id}
                  onSave={(email) => this.saveEmailForRoom(email)}
                  onChange={(email) =>
                    this.setState((state) => ({
                      ...state,
                      emptyEmails: { ...state.emptyEmails, [id]: { ...state.emptyEmails[id], email } },
                    }))
                  }
                  onSelect={(isSelected) =>
                    this.setState((state) => ({
                      ...state,
                      emptyEmails: {
                        ...state.emptyEmails,
                        [id]: { ...state.emptyEmails[id], isSelected },
                      },
                    }))
                  }
                />
              );
            })}
            {this.renderExtraMailField()}
          </div>
        </StyledContainer>
      </React.Fragment>
    );
  }
}
