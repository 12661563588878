/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { StyledSelect, StyledSelectItem, Wrapper } from './styles';

const DisabledItem = styled.div`
  opacity: 0.6;
`

const selectItemRenderer = ({ item, methods }) => (
  <StyledSelectItem selected={methods.isSelected(item)}>
    {!item.disabled && <div onClick={() => methods.addItem(item)}>{item.label} </div>}
    {item.disabled && <DisabledItem>{item.label}</DisabledItem>}
  </StyledSelectItem>
);

export function ControlledSelect(props) {
  const { onChange, options, className, placeholder, withAutoSelect } = props;
  const [values, setValues] = useState([]);
  const once = useRef(false);

  const onChangeHandler = (e) => {
    onChange(e);
  };

  useEffect(() => {
    if (once.current === true) {
      return;
    }
    if (Array.isArray(options) && options.length) {
      const value = options[0];
      if (withAutoSelect && options.length === 1) {
        setValues([value]);
      }
      once.current = true;
    }
  }, [options, withAutoSelect]);

  return (
    <Wrapper className="select-wrapper">
      <StyledSelect
        className={className}
        placeholder={placeholder}
        values={values}
        options={options}
        color="#333"
        searchable={false}
        dropdownGap={0}
        onChange={onChangeHandler}
        closeOnSelect
        itemRenderer={selectItemRenderer}
      />
    </Wrapper>
  );
}

export default function (props) {
  const { input, onChange, noFlex, multi, selectedValue, searchable = false } = props;
  const newProps = { ...props };
  const wrapperProps = noFlex ? { style: { flex: 'none' } } : {};

  const onChangeHandler = (e) => {
    if (input && input.onChange) {
      if (multi) {
        const value = (e && e.length ? e : []).flat(Infinity);
        input.onChange(value.length ? value : undefined);
      } else {
        input.onChange(e[0]);
      }
    }

    if (onChange) {
      onChange(e);
    }
  };

  const onBlurHandler = () => {
    if (input && input.onChange) {
      input.onBlur(input.value);
    }
  };

  if (input && input.value) {
    if (typeof input.value === 'string') {
      newProps.values = [
        {
          [props.valueField || 'value']: input.value,
          [props.labelField || 'label']: input.value,
        },
      ];
    } else if (typeof input.value === 'object' && !input.value.length) {
      newProps.values = [input.value];
    }
  } else if (selectedValue) {
    const selected = newProps.options.find((item) => item.value === selectedValue);
    if (selected) {
      newProps.values = [selected];
    } else {
      newProps.values = [];
    }
  }

  return (
    <Wrapper {...wrapperProps} className="select-wrapper">
      <StyledSelect
        {...newProps}
        color="#333"
        searchable={searchable}
        dropdownGap={0}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        closeOnSelect
        itemRenderer={selectItemRenderer}
      />
    </Wrapper>
  );
}
