import React from 'react';
import colors from '../../styles/colors';

import { ActionButtonWrapper } from './styles';

export default function ActionButton(props) {
  const { title, type, onClick, big, width, height, disabled, children } = props;
  let color;

  switch (type) {
    case 'alert':
      color = colors.bloodOrange;
      break;
    default:
      color = colors.darkSlateBlue;
  }

  return (
    <ActionButtonWrapper
      {...props}
      height={height || (big ? '60px' : '34px')}
      width={width || (big ? '100%' : '100px')}
      onClick={(...args) => {
        // eslint-disable-next-line no-unused-expressions
        if (disabled !== true && typeof onClick === 'function') {
          onClick(...args);
        }
      }}
      color={color}
    >
      {title}
      {children}
    </ActionButtonWrapper>
  );
}
