import React, { useEffect, useRef } from 'react';

import Modal from 'components/Modal';

import { TextWrapper } from './styles';

export default function TooltipModal({ show, title, body, onClose = () => null }) {
  const modalRef = useRef(null);

  useEffect(() => {
    if (show) {
      modalRef.current.openModal();
    }
  }, [show])

  return (
    <Modal title={title} showModal={false} ref={modalRef} onClose={() => onClose(false)}>
      <TextWrapper dangerouslySetInnerHTML={{ __html: body }} />
    </Modal>
  );
}