import { takeLatest, call, put } from 'redux-saga/effects';

import api, { POST } from 'utils/api';

import { loadUserSuccess, saveUserSuccess } from './actions';
import { LOAD_USER, SAVE_USER } from './constants';
import { notifySuccess } from "../../../../utils/notification";

function* loadUserData() {
  const response = yield call(api, `/profile/me`);
  const respJson = yield call([response, 'json']);

  yield put(loadUserSuccess(respJson));
}

function* updateUserData({ payload }) {
  const response = yield call(api, `/profile/update`, { method: POST, body: payload });
  const respJson = yield call([response, 'json']);

  notifySuccess('User successfully saved');
  yield put(saveUserSuccess(respJson));
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* getData() {
  yield takeLatest(LOAD_USER, loadUserData);
  yield takeLatest(SAVE_USER, updateUserData);
}
