import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectPermissions, makeSelectRoles } from '../../containers/App/selectors';
import AccessControl from './component';

const mapStateToProps = createStructuredSelector({
  permissions: makeSelectPermissions(),
  roles: makeSelectRoles(),
});

export default connect(mapStateToProps)(AccessControl);
