import React from 'react';
import createIconComponent from '../../utils/createIconComponent';

const SearchIcon = createIconComponent({
  content: (
    <g fill="none" fillRule="evenodd" transform="translate(1 1)" strokeWidth="2">
      <circle cx="6.5" cy="6.5" r="6.5" />
      <path d="M11 11l5 5" />
    </g>
  ),
  height: 18,
  width: 18,
  parentProps: {
    stroke: '#4A4A4A',
  },
});

SearchIcon.displayName = 'SearchIcon';

export default SearchIcon;
