import React, { useRef, useLayoutEffect, useState } from 'react';

import { StyledTip } from './style';

const Tip = ({val, children, isValid}) => {
  const targetRef = useRef();
  const [dimension, setDimension] = useState(0);

  useLayoutEffect(
    () => {
      if (
        targetRef.current
        && targetRef.current.childNodes
        && targetRef.current.childNodes[1]
        && targetRef.current.childNodes[1].clientWidth) {
        setDimension(targetRef.current.childNodes[1].clientWidth)
      }
    },
    []
  );

  return (
    <StyledTip
      width={dimension}
      ref={targetRef}
      isVisible={!isValid}
    >
      <div className="tip">
        {val}
      </div>
      {children}      
    </StyledTip>
  )
};

export default Tip;

