export const UPDATE_AUTH_TOKEN = 'powerwalks/Global/UPDATE_AUTH_TOKEN';
export const UPDATE_PERMISSIONS = 'powerwalks/Global/UPDATE_PERMISSIONS';
export const UPDATE_ROLES = 'powerwalks/Global/UPDATE_ROLES';
export const UPDATE_TIMEZONE = 'powerwalks/Global/UPDATE_TIMEZONE';
export const UPDATE_USER_ID = 'powerwalks/Global/UPDATE_USER_ID';

export const REFRESH_AUTH_TOKEN = 'powerwalks/Global/REFRESH_AUTH_TOKEN';
export const REFRESH_AUTH_TOKEN_SUCCESS = 'powerwalks/Global/REFRESH_AUTH_TOKEN_SUCCESS';
export const REFRESH_AUTH_TOKEN_ERROR = 'powerwalks/Global/REFRESH_AUTH_TOKEN_ERROR';

export const REDUCER_KEY = 'global';
