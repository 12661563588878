import styled from 'styled-components';
import colors from 'styles/colors';

export const Sidebar = styled.div`
  color: #ffffff;
  background-color: ${colors.nightBlue};
  padding: 50px;
  flex: 0 0 400px;

  @media (max-width: 768px) {
    padding: 30px 15px;
    flex: 1;
  }
`;

export const ActionButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    margin-bottom: 30px;

    &:last-child {
      margin: 0;
    }
  }
`;

export const SidebarTitle = styled.div`
  color: ${colors.greyblue};
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 50px;
`;

export const SidebarTitleCounter = styled.span`
  font-size: 21px;
`;
