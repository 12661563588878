import React, { useState } from 'react';
import styled from 'styled-components';
import api from '../utils/api';
import color from '../styles/colors';
import { useQuery } from '@tanstack/react-query';

const Container = styled.div`
  background-color: rgb(231, 239, 255);
  color: ${color.darkSlateBlue};
  border-radius: 5px;
  padding: 8px 20px;
  position: relative;
  top: -105px;
  left: 315px;
  width: calc(100% - 315px - 190px);
  margin-bottom: -75px;

  @media (max-width: 768px) {
    width: calc(100% - 20px);
    left: 0;
    top: 0;
    margin-inline: 10px;
    margin-bottom: 25px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  text-align: center;
  color: ${color.bloodOrange};
`;

const Signature = styled.div`
  color: ${color.bloodOrange};
  font-weight: bold;
  font-size: 0.9em;
  text-align: center;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 10px;
  top: 0px;
  text-transform: uppercase;
  font-family: Consolas;
  color: grey;
  font-size: 0.9em;
  cursor: pointer;
`;

async function fetchLatestMessage() {
  const resp = await api('/messages/latest').then((resp) => resp.json());
  return resp?.data;
}

export default function BubbleMessage() {
  const [hide, setHide] = useState(false);
  const { data: message } = useQuery(['messages', 'latest'], fetchLatestMessage);

  return (
    <>
      {!hide && !!message && Object.keys(message).length > 0 && (
        <Container>
          <CloseButton onClick={() => setHide(true)}>x</CloseButton>
          <Title>{message.title}</Title>
          {message.body}
          <Signature>{message.signature}</Signature>
        </Container>
      )}
    </>
  );
}
