import React from 'react';
import { StyledSelect, StyledSelectItem, Wrapper, Label } from './styles';

const selectItemRenderer = ({ item, methods }) => (
  <StyledSelectItem selected={methods.isSelected(item)}>
    <div onClick={() => methods.addItem(item)}>{item.label} </div>
  </StyledSelectItem>
);

export default function(props) {
  const { input, onChange, noFlex, label } = props;
  const wrapperProps = noFlex ? { style: { flex: 'none' } } : {};

  const onChangeHandler = (e) => {
    if (input && input.onChange) {
      input.onChange(e[0]);
    }

    if (onChange) {
      onChange(e);
    }
  };

  const onBlurHandler = () => {
    if (input && input.onChange) {
      input.onBlur(input.value);
    }
  };

  return (
    <Wrapper {...wrapperProps}>
      <Label>{label}</Label>
      <StyledSelect
        {...props}
        color="#333"
        searchable={false}
        dropdownGap={0}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        itemRenderer={selectItemRenderer}
      />
    </Wrapper>
  );
}
