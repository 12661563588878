import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import { reducer as form } from 'redux-form/immutable';

import globalReducer from './containers/App/reducer';
import { history } from './configureStore';

export default function createReducer(injectedReducers) {
  return combineReducers({
    router: connectRouter(history),
    global: globalReducer,
    form,
    ...injectedReducers,
  });
}
