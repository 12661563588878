import React from 'react';

import { RadioWrapper, RadioContainer, RadioMark, RadioTitle } from './styles';

function Radio({ title, checked, onSelect }) {
  return (
    <RadioWrapper onClick={onSelect}>
      <RadioContainer>{checked && <RadioMark />}</RadioContainer>
      {title && <RadioTitle>{title}</RadioTitle>}
    </RadioWrapper>
  );
}

export default function RadioGroup({ options = [], onChange = () => { }, titleExtractor = val => val, disabled }) {
  const onSelectHandler = (val, index) => () => {
    if (disabled !== true) {
      const newOptions = options.map((option) => ({ ...option, value: false }));
      newOptions[index].value = !val.value;
      onChange(newOptions);
    }
  }

  return options.map((val, index) =>
    <Radio
      key={val.id || val}
      title={titleExtractor(val)}
      checked={val.value}
      onSelect={onSelectHandler(val, index)}
    />
  );
}