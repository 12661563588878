import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;

export const FormItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

export const FormLabel = styled.div`
  flex: 1;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: 600;
  line-height: 3.57;
  color: #000000;
`;

export const SelectWrapper = styled.div`
  flex: 2;
  margin-left: -15px;
`;