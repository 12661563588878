import React, { useState, useEffect } from 'react'
import FormInput from 'components/FormInput';
import _ from "lodash";
import styled from "styled-components";

const SubcategoryHeader = styled.div`
font-size: 20px;
font-weight: bold;
`

export default function CheckboxGroup({ options = [], onChange = () => { }, disabled }) {
  const [selection, setSelection] = useState({});
  useEffect(() => {
    onChange(selection)
  }, [options, selection, onChange])

  const groupedOptions = _.groupBy(options, 'subcategory');

  return (
      <>
        {
          Object.keys(groupedOptions).map(categoryName => {
            return [
              categoryName !== "undefined" && categoryName !== "null" &&
              <SubcategoryHeader>{categoryName}</SubcategoryHeader>,
              groupedOptions[categoryName].map((opt) => {
                return (
                    <div key={opt.id}>
                      <label>
                        {opt.name}
                        <FormInput
                            disabled={disabled}
                            value={opt.option && opt.option[0] && opt.option[0].value}
                            onChange={e => {
                              const val = e.target.value;
                              if (opt && opt.option && opt.option[0] && opt.option[0].value) {
                                opt.option[0].value = val;
                              }
                              setSelection(state => ({...state, [opt.id]: val}))
                            }}
                        />
                      </label>
                    </div>
                )
              })
            ]
          })
        }
      </>
  )
}
