import React from 'react';
import { ReactReduxContext } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';

import getInjectors from './sagaInjectors';

export default ({ key, saga, mode }) => (WrappedComponent) => {
  class InjectSaga extends React.Component {
    static WrappedComponent = WrappedComponent;

    static displayName = `withSaga(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

    static contextType = ReactReduxContext;

    constructor(props, context) {
      super(props, context);

      getInjectors(context.store).injectSaga(key, { saga, mode }, props);
    }

    componentWillUnmount() {
      getInjectors(this.context.store).ejectSaga(key);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return hoistNonReactStatics(InjectSaga, WrappedComponent);
};
