import React from 'react';
import styled from 'styled-components';

import Select from "./component";

export default Select;

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;  
  font-weight: bold;
  width: 50%;
  min-width: 300px;
  margin-bottom: 30px;
  position: relative;
`;

export function SelectNamed({ label, ...props }) {
  return (
    <SelectWrapper>
      {label}
      <Select {...props}></Select>
    </SelectWrapper >
  )
}
