import React, { PureComponent } from 'react';
import { Field, FormSection } from 'redux-form/immutable';

import Select from 'components/Select';
import Checkbox from 'components/Checkbox';
import FormInput from 'components/FormInput';

import { CheckboxWrapper, FormItem, FormLabel, RadioContainer, SelectWrapper } from "./styles";
import { SALUTATION_OPTS } from './constants';
import AccessControl from '../../AccessControl';
import RadioGroup from '../../RadioGroup';

const ForcePasswordRefreshCheckbox = ({ input }) => {
  if (!input.value) {
    return null;
  }
  return (
    <RadioContainer >
      <RadioGroup
        options={['Yes', 'No']}
        defaultValue={input.value === 'UNLOCKED' ? 'No' : 'Yes'}
        onChange={(val) => {
          if (val === 'Yes') {
            input.onChange('LOCKED');
          } else {
            input.onChange('UNLOCKED');
          }
        }}
      ></RadioGroup>
    </RadioContainer>
  );
};

export default class UserInfo extends PureComponent {
  componentDidMount() {
    const { loadStates } = this.props;
    loadStates();
  }

  render() {
    const { states, initialValues, isMyProfile } = this.props;
    const userPath = ['user', 'userInfo'];
    const userInfo = initialValues.hasIn(userPath) ? initialValues.getIn(userPath).toJS() : {};
    const initialSalutation = userInfo.salutation ? [userInfo.salutation] : [];
    const initialState = userInfo.state ? [userInfo.state] : [];

    return (
      <FormSection name="user">
        <FormItem>
          <FormLabel>Login:</FormLabel>
          <Field name="username" component={FormInput} type="text" />
        </FormItem>
        <FormItem>
          <FormLabel>Salutation:</FormLabel>
          <SelectWrapper>
            <Field name="userInfo.salutation" component={Select} options={SALUTATION_OPTS} values={initialSalutation} />
          </SelectWrapper>
        </FormItem>
        <FormItem>
          <FormLabel>First Name:</FormLabel>
          <Field name="userInfo.firstName" component={FormInput} type="text" />
        </FormItem>
        <FormItem>
          <FormLabel>Middle Name:</FormLabel>
          <Field name="userInfo.middleName" component={FormInput} type="text" />
        </FormItem>
        <FormItem>
          <FormLabel>Last Name:</FormLabel>
          <Field name="userInfo.lastName" component={FormInput} type="text" />
        </FormItem>
        <FormItem>
          <FormLabel>Email Address:</FormLabel>
          <Field name="email" component={FormInput} type="text" />
        </FormItem>
        <FormItem>
          <FormLabel>Title:</FormLabel>
          <Field name="userInfo.title" component={FormInput} type="text" />
        </FormItem>
        <FormItem>
          <FormLabel>Country:</FormLabel>
          <Field name="userInfo.country" component={FormInput} type="text" />
        </FormItem>
        <FormItem>
          <FormLabel>State:</FormLabel>
          <SelectWrapper>
            <Field name="userInfo.state" component={Select} options={states} values={initialState} />
          </SelectWrapper>
        </FormItem>
        <FormItem>
          <FormLabel>City:</FormLabel>
          <Field name="userInfo.city" component={FormInput} type="text" />
        </FormItem>
        <FormItem>
          <FormLabel>Street Address:</FormLabel>
          <Field name="userInfo.street" component={FormInput} type="text" />
        </FormItem>
        <FormItem>
          <FormLabel>Postal code:</FormLabel>
          <Field name="userInfo.zipCode" component={FormInput} type="text" />
        </FormItem>
        <FormItem>
          <FormLabel>Phone:</FormLabel>
          <Field name="userInfo.phone" component={FormInput} type="text" />
        </FormItem>
        {/* <FormItem>
          <FormLabel>Reset password on login:</FormLabel>
          <CheckboxWrapper>
            <Checkbox onChange={console.log} />
          </CheckboxWrapper>
        </FormItem> */}
        {!isMyProfile && (
          <AccessControl hasRole="global" hasLevel="managing">
            <FormItem>
              <FormLabel>Reset password on login:</FormLabel>
              <CheckboxWrapper>
                <Field name="forceResetPassword" component={ForcePasswordRefreshCheckbox} />
              </CheckboxWrapper>
            </FormItem>
            <FormItem>
              <FormLabel>Active:</FormLabel>
              <CheckboxWrapper>
                <Field name="isActive" component={Checkbox} type="checkbox" />
              </CheckboxWrapper>
            </FormItem>
          </AccessControl>
        )}
      </FormSection>
    );
  }
}
