import React from 'react';

import { MENU_ITEMS } from './contants';
import MenuItem from './MenuItem';

export default ({ logout, isMobile, closeMenu }) => (
  <div id="tabs-block">
    <div className={`nav-bar ${isMobile ? 'nav-bar-mobile' : 'nav-bar-desktop'}`}>
      {
        MENU_ITEMS.map((item) => (
          <MenuItem item={item} actions={{ logout, closeMenu }} key={item.title} />
        ))
      }
    </div>
  </div >
)