import React from 'react';
import { Field, FormSection } from 'redux-form/immutable';

import Select from '../Select';
import { FormItem, FormLabel, SelectWrapper } from './styles';

export default class UserRole extends React.Component {
  rolePath = ['user', 'userRoles', '0'];

  state = {
    role: ''
  };

  componentDidMount() {
    const { loadStates, loadRoles, loadAccessLevels, loadDistricts, loadCampuses } = this.props;
    const { initialRole, initialDistrict, initialState } = this.getInitialState();

    loadStates();
    loadRoles();

    if (initialRole && initialRole.length) {
      loadAccessLevels(initialRole[0].value);
    }

    if (initialState && initialState.length) {
      loadDistricts(initialState[0].value);
    }

    if (initialDistrict && initialDistrict.length) {
      loadCampuses(initialDistrict[0].value);
    }
  }

  getItemValue = (item) => ({
    label: item.name,
    value: item.id,
  });

  getInitialState = () => {
    const { initialValues } = this.props;

    const userRole = initialValues.hasIn(this.rolePath) ? initialValues.getIn(this.rolePath).toJS() : {};
    const initialRole = [];
    const initialAccessLevel = [];
    const initialCampus = [];
    const initialDistrict = [];
    const initialRegions = [];
    const initialState = [];

    if (userRole.accessLevel) {
      initialAccessLevel.push({
        value: userRole.accessLevel.id,
        label: userRole.accessLevel.title,
      });
    }

    if (userRole.role) {
      initialRole.push({
        value: userRole.role.id,
        label: userRole.role.title,
      });
    }

    if (userRole.campus) {
      const { campus } = userRole;
      initialCampus.push(this.getItemValue(campus));
      initialDistrict.push(this.getItemValue(campus.district));
      initialState.push(this.getItemValue(campus.district.state));
    } else if (userRole.district) {
      const { district } = userRole;
      initialDistrict.push(this.getItemValue(district));
      initialState.push(this.getItemValue(district.state));
    } else if (userRole.regions && userRole.regions.length) {
      const { regions } = userRole;
      initialRegions.push(...regions.map(this.getItemValue));
      initialState.push(this.getItemValue(regions[0].state));
    }

    return { initialRole, initialAccessLevel, initialCampus, initialDistrict, initialRegions, initialState };
  };

  onRoleChange = (selected) => {
    if (!selected) {
      return;
    }
    const { loadAccessLevels } = this.props;

    this.setState({
      role: selected.label,
    });

    loadAccessLevels(selected.value);
  };

  onStateChange = (selected) => {
    if (!selected) {
      return;
    }
    const { loadDistricts } = this.props;
    loadDistricts(selected.value);
  };

  onDistrictChange = (selected) => {
    if (!selected) {
      return;
    }
    const { loadCampuses } = this.props;
    loadCampuses(selected.value);
  };

  onValueChange = (fieldName) => (value) => {
    this.setState({
      [fieldName]: value,
    });
  };

  render() {
    const { role } = this.state;
    const { roles, accessLevels, districts, states, campuses, disabled } = this.props;
    const { initialRole, initialAccessLevel, initialCampus, initialDistrict, initialRegions, initialState } = this.getInitialState();

    return (
      <FormSection name="user.userRoles[1]">
        <FormItem>
          <FormLabel>Role:</FormLabel>
          <SelectWrapper>
            <Field
              name="role"
              component={Select}
              options={roles}
              values={initialRole}
              disabled={disabled}
              onChange={this.onRoleChange}
            />
          </SelectWrapper>
        </FormItem>

        <FormItem>
          <FormLabel>Access Level:</FormLabel>
          <SelectWrapper>
            <Field
              name="accessLevel"
              component={Select}
              options={accessLevels}
              values={initialAccessLevel}
              disabled={disabled}
            />
          </SelectWrapper>
        </FormItem>
        {role !== 'Global' && (
          <FormItem>
            <FormLabel>State:</FormLabel>
            <SelectWrapper>
              <Field
                name="state"
                component={Select}
                options={states}
                values={initialState}
                disabled={disabled}
                onChange={this.onStateChange}
              />
            </SelectWrapper>
          </FormItem>
        )}
        {role !== 'Global' && role !== 'Regional' && (
          <FormItem>
            <FormLabel>District:</FormLabel>
            <SelectWrapper>
              <Field
                name="district"
                component={Select}
                options={districts}
                values={initialDistrict}
                disabled={disabled}
                onChange={this.onDistrictChange}
              />
            </SelectWrapper>
          </FormItem>
        )}
        {role === 'Regional' && (
          <FormItem>
            <FormLabel>Districts:</FormLabel>
            <SelectWrapper>
              <Field
                name="regions"
                component={Select}
                values={initialRegions}
                multi
                options={districts}
                disabled={disabled}
              />
            </SelectWrapper>
          </FormItem>
        )}
        {role === 'Campus' && (
          <FormItem>
            <FormLabel>Campus:</FormLabel>
            <SelectWrapper>
              <Field
                name="campus"
                component={Select}
                options={campuses}
                values={initialCampus}
                disabled={disabled}
              />
            </SelectWrapper>
          </FormItem>
        )}
      </FormSection>
    );
  }
}
