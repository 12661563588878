import styled from 'styled-components'
import colors from '../styles/colors'

export const PageHeader = styled.div`
font-family: 'Open Sans', sans-serif;
color: ${colors.headerBlack};
text-transform: uppercase;
font-weight: bold;
font-size: 14px;
margin-bottom: 18px;
`;