/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import DateTime from 'react-datetime';
import { Helmet } from 'react-helmet';

import 'react-datetime/css/react-datetime.css';

import api, { POST } from 'utils/api';
import { SelectNamed } from 'components/Select';
import { Line } from 'components/Line';
import ActionButton from 'components/ActionButton';
import Sidebar from 'components/Sidebar';
import InfoIcon from '../../components/Icons/InfoIcon';
import SurveyOptions from './components/SurveyOptions';

import TooltipModal from '../NewSurvey/components/TooltipModal';

import './style.scss';

import { tooltips } from './config/tooltips';

const timezonesMapping = {
  'Hawaiian Standard Time': 'Pacific/Honolulu',
  'Alaskan Standard Time': 'America/Anchorage',
  'Pacific Standard Time': 'America/Los_Angeles',
  'Mountain Standard Time': 'America/Phoenix',
  'Central Standard Time': 'America/Chicago',
  'Eastern Standard Time': 'America/New_York',
  'Atlantic Standard Time': 'Atlantic/Bermuda',
};

export default function NewSurveyPage({ location, history, timezone }) {
  const [createDate, setCreateDate] = useState(() => new Date());
  const [infoSurvey, setInfoSurvey] = useState({});

  const [tooltipPos, setTooltipPos] = useState(0);
  const [tooltipText, setTooltipText] = useState({ title: '', body: '' });

  const [showModal, setShowModal] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isValidOptions, setIsValidOptions] = useState(false);

  const [options, setOptions] = useState([]);

  const [subjects, setSubjects] = useState([]);
  const [grades, setGrades] = useState([]);

  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);

  const [willDoData, setWillDoData] = useState('');
  const [doInsteadData, setDoInsteadData] = useState('');
  const [roomList, setRoomList] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [surveyType, setSurveyType] = useState(null);

  const [campusId, setCampusId] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const response = await api(`/survey/custom/${location.state.surveyType}/for/campus/${location.state.campusId}`);
      const json = await response.json();
      setOptions(json.data.modules);
    }

    if (location && location.state && location.state.surveyType) {
      fetchData();
      setInfoSurvey(location.state);
      setSelectedRoom(location.state.room);
      setSurveyType(location.state.surveyType);
    }
  }, [location && location.state && location.state.surveyType]);
  useEffect(() => {
    async function fetchData() {
      const response = await api(`/data/subjects`);
      const json = await response.json();
      setSubjects((json.data || []).map((e) => ({ value: e.id, label: e.name })));
    }

    fetchData();
  }, []);
  useEffect(() => {
    async function fetchData() {
      const response = await api(`/data/grades?campusId=${campusId}`);
      const json = await response.json();
      setGrades((json.data || []).map((e) => ({ value: e.id, label: e.name })));
    }

    if (campusId) {
      fetchData();
    }
  }, [campusId]);

  useEffect(() => {
    if (location && location.state && location.state.campusId) {
      setCampusId(location.state.campusId);
    }
  }, [location && location.state && location.state.campusId]);

  useEffect(() => {
    async function fetchData() {
      const response = await api(`/data/rooms?campusId=${campusId}`);
      const json = await response.json();

      setRoomList(json.data);
    }

    if (campusId) {
      fetchData();
    }
  }, [campusId]);

  if (!(location && location.state && location.state.surveyType)) {
    history.push('/home');
    return null;
  }

  const setTooltip = (name) => {
    if (tooltipText?.title === name) {
      setTooltipText({ title: '', body: '' });
    } else {
      setTooltipText({ title: name, body: tooltips[name] });
    }
  }

  return (
    <div className="New-survey-page">
      <Helmet>
        <title>New survey</title>
        <meta name="description" content="New survey page" />
      </Helmet>
      <TooltipModal show={showModal} title={tooltipText.title} body={tooltipText.body} onClose={setShowModal} />
      <div className="ns-page">
        <div className="new-survey-wrapper">
          <div id="page-title">NEW SURVEY</div>
          <div id="steps-title">{infoSurvey.surveyTypeName}</div>
          <div className="container">
            <ul className="progressbar">
              <li className="actived">HOME</li>
              <li className="actived">SURVEY SETTINGS</li>
              <li className="active">{infoSurvey.surveyTypeName}</li>
              <li>FINISH</li>
            </ul>
          </div>
          <div id="block-wrapper">
            <div id="block-title">
              <span className="block-title--text">
                Common Info
              </span>
              <span>
                <InfoIcon
                  onClick={(e) => {
                    if (window.innerWidth < 768) {
                      setShowModal(true);
                    } else {
                      const { top } = e.currentTarget.getBoundingClientRect();
                      setTooltipPos(window.scrollY + top);
                    }

                    setTooltip('Common Info')
                  }}
                  width={33}
                  height={33}
                  float="right"
                />
              </span>
            </div>
            <Line margin="25px 0" />
            <div id="inner-block-line">
              <div id="inner-block">
                <div className="inner-item">Survey Definition:</div>
                <div className="inner-item">Version:</div>
                <div className="inner-item">State:</div>
                <div className="inner-item">District:</div>
                <div className="inner-item">Campus:</div>
                <div className="inner-item">Room:</div>
              </div>
              <div id="inner-block">
                <div className="inner-item">{infoSurvey.surveyTypeName}</div>
                <div className="inner-item">{infoSurvey.version ?? 1}</div>
                <div className="inner-item">{infoSurvey.stateName}</div>
                <div className="inner-item">{infoSurvey.districtName}</div>
                <div className="inner-item">{infoSurvey.campusName}</div>
                <div className="inner-item room-select">
                  <SelectNamed
                      label=''
                      selectedValue={selectedRoom}
                      options={roomList.map((obj) => ({ label: obj.name, value: obj.id }))}
                      onChange={(val) => {
                        if (val && val[0] && val[0].value) {
                          setSelectedRoom(val[0].value);
                        }
                      }}
                  />
                </div>
              </div>
            </div>
            <Line margin="25px 0" />
            <div>
              <SelectNamed
                label="Subject"
                options={subjects}
                values={subjects.length > 0 && selectedSubject ? [subjects.find((e) => e.value === selectedSubject)] : undefined}
                onChange={(e) => {
                  if (e && e[0] && e[0].value) {
                    setSelectedSubject(e[0].value);
                  }
                }}
              />
              <SelectNamed
                label="Grade"
                options={grades}
                values={grades.length > 0 && selectedGrade ? [grades.find((e) => e.value === selectedGrade)] : undefined}
                onChange={(e) => {
                  if (e && e[0] && e[0].value) {
                    setSelectedGrade(e[0].value);
                  }
                }}
              />
              <label>
                Date Observed:
                <DateTime
                  value={createDate}
                  displayTimeZone={timezonesMapping[timezone]}
                  onChange={(date) => {
                    setCreateDate(date.toDate());
                  }}
                />
              </label>
            </div>
          </div>

          <div id="block-wrapper">
            <SurveyOptions
              options={options}
              onTooltipClick={(e, name) => {
                if (window.innerWidth < 768) {
                  setShowModal(true);
                } else {
                  const { top } = e.currentTarget.getBoundingClientRect();
                  setTooltipPos(window.scrollY + top);
                }

                setTooltip(name);
              }}
              removeModule={(e) => {
                setOptions((opt) => opt.filter((o) => o !== e));
              }}
              onChange={(data) => {
                if (data) {
                  setSelectedOptions(data);
                }

                const isValid = options.filter(o => o.name.toLowerCase() === 'PLC Instructor Location'.toLowerCase()
                      || o.name.toLowerCase() === 'PLC Who Engaged in Academic Talk'.toLowerCase())
                    .every(({ id, type, options }) => {
                      if (data) {
                        const selectedOptionsObj = data[id];
                        if (type === 'multi') {
                          return selectedOptionsObj.length > 0;
                        }
                        if (type === 'radio') {
                          return !!selectedOptionsObj;
                        }
                        if (type === 'number') {
                          return options.length === Object.keys(selectedOptionsObj || {}).length;
                        }
                      }

                      return false;
                    });

                setIsValidOptions(isValid);
              }}
            />
          </div>

          <div id="block-wrapper">
            <div id="block-title">Notes: What I will do...</div>
            <Line margin="15px 0" />
            <textarea
              id="bottom-textarea"
              onChange={({ target }) => setWillDoData(target.value)}
              value={willDoData}
            ></textarea>
          </div>

          <div id="block-wrapper">
            <div id="block-title">Notes: What I might do instead...</div>
            <Line margin="15px 0" />
            <textarea
              id="bottom-textarea"
              onChange={({ target }) => setDoInsteadData(target.value)}
              value={doInsteadData}
            ></textarea>
          </div>
          <div id="block-wrapper-buttons">

            <ActionButton big title="Back" onClick={() => history.push('/new-survey-config')} />
            <ActionButton big title="Cancel" onClick={() => history.push('/home')} />
            <ActionButton
              big
              type="alert"
              title="Finish"
              disabled={!(isValidOptions && selectedGrade && selectedSubject)}
              onClick={() => {
                let res = [];
                for (const optionValue of options) {
                  const { id, type } = optionValue;
                  const selectedValues = selectedOptions[id];

                  if (type === 'multi') {
                    res = [...res, ...selectedValues.map((val) => ({ surveyOptionId: val.id, value: true }))];
                  } else if (type === 'radio') {
                    res.push({ surveyOptionId: selectedValues.id, value: true });
                  } else if (type === 'number') {
                    res = [...res, ...Object.keys(selectedValues).map((key) => ({ surveyOptionId: key, value: selectedValues[key] }))];
                  }
                }

                const e = {
                  name: '',
                  notes: '',
                  willDo: willDoData,
                  doInstead: doInsteadData,
                  roomId: selectedRoom,
                  gradeId: selectedGrade,
                  subjectId: selectedSubject,
                  surveyTypeId: surveyType,
                  options: res,
                  dateCreation: createDate,
                  isPLC: true,
                  modules: options.map((module) => ({
                    moduleId: module.id,
                    value: true,
                  })),
                };


                api('/survey', {
                  method: POST,
                  body: e,
                }).then(async () => {
                  history.push('/surveycongratulation');
                });
              }}
            />
          </div>
        </div>

        <Sidebar className="new-survey-tooltip-block">
          {tooltipPos > 0 && (
            <div style={{ position: 'absolute', top: tooltipPos - 190 }}>
              <div className="tooltip-title">{tooltipText.title}</div>
              <div className="tooltip-text">{tooltipText.body}</div>
            </div>
          )}
        </Sidebar>
      </div>
    </div>
  );
}
