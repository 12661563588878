import styled from 'styled-components';

export const ActionButtonWrapper = styled.div`
  background-color: ${(props) => props.color};
  color: white;
  user-select: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  display: flex;
  font-family: BebasNeue;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 3.14px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  height: ${(props) => (props.height ? props.height : '34px')};
  width: ${(props) => (props.width ? props.width : '100px')};
`;
