import React from 'react'
import FormInput from 'components/FormInput';

export default function CheckboxGroup({ options = [], onChange = () => { }, disabled }) {
  return options.map((opt, index) => (
    <div key={opt.id}>
      <label>
        {opt.name}
        <FormInput
          disabled={disabled}
          value={opt.value}
          onChange={e => {
            const newOptions = [...options];
            newOptions[index].value = e.target.value;
            onChange(newOptions);
          }}
        />
      </label>
    </div>
  ));
}
