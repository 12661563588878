export const tooltips = {
  'Common Info': 'This Module collects common information like Room, Grade, Subject, and Date Observed.',
  'Positive Comments': `
    Positive Comments should be used for the following:
    <br/ >
    1. Send a reminder to the observer to talk to the teacher about a concern.
    <br/ >
    2. Send a reminder to the observer to check on something.
    <br/ >
    3. Send a note to someone to provide a needed resource to the teacher.
    <br/ >
    4. Send a positive note to the teacher if the observer is UNABLE to do so in person.
    <br/ >    
    <br/ >    
    <b>NEVER SEND A NEGATITVE NOTE OR CRITIQUE TO A TEACHER BY E-MAIL.</b>

  `,

  'Instructor Location': `
      <b>Instructor Work Area:</b> Teacher engaged in administrative duties at the teacher desk or computer/technology space.
      <br />
      <br />
      <b>Lecture Position:</b> Teacher teaching or monitoring with significant space between teacher and student(s), but not in work area. 
      <br />
      <br />
      <b>Power Zone:</b> Teacher is teaching or monitoring in close proximity to one or more students in any area of the room.
    `,

  'Pupil Engagement': `
      <b>Off Task:</b> Actual number of students who are obviously off task, have no task to work on, or have finished the task.
      <br />
      <br />
      <b>On Task:</b> Actual number of students who have not been identified as Off Task.
  `,

  'Lesson Framing': `
      <b>Today’s Objective Posted:</b> Clear, student friendly statement of the intent of today’s lesson.
      <br />
      <br />
      <b>Today’s Close Posted:</b> Clear, student friendly description of how the student will demonstrate the understanding of the critical component(s) of today’s lesson.
      <br />
      <br />
      <b>Class Activity Aligns With Lesson Frame:</b> The observed activity in the classroom aligns with the posted lesson frame.
      <br />
      <br />
      <b>Effective Lesson Closure Observed:</b> Within the last 5-minutes of the class, students are engaged in demonstrating or articulating the key understanding / connections of the lesson.
      <br />
      <br />
      <b>Evidence of Previous Written Close:</b> The observer can read the written close completed by one or more students from an earlier class on the day of the PowerWalks Observation.
  `,

  'High Yield Instructional Practices': `
      <b>Identifying Similarities & Differences (written):</b> STUDENTS are comparing or classifying topics, items, and/or subject matter through purposeful writing.
      <br />
      <br />
      <b>Summarization (written):</b> STUDENTS are writing a clear and concise summary of a discussion, observation, and/or reading.
      <br />
      <br />
      <b>Note Making:</b> STUDENTS are making written connections, inferences, observations, highlights, and/or other notes based on presented information, discussion, and/or reading.
      <br />
      <br />
      <b>Other Critical Writing Activity:</b> STUDENTS engaged in a writing activity designed to increase connections to and cognition of the subject matter.
      <br />
      <br />
      <b>Nonlinguistic Representation:</b> STUDENTS are using visual or manipulative displays to organize, use, demonstrate, or connect to the subject matter.
      <br />
      <br />
      <b>Student to Student Purposeful Talk:</b> STUDENTS are engaged in purposeful talk about the subject matter, driven by TEACHER planning and lesson design.
      <br />
      <br />
      <b>Student to Student Collaboration:</b> STUDENTS collaborating on an assignment, academically supporting peers, and/or informally discussing academic content.
      <br />
      <br />
      <b>Providing Recognition:</b> TEACHER specifically recognizes a student’s academic success or growth.
      <br />
      <br />
      <b>Reinforcing Effort:</b> TEACHER specifically reinforces the work and effort by a student that will lead to academic success or growth.
      <br />
      <br />
      <b>Practice:</b> STUDENTS are engaged in an academic task AND the TEACHER is actively monitoring and/or providing feedback.
  `,

  'Instructional Rigor': `
      <b>No Observable Instructional Activity or Task:</b> While the observer is in the room, no instructional activity was in progress or started.
      <br />
      <br />
      <b>Remembering:</b> The memory of facts and knowledge. Listening, watching, and copying are Remembering level activities
      <br />
      <br />
      <b>Understanding:</b> Organizing information to develop meaning. Responding to simple questions, finding answers in provided materials, and short answers are often Understanding level activities.
      <br />
      <br />
      <b>Applying:</b> Using knowledge to solve problems in a new situation. Multi-step process questions, building, manipulating, and hands on activities are often Applying level activities.
      <br />
      <br />
      <b>Analyzing:</b> Examining and breaking information into parts by identifying motives and causes. Using evidence to support generalizations and inferences.
      <br />
      <br />
      <b>Evaluating:</b> Using knowledge to defend opinions or judge the quality of work, based on criteria.
      <br />
      <br />
      <b>Creating:</b> Using and combining components of knowledge to create a new or alternative solution.
  `,

  'Instructional Relevance': `
      <b>No Observable Instructional Activity or Task:</b> While the observer is in the room, no instructional activity was in progress or started.
      <br />
      <br />
      <b>Knowledge in the Content Area:</b> Using content knowledge to solve content problems in the content area. Example: Completing a math worksheet in a math class.
      <br />
      <br />
      <b>Application in the Content Area:</b> Content specific manipulating, building, or creating in the content area.
      <br />
      <br />
      <b>Knowledge Across Content Area:</b> Use of other content information to address tasks in a content area. Example: Reading a historical account in an English or reading class.
      <br />
      <br />
      <b>Application Across Content Area:</b> Use of other content knowledge while manipulating, building, or creating in a content area.
      <br />
      <br />
      <b>Real World Predictable Outcome:</b> Using content knowledge to address real world problems and/or situations that have predictable outcomes or solutions.
      <br />
      <br />
      <b>Real World Novel Outcome:</b> Using content knowledge to address real world problems and/or situations that have novel or infinite outcomes or solutions.
  `,

  'Classroom Climate': `
      <b>Following Posted Instructional Schedule:</b> If a classroom schedule is posted, the classroom activity and/or covered content aligns with the schedule.
      <br />
      <br />
      <b>Circulates Through Classroom:</b> The teacher moves to different areas of the classroom during the observation.
      <br />
      <br />
      <b>Using Positive Language:</b> The teacher uses positive language when interacting with students. Examples: Good job; Glad to see you; Great energy in the room today; etc.
      <br />
      <br />
      <b>Using a Timer:</b> The teacher is using a timer to keep track of how much time is left in an instructional activity. Ex: Warmups, Individual Practice; Lesson Closure, etc.
      <br />
      <br />
      <b>Copying / Dictation Activity:</b> The student is either copying material from the board or a book, or writing down what the teacher is dictating.
      <br />
      <br />
      <b>Clutter Free Classroom:</b> The classroom and teacher work area are free of clutter, excessive materials, and/or excessive decorations.
      <br />
      <br />
      <b>Organized Classroom:</b> The materials and decor of the classroom and teacher work area are orderly and organized.
      <br />
      <br />
      <b>Clean Classroom:</b> The classroom and teacher work area are clean and neat.
  `,
};
