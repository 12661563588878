import { fromJS } from 'immutable';

import { CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_ERROR } from './constants';

const initialState = fromJS({
  changeLoading: false,
  hasError: false,
});

function changePasswordReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return state.set('changeLoading', true).set('hasError', false);

    case CHANGE_PASSWORD_SUCCESS: {
      return state.set('changeLoading', false);
    }
    case CHANGE_PASSWORD_ERROR: {
      return state.set('changeLoading', false).set('hasError', true);
    }

    default:
      return state;
  }
}

export default changePasswordReducer;
