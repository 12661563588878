import React, { Fragment } from 'react';

import { CloseIcon } from '../Icons';
import { Wrapper, StyledModal, Background, Title, Header, IconWrapper, Content } from './styles';

export default class Modal extends React.PureComponent {
  state = {
    show: false,
  };

  constructor(props) {
    super(props);

    if (props.showModal) {
      this.state.show = true;
    }
  }

  closeModal = (...args) => {
    const { onClose } = this.props;

    this.setState({ show: false }, () => {
      if (onClose && typeof onClose === 'function') {
        onClose(...args);
      }
    });
  };

  openModal = () => {
    this.setState({ show: true });
  };

  render() {
    const { show } = this.state;
    const { children, title } = this.props;

    return show ? (
      <Wrapper>
        <Background onClick={this.closeModal} />
        <StyledModal>
          <Header>
            <Title>{title}</Title>
            <IconWrapper onClick={this.closeModal}>
              <CloseIcon height="25px" width="25px" />
            </IconWrapper>
          </Header>

          <Content>{children}</Content>
        </StyledModal>
      </Wrapper>
    ) : (
        <Fragment />
      );
  }
}
