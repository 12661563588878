import styled from 'styled-components';
import colors from 'styles/colors';

export const Wrapper = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 15px;
    margin: 0;
    background: #092143;
  }
`;

export const Content = styled.div`
  padding: 50px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 15px;
    background: white;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const Title = styled.div`
  color: #000000;
  flex: 1;
  object-fit: contain;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 36px;
  margin-bottom: -20px;
`;

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  width: 300px;
`;

export const Line = styled.div`
  height: 1px;
  border: solid 1px ${colors.warmGrey};
  margin-top: 50px;
  margin-bottom: 50px;
`;
