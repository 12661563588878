import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { formValues } from 'redux-form/immutable';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';

import { loadStates, loadDistricts, loadCampuses, loadRoles, loadAccessLevels } from './state/actions';
import { makeStatesSelect, makeCampusesSelect, makeDistrictsSelect, makeRolesSelect, makeAccessLevelsSelect } from './state/selectors';
import reducer from './state/reducer';
import saga from './state/saga';
import { REDUCER_KEY } from './constants';
import UserRole from './component';

const mapDispatchToProps = (dispatch) => ({
  loadStates: () => {
    dispatch(loadStates());
  },
  loadDistricts: (stateId) => {
    dispatch(loadDistricts(stateId));
  },
  loadCampuses: (districtId) => {
    dispatch(loadCampuses(districtId));
  },
  loadRoles: () => {
    dispatch(loadRoles());
  },
  loadAccessLevels: (roleId) => {
    dispatch(loadAccessLevels(roleId));
  },
});

const mapStateToProps = createStructuredSelector({
  states: makeStatesSelect(),
  districts: makeDistrictsSelect(),
  campuses: makeCampusesSelect(),
  roles: makeRolesSelect(),
  accessLevels: makeAccessLevelsSelect(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: REDUCER_KEY, reducer });
const withSaga = injectSaga({ key: REDUCER_KEY, saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  formValues('user.userRoles[1]')
)(UserRole);

export { mapDispatchToProps };
