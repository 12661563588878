import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faUser } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

export default class ForgotPwdPage extends React.Component {
  state = {
    username: '',
  };


  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSend = () => {
    const { username } = this.state;
    const { resetPassword } = this.props;

    resetPassword(username);
  }

  render() {
    const { username } = this.state;
    const { resetLoading, hasError } = this.props;

    return (
      <div className="Forgot-pwd-page">
        <Helmet>
          <title>Password reset</title>
          <meta name="description" content="Password reset page" />
        </Helmet>
        <div id="wrapper">
          <div id="form-container">
            <div className="row">
              <button type="button" className="toggle">
                <Link to="/">
                  Login
                  <span className="icon-wrapper">
                    <FontAwesomeIcon icon={faSignInAlt} />
                  </span>
                </Link>
              </button>
              <button type="button" className="toggle">
                <Link to="/">
                  Subscription Request
                  <span className="icon-wrapper">
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                </Link>
              </button>
            </div>
            <div className="row">
              <div id="reset-pwd-wrapper">
                <div className="title">password reset</div>
                <form name="form">
                  <div className="form-group">
                    <label htmlFor="username">
                      {hasError ? <div className="help-block">Username / email not found</div> : 'Username / email'}
                      <input type="text" className="form-control" name="username" value={username} onChange={this.handleChange} />
                    </label>
                  </div>
                  <div className="form-group position-absolute align-bottom" id="reset-pwd-button">
                    <button type="submit" className="btn btn-primary" disabled={resetLoading} onClick={this.handleSend}>
                      Reset password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div id="card-container" />
        </div>
      </div>
    );
  }
}
