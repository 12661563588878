import { fromJS } from 'immutable';

import { RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR } from './constants';

const initialState = fromJS({
  resetLoading: false,
  hasError: false,
});

function resetPasswordReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_PASSWORD:
      return state.set('resetLoading', true).set('hasError', false);

    case RESET_PASSWORD_SUCCESS: {
      return state.set('resetLoading', false);
    }
    case RESET_PASSWORD_ERROR: {
      return state.set('resetLoading', false).set('hasError', true);
    }

    default:
      return state;
  }
}

export default resetPasswordReducer;
