export function getCookie(name) {
  const cookies = decodeURIComponent(document.cookie).split('; ');
  const cookie = cookies.find((value) => value.includes(name));

  if (cookie) {
    return cookie.replace(`${name}=`, '');
  }

  return null;
}

export function setCookie(name, value, exhours) {
  const date = new Date();
  date.setTime(date.getTime() + (exhours * 60 * 60 * 1000));

  const expires = `expires=${  date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}

export function deleteCookie(name) {
  const date = new Date(null);
  const expires = `expires=${date.toUTCString()}`;

  document.cookie = `${name}=;${expires};path=/`;
}
