import React from 'react';
import createIconComponent from '../../utils/createIconComponent';

const InfoIcon = createIconComponent({
  content: (
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="PowerWalks-SURVEY_STEP2-1440+" transform="translate(-1157.000000, -897.000000)">
        <g id="Group-11" transform="translate(317.000000, 896.000000)">
          <g id="Group-4-Copy" transform="translate(840.000000, 1.000000)">
            <g id="Group">
              <circle id="Oval" fill="#D3D3D3" cx="16.5" cy="16.5" r="16.5"></circle>
              <g id="noun_Book_1851043" transform="translate(9.000000, 6.000000)" fill="#FFFFFF">
                <path d="M0.0160870714,4.017741 C0.00549316071,3.9049395 0,3.7906155 0,3.675 C0,2.024379 1.11053571,0.6279231 2.63933571,0.1639344 C2.80543929,0.06011355 3.00266786,0 3.21428571,0 L13.9285714,0 C14.5202143,0 15,0.4701417 15,1.05 C15,1.6298625 14.5202143,2.1 13.9285714,2.1 L13.3928571,2.1 C12.5052857,2.1 11.7857143,2.8052115 11.7857143,3.675 C11.7857143,4.5447885 12.5052857,5.25 13.3928571,5.25 L13.9285714,5.25 C14.5202143,5.25 15,5.7201375 15,6.3 L15,19.95 C15,20.52981 14.5202143,21 13.9285714,21 L1.07142857,21 C0.479736429,21 0,20.52981 0,19.95 L0,4.2 C0,4.13784 0.00549316071,4.0769505 0.0160870714,4.017741 Z M3.75,2.1 C2.86246071,2.1 2.14285714,2.8052115 2.14285714,3.675 C2.14285714,4.5447885 2.86246071,5.25 3.75,5.25 L10.0037036,5.25 C9.77233929,4.7726805 9.64285714,4.238577 9.64285714,3.675 C9.64285714,3.111423 9.77233929,2.5773195 10.0037036,2.1 L3.75,2.1 Z M12.8571429,7.35 L12.8571429,18.9 L2.14285714,18.9 L2.14285714,7.35 L12.8571429,7.35 Z" id="Shape"></path>
              </g>
              <path d="M17.578125,23 L15.6689453,23 L15.6689453,17.4677734 L17.578125,17.4677734 L17.578125,23 Z M15.6201172,16.0908203 C15.6201172,15.8043605 15.699869,15.5911465 15.859375,15.4511719 C16.018881,15.3111972 16.2760399,15.2412109 16.6308594,15.2412109 C16.9856789,15.2412109 17.2444653,15.3128248 17.4072266,15.4560547 C17.5699878,15.5992846 17.6513672,15.810871 17.6513672,16.0908203 C17.6513672,16.6507189 17.3112013,16.9306641 16.6308594,16.9306641 C15.9570279,16.9306641 15.6201172,16.6507189 15.6201172,16.0908203 Z" id="i" fill="#FFFFFF" fillRule="nonzero"></path>
            </g>
            <polygon id="Path-3" fill="#D3D3D3" points="0 17 0 35.5 9.5 30"></polygon>
          </g>
        </g>
      </g>
    </g>
  ),
  height: 33,
  width: 33,
  parentProps: {
    // stroke: '#4A4A4A',
  },
});

InfoIcon.displayName = 'InfoIcon';

export default InfoIcon;
