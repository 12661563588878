import React from 'react';
import createIconComponent from '../../utils/createIconComponent';

const ListInfoIcon = createIconComponent({
  content: (
    <g fill="none" fillRule="evenodd">
      <circle cx="13" cy="13" r="13" fill="#D3D3D3" />
      <g stroke="#FFF" strokeWidth="2">
        <path d="M8.432 9.135h9.838M8.432 11.946h9.838M8.432 14.757h9.838M8.432 17.568h6.325" />
      </g>
    </g>
  ),
  height: 26,
  width: 26,
});

ListInfoIcon.displayName = 'ListInfoIcon';

export default ListInfoIcon;
